import axios from "axios";
import {errorOccur} from "../utils/toast";
import {api_url} from "../utils/api/basic";

function TokenRefresh(addToast, dispatch) {
    if (localStorage.getItem('token')) {
        const tokenParts = JSON.parse(atob(localStorage.getItem('token').split('.')[1]));
        const now = Math.ceil(Date.now() / 1000);
        const time_left_percent = (tokenParts.exp - now) / (tokenParts.exp - tokenParts.orig_iat)
        if (time_left_percent < 10/100 && time_left_percent > 0) {
            axios.post(api_url+"/api/v1/auth/refresh", {token:localStorage.getItem('token')})
            .then(res => {
                localStorage.removeItem('token');
                localStorage.setItem('token', res.data.token);
            })
            .catch((error)=> {
                if (error.response.status === 401) dispatch({type: 'EXPIRED', payload: true})
                else errorOccur(addToast)
            })

        }
    }
}

export default TokenRefresh;