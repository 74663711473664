import React, {useRef} from "react";
import {url} from "../../utils/api/basic"
import {useSelector} from "react-redux";

const WebSocketContext = React.createContext(null);
export { WebSocketContext };

export default ({ children }) => {
    const character = useSelector(state=>state.character).value
    const webSocketWorldUrl = 'wss://'+url+'/ws/chat/world/'+localStorage.getItem('token')+'/'
    const webSocketGuildUrl = 'wss://'+url+'/ws/chat/guild/'+localStorage.getItem('token')+'/'
    const webSocketAnnounceUrl = 'wss://'+url+'/ws/chat/announce/'+localStorage.getItem('token')+'/'
    let world = useRef(null);
    let announce = useRef(null);
    let guild = useRef(null);
    if (!world.current) {
        world.current = new WebSocket(webSocketWorldUrl);
        world.current.onopen = () => {
            console.log("connected to world chat");
        }
        world.current.onclose = error => {
            console.log("disconnect from world chat");
            console.log(error);
        };
        world.current.onerror = error => {
            console.log("connection error from world chat");
            console.log(error);
        };
    }
    if (!guild.current && character.guild !== '없음') {
        guild.current = new WebSocket(webSocketGuildUrl);
        guild.current.onopen = () => {
            console.log("connected to guild chat");
        }
        guild.current.onclose = error => {
            console.log("disconnect from guild chat");
            console.log(error);
            guild.current = new WebSocket(webSocketGuildUrl);
        };
        guild.current.onerror = error => {
            console.log("connection error from guild chat");
            console.log(error);
        };
    }
    if (!announce.current) {
        announce.current = new WebSocket(webSocketAnnounceUrl);
        announce.current.onopen = () => {
            console.log("connected to announcement");
        }
        announce.current.onclose = error => {
            console.log("disconnect from announcement");
            console.log(error);
        };
        announce.current.onerror = error => {
            console.log("connection error from announcement");
            console.log(error);
        };
    }

    return (
        <WebSocketContext.Provider value={{'world': world, 'announce': announce, 'guild': guild}}>
            {children}
        </WebSocketContext.Provider>
    );
}