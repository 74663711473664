import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { errorOccur, guildRemove, guildJoinTypeChanged,
    guildJoinTypeIsSame, guildIntroChanged, guildIntroIsSame } from "../../../utils/toast";
import { getGuildInfo, deleteGuild, changeGuildInfo } from "../../../utils/api/guild";
import {getCharacter} from "../../../utils/api/character";

function ManageInfo(props) {
    const [guild, setGuild] = useState()
    const [tempAction, setTempAction] = useState()
    const dispatch = useDispatch()
    const { addToast } = useToasts()

    useLayoutEffect(() => {
        window.jQuery('[data-modal="guildremove"]').click(function () {
            window.jQuery('#guildRemove').modal('show');
        });
    })
    useEffect(() => {
        getGuildInfo()
            .then(res => {
                setGuild(res.data)
                setTempAction(res.data.intro)
            })
            .catch(error => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else errorOccur(addToast)
            })
    }, [dispatch, addToast])

    const handleGuildRemove = () => {
        deleteGuild()
            .then(() => {
                guildRemove(addToast)
                getCharacter().then(res=>{dispatch({type:'CHARACTER', payload:res.data})})
                dispatch({type:"GUILD", payload: {guild__name:"없음", op:0}})
                props.history.push('/')
            })
            .catch((error) => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else errorOccur(addToast)
            })
    }
    const handleJoinType = (join_type) => {
        if (join_type === false) {
            if (join_type === guild.join_type) {
                guildJoinTypeIsSame(addToast, join_type)
            }
            else {
                guild.join_type = join_type
                changeGuildInfo('join_type', guild.join_type)
                    .then(()=>{
                        guildJoinTypeChanged(addToast, join_type)
                    })
            }
        }
        else if (join_type === true) {
            if (join_type === guild.join_type) {
                guildJoinTypeIsSame(addToast, join_type)
            }
            else {
                guild.join_type = join_type
                changeGuildInfo('join_type', guild.join_type)
                    .then(()=>{
                        guildJoinTypeChanged(addToast, join_type)
                    })
            }
        }
    }
    const changeGuildIntro = (event) => {
        setTempAction(event.target.value)
    }
    const handleGuildIntro = () => {
        if (tempAction === guild.intro) {
            guildIntroIsSame(addToast)
        }
        else if (tempAction !== guild.intro) {
            guild.intro = tempAction
            changeGuildInfo('intro', guild.intro)
                .then(()=>{
                    guildIntroChanged(addToast)
                })
        }
    }

    if (guild === undefined) {
        return (<>loading</>)
    }
    else {
        return (
            <div className="section">
                <div className="container">
                    <h1>길드 정보</h1>
                    <div className="row text-center" style={{ marginTop: "3%" }}>
                        <h3 style={{ marginLeft: "auto", marginRight: "auto" }}>길드 설명</h3><br /><br />
                        <form className="col-md-12">
                            <div className="form-group">
                                <textarea className="form-control" id="guildIntro" rows="3" value={tempAction} onChange={changeGuildIntro}/>
                            </div>
                        </form>
                        <div className="col-md-12">
                            <button className="btn btn-outline-primary" onClick={() => handleGuildIntro()}>설명 변경</button>
                        </div>
                    </div>
                    <br/>
                    <div className="row text-center" style={{ marginTop: "3%" }}>
                        <h3 style={{ marginLeft: "auto", marginRight: "auto" }}>가입 방식</h3><br /><br />
                        <div className="col-md-12">
                            <button className="btn btn-outline-primary" onClick={() => handleJoinType(false)} style={{marginRight: "10px" }}>자유 가입</button>
                            <button className="btn btn-outline-warning" onClick={() => handleJoinType(true)} style={{marginLeft: "10px"}}>승인 가입</button>
                        </div>
                    </div>
                    <br/>
                    <div className="row text-center" style={{ marginTop: "3%" }}>
                        <h3 style={{ marginLeft: "auto", marginRight: "auto" }}>길드 삭제</h3><br /><br />
                        <div className="col-md-12">
                            <button className="btn btn-danger btn-lg" data-modal="guildremove">삭제</button>
                        </div>
                    </div>
                </div>
                <div className="modal" id="guildRemove" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="guildRemove">길드 삭제</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>정말 길드를 삭제하시겠습니까?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary" data-dismiss="modal" onClick={() => handleGuildRemove()}>확인</button>
                                <button type="button" className="btn btn-outline-danger" data-dismiss="modal">취소</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ManageInfo