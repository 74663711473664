import React from "react";
import moment from "moment";
import War from "./war";
import WarParticipation from "./participation";

function WarRouter(props) {
    const now = moment()
    const war_start = moment(now.format("YYYY-MM-DD")+" 20:00:00")
    const war_end = moment(now.format("YYYY-MM-DD")+" 21:00:00")
    const time_diff_start = moment.duration(now.diff(war_start)).asMilliseconds()
    const time_diff_end = moment.duration(now.diff(war_end)).asMilliseconds()
    if(time_diff_start > 0 && time_diff_end < 0)
        return(
            <War {...props}/>
        )
    else
        return(
            <WarParticipation/>
        )
}

export default WarRouter;