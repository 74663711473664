import React, { useState, useEffect } from "react";
import { getGuildInfo, manageGuildTown } from "../../../utils/api/guild";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { guardStatAdapt, shortOfMoney, errorOccur } from "../../../utils/toast";

export default function ManageTown(props) {
  const [guild, setGuild] = useState();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const town = useSelector((state) => state.town).value;
  const [money, setMoney] = useState(0);
  const [tot_money, setTotMoney] = useState();
  const [guard, setGuard] = useState();
  const [hp, setHp] = useState(0);
  const [mp, setMp] = useState(0);
  const [atk, setAtk] = useState(0);
  const [mag, setMag] = useState(0);
  const [def, setDef] = useState(0);
  const [spr, setSpr] = useState(0);
  const [spd, setSpd] = useState(0);
  const statCoefficient = 5000;

  function hp_stat(value, calculation) {
    if (calculation === "plus") {
      if (money >= value * statCoefficient) {
        setHp(hp + value);
        setMoney(money - value * statCoefficient);
      }
    } else {
      if (tot_money - money >= value * statCoefficient && hp >= value) {
        setHp(hp - value);
        setMoney(money + value * statCoefficient);
      } else {
        setMoney(money + hp * statCoefficient);
        setHp(0);
      }
    }
  }
  function mp_stat(value, calculation) {
    if (calculation === "plus") {
      if (money >= value * statCoefficient) {
        setMp(mp + value);
        setMoney(money - value * statCoefficient);
      }
    } else {
      if (tot_money - money >= value * statCoefficient && mp >= value) {
        setMp(mp - value);
        setMoney(money + value * statCoefficient);
      } else {
        setMoney(money + mp * statCoefficient);
        setMp(0);
      }
    }
  }
  function atk_stat(value, calculation) {
    if (calculation === "plus") {
      if (money >= value * statCoefficient) {
        setAtk(atk + value);
        setMoney(money - value * statCoefficient);
      }
    } else {
      if (tot_money - money >= value * statCoefficient && atk >= value) {
        setAtk(atk - value);
        setMoney(money + value * statCoefficient);
      } else {
        setMoney(money + atk * statCoefficient);
        setAtk(0);
      }
    }
  }
  function mag_stat(value, calculation) {
    if (calculation === "plus") {
      if (money >= value * statCoefficient) {
        setMag(mag + value);
        setMoney(money - value * statCoefficient);
      }
    } else {
      if (tot_money - money >= value * statCoefficient && mag >= value) {
        setMag(mag - value);
        setMoney(money + value * statCoefficient);
      } else {
        setMoney(money + mag * statCoefficient);
        setMag(0);
      }
    }
  }
  function def_stat(value, calculation) {
    if (calculation === "plus") {
      if (money >= value * statCoefficient) {
        setDef(def + value);
        setMoney(money - value * statCoefficient);
      }
    } else {
      if (tot_money - money >= value * statCoefficient && def >= value) {
        setDef(def - value);
        setMoney(money + value * statCoefficient);
      } else {
        setMoney(money + def * statCoefficient);
        setDef(0);
      }
    }
  }
  function spr_stat(value, calculation) {
    if (calculation === "plus") {
      if (money >= value * statCoefficient) {
        setSpr(spr + value);
        setMoney(money - value * statCoefficient);
      }
    } else {
      if (tot_money - money >= value * statCoefficient && spr >= value) {
        setSpr(spr - value);
        setMoney(money + value * statCoefficient);
      } else {
        setMoney(money + spr * statCoefficient);
        setSpr(0);
      }
    }
  }
  function spd_stat(value, calculation) {
    if (calculation === "plus") {
      if (money >= value * statCoefficient) {
        setSpd(spd + value);
        setMoney(money - value * statCoefficient);
      }
    } else {
      if (tot_money - money >= value * statCoefficient && spd >= value) {
        setSpd(spd - value);
        setMoney(money + value * statCoefficient);
      } else {
        setMoney(money + spd * statCoefficient);
        setSpd(0);
      }
    }
  }
  function handleStat(statName, value, calculation) {
    if (statName === "체력") {
      hp_stat(value, calculation);
    } else if (statName === "마나") {
      mp_stat(value, calculation);
    } else if (statName === "공격력") {
      atk_stat(value, calculation);
    } else if (statName === "마력") {
      mag_stat(value, calculation);
    } else if (statName === "방어력") {
      def_stat(value, calculation);
    } else if (statName === "마법저항력") {
      spr_stat(value, calculation);
    } else if (statName === "속도") {
      spd_stat(value, calculation);
    }
  }
  const handleAdapt = () => {
    manageGuildTown(hp, mp, atk, mag, def, spr, spd)
      .then((res) => {
        setHp(0);
        setMp(0);
        setAtk(0);
        setMag(0);
        setDef(0);
        setSpr(0);
        setSpd(0);
        setGuard(res.data);
        getGuildInfo().then((res) => setMoney(res.data.money));
        guardStatAdapt(addToast);
      })
      .catch((err) => {
        if (err.response.status === 401)
          dispatch({ type: "EXPIRED", payload: true });
        else if (err.response.status === 402) shortOfMoney(addToast);
        else errorOccur(addToast);
      });
  };

  useEffect(() => {
    getGuildInfo()
      .then((res) => {
        setGuild(res.data);
        setMoney(res.data.money);
        setTotMoney(res.data.money);

        manageGuildTown(0, 0, 0, 0, 0, 0, 0).then((res) => {
          setGuard(res.data);
        });
      })
      .catch((error) => {
        if (error.response.status === 401)
          dispatch({ type: "EXPIRED", payload: true });
        else errorOccur(addToast);
      });
  }, [dispatch, addToast]);

  return guild === undefined ? (
    <div>...loading</div>
  ) : (
    <div className="section">
      <div className="container">
        <h1>마을 관리</h1>
        <div className="row text-center" style={{ marginTop: "3%" }}>
          <h3 style={{ marginLeft: "auto", marginRight: "auto" }}>
            {town[0].nameKorean} 근위대 관리
          </h3>
          <br />
          <br />
        </div>
        {guard ? (
          <div className="row">
            <div
              className="col-md-auto"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            >
              <table className="table">
                <tbody>
                  <tr>
                    <th width="100" />
                    <td width="100">길드 자금</td>
                    <td />
                    <td style={{ textAlign: "center" }}>
                      <div id="money_text" style={{ display: "inline" }}>
                        {money.toLocaleString()}
                      </div>
                    </td>
                    <td />
                  </tr>
                  {[
                    "체력",
                    "마나",
                    "공격력",
                    "마력",
                    "방어력",
                    "마법저항력",
                    "속도",
                  ].map((statName, id) => (
                    <tr key={id}>
                      <th width="100">{statName}</th>
                      <td width="100">
                        {statName === "체력" ? guard.guard_hp : null}
                        {statName === "마나" ? guard.guard_mp : null}
                        {statName === "공격력" ? guard.guard_atk : null}
                        {statName === "마력" ? guard.guard_mag : null}
                        {statName === "방어력" ? guard.guard_def : null}
                        {statName === "마법저항력" ? guard.guard_spr : null}
                        {statName === "속도" ? guard.guard_spd : null}
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => handleStat(statName, 1000, "minus")}
                        >
                          -1000
                        </button>
                        &nbsp;
                        <button
                          type="button"
                          onClick={() => handleStat(statName, 100, "minus")}
                        >
                          -100
                        </button>
                        &nbsp;
                        <button
                          type="button"
                          onClick={() => handleStat(statName, 10, "minus")}
                        >
                          -10
                        </button>
                      </td>
                      <td width="50" style={{ textAlign: "center" }}>
                        <div id="hp_text" style={{ display: "inline" }}>
                          {statName === "체력" ? hp : null}
                          {statName === "마나" ? mp : null}
                          {statName === "공격력" ? atk : null}
                          {statName === "마력" ? mag : null}
                          {statName === "방어력" ? def : null}
                          {statName === "마법저항력" ? spr : null}
                          {statName === "속도" ? spd : null}
                        </div>
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => handleStat(statName, 10, "plus")}
                        >
                          +10
                        </button>
                        &nbsp;
                        <button
                          type="button"
                          onClick={() => handleStat(statName, 100, "plus")}
                        >
                          +100
                        </button>
                        &nbsp;
                        <button
                          type="button"
                          onClick={() => handleStat(statName, 1000, "plus")}
                        >
                          +1000
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="inline" style={{ float: "right" }}>
                <button
                  className="btn btn-success"
                  onClick={() => handleAdapt()}
                >
                  적용
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="row" style={{ margin: "30px" }}>
            <h5 style={{ margin: "0 auto" }}>점령중인 지역이 아닙니다</h5>
          </div>
        )}
      </div>
    </div>
  );
}
