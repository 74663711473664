import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    alreadyDefend,
    earlyToAttack,
    errorOccur,
    guildWarDefendNotAvailable,
    notWarTime,
    noWarLife, townConquered, warAttackLost,
    warDefend
} from "../../../utils/toast";
import {useToasts} from "react-toast-notifications";
import {logType, setBattleLog} from "../../../utils/log";
import moment from "moment";
import {getCharacter} from "../../../utils/api/character";
import {getSpecificLog} from "../../../utils/api/basic";
import {attackCity, defendCity} from "../../../utils/api/war";
import {getRelatedParticipation} from "../../../utils/api/war";

function War(props) {
    const character = useSelector(store=>store.character).value
    const dispatch = useDispatch()
    const { addToast } = useToasts()
    const [related_participation, setRelParty] = useState([])
    const [list, setList] = useState([])

    const hp = (character.hp/character.max_hp*100)+"%"
    const mp = (character.mp/character.max_mp*100)+"%"

    const handleAttack = () => {
        attackCity()
            .then(res=>{
                if (res.status === 201){
                    townConquered(addToast, res.data["town"])
                    dispatch({type:"BATTLE", payload:res.data})
                    getCharacter().then(res=>{dispatch({type:'CHARACTER', payload:res.data})})
                    props.history.push('/log/battle')
                }
                else if (res.status === 202){
                    warAttackLost(addToast, res.data["life"])
                    dispatch({type:"BATTLE", payload:res.data})
                    getCharacter().then(res=>{dispatch({type:'CHARACTER', payload:res.data})})
                    props.history.push('/log/battle')
                }
            })
            .catch((error)=> {
                if (error.response.status === 401) dispatch({type: 'EXPIRED', payload: true})
                else if (error.response.status === 406) notWarTime(addToast)
                else if (error.response.status === 400) noWarLife(addToast)
                else if (error.response.status === 428) earlyToAttack(addToast)
                else errorOccur(addToast)
            })
    }

    const handleDefend = () => {
        defendCity()
            .then(() => warDefend(addToast))
            .catch((error)=> {
                if (error.response.status === 401) dispatch({type: 'EXPIRED', payload: true})
                else if (error.response.status === 400) noWarLife(addToast)
                else if (error.response.status === 406) notWarTime(addToast)
                else if (error.response.status === 412) guildWarDefendNotAvailable(addToast)
                else if (error.response.status === 428) alreadyDefend(addToast)
                else errorOccur(addToast)
            })
    }

    useEffect(() => {
        getSpecificLog("war", 1)
            .then(res => setList(res.data))
        getRelatedParticipation()
            .then(res => setRelParty(res.data))
            .catch(error => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else errorOccur(addToast)
            })
    }, [addToast, dispatch, character])

    return(
        <div className="section">
            <div className='container'>
                <h1 style={{ marginTop:'30px', textAlign:"center"}}>전쟁</h1>
                <div className="row">
                    <div className="col-md-6" style={{textAlign:"center",paddingLeft:"30px", paddingRight:"30px"}}>
                        <h2 style={{marginBottom:'30px'}}>현 정보</h2>
                        <div className="col-md" style={{width:"60%", marginLeft:"auto", marginRight:"auto",paddingLeft:"0px", paddingRight:"0px", marginBottom:"50px"}}>
                            <div className="progress">
                                <div className="progress-bar bg-danger" role="progressbar" style={{width: hp}}/>
                                <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                                    color="white"><b>{character.hp} / {character.max_hp}</b></font></div>
                            </div>
                            <div className="progress" style={{textAlign:"center", marginBottom:"20px"}}>
                                <div className="progress-bar" role="progressbar" style={{width: mp}} />
                                <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                                    color="white"><b>{character.mp} / {character.max_mp}</b></font></div>
                            </div>
                            <button className='btn-sm btn-success' onClick={()=>{props.history.push('/town/rest')}}>여관 가기</button>
                        </div>

                        <h2 style={{marginBottom:'30px', marginTOP:'50px'}}>행동</h2>
                        { related_participation.length > 0 && related_participation[0].action_type ==='attack' ?
                            <div className="col-md" style={{textAlign:"center", width: '50%', minWidth: '300px', marginLeft:"auto", marginRight:"auto"}}>
                                <button style={{padding:"0"}} onClick={()=>{handleAttack()}}>
                                    <img src="/placeholder.png" alt="attack"
                                         className="img-responsive" style={{height: '100%', width: '100%', objectFit: 'contain'}}/>
                                    <div className="carousel-caption text-dark">
                                        <h4>공격</h4>
                                    </div>
                                </button>
                            </div>
                            :
                            <div className="col-md" style={{width: '50%', minWidth: '300px', marginLeft:"auto", marginRight:"auto"}}>
                                <button style={{padding:"0"}} onClick={()=>{handleDefend()}}>
                                    <img src="/placeholder.png" alt="defend"
                                         className="img-responsive" style={{height: '100%', width: '100%', objectFit: 'contain'}}/>
                                    <div className="carousel-caption text-dark">
                                        <h4>수비</h4>
                                    </div>
                                </button>
                            </div>
                        }
                    </div>

                    <div className="col-md-6" style={{textAlign:"center",paddingLeft:"30px", paddingRight:"30px"}}>
                        <h2>전시 상황</h2>
                        {related_participation.length > 0?
                            <h5 style={{marginBottom:'20px'}}>- {related_participation[0].station} {related_participation[0].action_type ==='attack'?
                                <>공격</>
                                :<>수비</>} 신청 -</h5>:
                            <h5 style={{marginBottom:'20px'}}>- 지원한 신청이 없습니다. -</h5>
                        }
                        <div className="list-group">
                            <div className="list-group-item">
                                <b>전투 로그</b>
                            </div>
                            {list.map((item, index)=>( <>
                                    {(() => {
                                        if (item.type ==="war") {
                                            return (
                                                <div className="list-group-item text-left" key={index}
                                                     onClick={item.battle !==null ? ()=>{setBattleLog(item, dispatch, props.history)}: null}>
                                                    {logType(item.type)}
                                                    &nbsp;
                                                    <b>[{moment(item.time).format("LT")}]</b>
                                                    &nbsp;
                                                    {item.log}
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <></>
                                            )
                                        }
                                    })()}
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default War