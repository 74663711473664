import axios from "axios";

const client = "https://erionworld.com" // Main Server

const url = "api.erionworld.com" // Main Server

const api_url = "https://" + url

function tryLogin(username, password) {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/auth/", {username:username, password:password})
    );
}
function signUp(username, password, confirm_pass, email) {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/accounts/", {username: username, password: password,
            confirm_pass:confirm_pass, email: email})
    );
}
function emailValidation(token) {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/email/validation/", {token:token})
    );
}
function getLog(num) {
    return Promise.resolve(
        axios.get(api_url+"/api/v1/log/"+num,
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getSpecificLog(type, num) {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/log/"+num,{type:type},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}


export {api_url, getLog, getSpecificLog, signUp, tryLogin, emailValidation, url, client}