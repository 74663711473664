import React, {useEffect, useState, useLayoutEffect} from "react";
import {jobCounter} from "../../utils/character";
import {useToasts} from "react-toast-notifications";
import {useDispatch} from "react-redux";
import {actionTimeLeft, errorOccur, noHP} from "../../utils/toast";
import {BattlePage} from "../../utils/battle";
import {battlePractice, getPracticeMember} from "../../utils/api/battle";

function PractiveBattle() {
    const { addToast } = useToasts()
    const dispatch = useDispatch()
    const [enemy, setEnemy] = useState('null')
    const [battle, setBattle] = useState('null')
    const [enemy_list, setEnemyList] = useState([])
    const [isPlayerEquip, setIsPlayerEquip] = useState(false)
    const [isEnemyEquip, setIsEnemyEquip] = useState(false)

    useEffect(()=>{
        getPracticeMember()
            .then(res=>{
                setEnemyList(res.data)
                setEnemy(res.data[0])
            })
            .catch(error=>{
                if(error.response.status===401) dispatch({type:'EXPIRED', payload:true})
                else errorOccur(addToast)
            })
    },[addToast, dispatch]);

    useLayoutEffect(()=> {
        window.jQuery('[data-toggle="popover"]').popover({
            animation: true,
            container: "body",
            html: true,
            placement: "right",
            trigger: "hover"
        })
    })

    const handleSubmit = () => {
        battlePractice(enemy["name"])
            .then(res=>{
                setBattle(res.data)
                setEnemy(enemy_list[0])
            })
            .catch((error)=>{
                if(error.response.status===401) dispatch({type:'EXPIRED', payload:true})
                else if(error.response.status===417) actionTimeLeft(addToast, error.response.data)
                else if(error.response.status===406) noHP(addToast)
                else errorOccur(addToast)
            })
    }
    if (battle ==='null')
        return(
            <div className="section">
                <div className="container">
                    <h1>연습 전투</h1>
                    <h5 style={{marginLeft:"30px"}}>같은 마을에 위치한 플레이어와 전투를 해보실 수 있습니다.</h5>
                    <div className="row" style={{marginTop:"10%", marginBottom:"10%"}}>
                            {(() => {
                                if (enemy_list.length === 0)
                                    return (

                                        <div className="col-12" style={{textAlign:"center"}}>
                                            <h2>현재 마을에 있는 플레이어가 없습니다</h2>
                                        </div>
                                    )
                                else
                                    return (
                        <div className="col-12" style={{textAlign:"center"}}>
                            <select className="custom-select" style={{width: '175px', cursor: 'pointer'}} onChange={e => setEnemy(enemy_list[e.currentTarget.value])}>
                                {enemy_list.map((character, index)=>(
                                    <option key={index} value={index}>{character.name}</option>
                                ))}
                            </select>
                            <table className="table" style={{minWidth:"300px", width:"60%",
                                margin:"auto", marginTop:"30px", marginBottom:"30px"}}>
                                <thead>
                                <tr>
                                    <th width="50%">이름</th>
                                    <th width="20%">레벨</th>
                                    <th width="30%">직업</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{enemy["name"]}</td>
                                    <td>{enemy["lev"]}</td>
                                    <td>{jobCounter(enemy["job"], enemy["job_class"])}</td>
                                </tr>
                                </tbody>
                            </table>
                            <button className="btn btn-danger" onClick={()=>{handleSubmit()}}>
                                전투
                            </button>
                        </div>
                                    )

                            })()}
                    </div>
                </div>
            </div>
        )
    else
        return (
            <div className="section">
                <div className="container text-center">
                    <h1>연습 전투</h1>
                    <button className=" nav-item bg-light border"
                            onClick={()=>{setBattle('null')}}>돌아가기
                    </button>
                    {BattlePage(
                        battle,
                        isPlayerEquip,
                        setIsPlayerEquip,
                        isEnemyEquip,
                        setIsEnemyEquip
                    )}
                </div>
            </div>
        )
}

export default PractiveBattle