import React, {useEffect, useState} from "react";
import {useDispatch/*, useSelector*/} from "react-redux";
import {useToasts} from "react-toast-notifications";
import {errorOccur, guildMasterPassed, guildOpDown, guildOpLimit, guildOpUP} from "../../../utils/toast"
import {jobCounter} from "../../../utils/character";
import { useLayoutEffect } from "react";
import {
    getGuildMemberList,
    getMyMemberData,
    guildOP_0,
    guildOP_1,
    guildOP_2,
    kickGuildMember
} from "../../../utils/api/guild";

function ManageMember(props) {
    // const guild = useSelector(state=>state.guild_info)
    const [member, setMember] = useState([])
    const [my_guild_info, setMyGuildInfo] = useState()
    const [tempAction, setTempAction] = useState(0)
    const dispatch = useDispatch()
    const { addToast } = useToasts()

    const go_apply=()=>{
        props.history.push('/guild/manage/apply')
    }

    useLayoutEffect(()=>{
        window.jQuery('[data-modal="promote"]').click(function(){
            window.jQuery('#guildPromote').modal('show');
        });
        window.jQuery('[data-modal="demote"]').click(function(){
            window.jQuery('#guildDemote').modal('show');
        });
        window.jQuery('[data-modal="kickout"]').click(function(){
            window.jQuery('#guildKickOut').modal('show');
        });
        window.jQuery('[data-modal="entrust"]').click(function(){
            window.jQuery('#guildEntrust').modal('show');
        });
    })
    useEffect(()=>{
        getMyMemberData()
            .then(res=> setMyGuildInfo(res.data))
            .catch(error=>{
                if(error.response.status===401) dispatch({type:'EXPIRED', payload:true})
                else errorOccur(addToast)
            })
    },[dispatch, addToast])
    useEffect(()=>{
        getGuildMemberList()
            .then(res=> setMember(res.data))
    },[dispatch, addToast])

    const handlePromote = () => {
        guildOP_1(member[tempAction].character__name)
            .then(()=>{
                guildOpUP(addToast)
                 getMyMemberData().then(res=>{
                    dispatch({type:"GUILD", payload:res.data[0]})
                    getGuildMemberList().then(res=> setMember(res.data))
                })
                setMember([])
            })
            .catch((error)=>{
                if(error.response.status===401) dispatch({type:'EXPIRED', payload:true})
                else if (error.response.status===423)guildOpLimit(addToast)
                else errorOccur(addToast)
            })
    }
    const handleDemote = () => {
        guildOP_0(member[tempAction].character__name)
            .then(()=>{
                guildOpDown(addToast)
                getMyMemberData().then(res=>{
                    dispatch({type:"GUILD", payload:res.data[0]})
                    getGuildMemberList().then(res=> setMember(res.data))
                })
                setMember([])
            })
            .catch(error=>{
                if(error.response.status===401) dispatch({type:'EXPIRED', payload:true})
                else errorOccur(addToast)
            })
    }
    const handleKickOut = () => {
        kickGuildMember(member[tempAction].character__name)
            .then(()=>{
                getMyMemberData().then(res=>{
                    dispatch({type:"GUILD", payload:res.data[0]})
                    getGuildMemberList().then(res=> setMember(res.data))
                    setTempAction(0)
                })
            })
            .catch(error=>{
                if(error.response.status===401) dispatch({type:'EXPIRED', payload:true})
                else errorOccur(addToast)
            })
    }
    const handleEntrust = () => {
        guildOP_2(member[tempAction].character__name)
            .then(()=>{
                guildMasterPassed(addToast)
                getMyMemberData().then(res=>dispatch({type:"GUILD", payload:res.data[0]}))
                props.history.push("/guild/info")
            })
            .catch(error=>{
                if(error.response.status===401) dispatch({type:'EXPIRED', payload:true})
                else errorOccur(addToast)
            })
    }

    if(my_guild_info===undefined || member.length===0) {
        return(<>loading</>)
    }
    else {
        return(
            <div className="section">
                <div className="container">
                    <br/><br/><br/>
                    <h1>
                        길드원 관리
                        <button className="btn btn-outline-primary" style={{float:"right"}} onClick={()=>go_apply()}>길드 신청 관리</button>
                    </h1>
                    <div className="row" style={{marginTop:"3%"}}>
                        <div className="col-md-12">
                            <table className="table table-bordered" style={{tableLayout: 'fixed'}}>
                                <thead>
                                <tr>
                                    <th>직위</th>
                                    <th>이름</th>
                                    <th>직업</th>
                                    <th>레벨</th>
                                    <th>길드 기여도</th>
                                    <th>관리 항목</th>
                                </tr>
                                </thead>
                                <tbody>
                                {member.map((mem, index) => (
                                    <tr key={index}>
                                        {(() => {
                                            if (mem.op === 2) {
                                                return (
                                                    <td>길드장</td>
                                                )
                                            } else if (mem.op === 1) {
                                                return (
                                                    <td>길드임원</td>
                                                )
                                            } else {
                                                return (
                                                    <td>길드원</td>
                                                )
                                            }
                                        })()}
                                        <td>{ mem.character__name }</td>
                                        <td>{ jobCounter(mem.character__job, mem.character__job_class) }</td>
                                        <td>{ mem.character__lev }</td>
                                        <td>{ mem.donate }</td>
                                        <td>
                                            {(() => {if (my_guild_info[0].op === 2) {
                                                if (mem.op === 2) {return(<>
                                                    <div className="dropdown show">
                                                        <button className="btn btn-outline-dark" style={{float:"left"}} data-toggle="dropdown">
                                                            위임
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                            {member.map((mem,index) => (
                                                                mem.op < 2 ?
                                                                    <div key={index} className="dropdown-item" data-modal="entrust"
                                                                         onClick={()=>setTempAction(member.indexOf(mem))}>{mem.character__name}</div>
                                                                    :<></>))}
                                                        </div>
                                                    </div>
                                                    <div className="modal" id="guildEntrust" tabIndex="-1" role="dialog">
                                                        <div className="modal-dialog" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="guildEntrust">길드장 위임</h5>
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <p>정말 {member[tempAction].character__name}에게 길드장을 위임하시겠습니까?</p>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-outline-primary" data-dismiss="modal" onClick={()=>handleEntrust()}>확인</button>
                                                                    <button type="button" className="btn btn-outline-danger" data-dismiss="modal">취소</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>)}
                                                else if (mem.op === 1) {return(<>
                                                    <button data-modal="demote" className="btn btn-outline-warning"
                                                            style={{float:"left"}} data-target="#guildDemote"
                                                            onClick={()=>setTempAction(member.indexOf(mem))}>
                                                        강등
                                                    </button>
                                                    <div className="modal" id="guildDemote" tabIndex="-1" role="dialog">
                                                        <div className="modal-dialog" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="guildDemote">길드임원 강등</h5>
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <p>정말 {member[tempAction].character__name}을 길드원으로 강등하시겠습니까?</p>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-outline-primary" data-dismiss="modal" onClick={()=>handleDemote()}>확인</button>
                                                                    <button type="button" className="btn btn-outline-danger" data-dismiss="modal">취소</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>)}
                                                else if (mem.op === 0) {return(<>
                                                    <button data-modal="promote" className="btn btn-outline-primary"
                                                            style={{float:"left"}} data-target="#guildPromote"
                                                            onClick={()=>setTempAction(member.indexOf(mem))}>
                                                        임명
                                                    </button>
                                                    <button data-modal="kickout" className="btn btn-outline-danger"
                                                            style={{float:"right"}} data-target="#guildKickOut"
                                                            onClick={()=>setTempAction(member.indexOf(mem))}>
                                                        퇴출
                                                    </button>
                                                    <div className="modal" id="guildKickOut" tabIndex="-1" role="dialog">
                                                        <div className="modal-dialog" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="guildKickOut">길드원 퇴출</h5>
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <p>정말 {member[tempAction].character__name}을 퇴출하시겠습니까?</p>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-outline-primary" data-dismiss="modal" onClick={()=>handleKickOut()}>확인</button>
                                                                    <button type="button" className="btn btn-outline-danger" data-dismiss="modal">취소</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal" id="guildPromote" tabIndex="-1" role="dialog">
                                                        <div className="modal-dialog" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="guildPromote">길드임원 임명</h5>
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <p>정말 {member[tempAction].character__name}을 길드임원으로 임명하시겠습니까?</p>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-outline-primary" data-dismiss="modal" onClick={()=>handlePromote()}>확인</button>
                                                                    <button type="button" className="btn btn-outline-danger" data-dismiss="modal">취소</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>)}}
                                            else if (my_guild_info[0].op === 1) {
                                                if (mem.op === 0) {return(<>
                                                    <button data-modal="kickout" className="btn btn-outline-danger" style={{float:"right"}} data-target="#guildKickOut" onClick={()=>setTempAction(member.indexOf(mem))}>
                                                        퇴출
                                                    </button>
                                                    <div className="modal" id="guildKickOut" tabIndex="-1" role="dialog">
                                                        <div className="modal-dialog" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="guildKickOut">길드원 퇴출</h5>
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <p>정말 {member[tempAction].character__name}을 퇴출하시겠습니까?</p>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-outline-primary" data-dismiss="modal" onClick={()=>handleKickOut()}>확인</button>
                                                                    <button type="button" className="btn btn-outline-danger" data-dismiss="modal">취소</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>)}}
                                            })()}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ManageMember