import React, { useState, useEffect } from "react";
import { getConquerInfo } from "../../utils/api/town";
import { useSelector } from "react-redux";

export default function TownConquer() {
  const [conquerInfo, setConquerInfo] = useState(false);
  const townPosition = useSelector((state) => state.town_position).value;

  useEffect(() => {
    getConquerInfo().then((res) => {
      setConquerInfo(res.data);
    });
  }, []);

  return (
    <div className="section">
      <div></div>
      <div className="container">
        <br />
        <br />
        <br />
        <h1>점령 현황</h1>
        <br />
        <div className="row">
          <div
            className="col-md-8"
            style={{
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
              padding: "0",
            }}
          >
            <img
              src="/map.png"
              className="img-responsive"
              style={{
                position: "absolute",
                left: "0%",
                width: "100%",
                height: "100%",
              }}
              alt="map"
            />
            {townPosition.map((floor, id) => (
              <div key={id}>
                {floor.map((room, id) => (
                  <span
                    key={id}
                    style={{
                      position: "relative",
                      border: "1px solid transparent",
                      width: "11.11%",
                      paddingBottom: "10.7%",
                      float: "left",
                    }}
                  >
                    {conquerInfo[room] && conquerInfo[room]["guild__name"] ? (
                      <span
                        style={{
                          border: "1px solid gray",
                          backgroundColor: "white",
                          fontWeight: "bold",
                          position: "absolute",
                          bottom: "0%",
                          left: "50%",
                          transform: "translateX(-50%)",
                          width: `${
                            /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(
                              conquerInfo[room]["guild__name"]
                            ) && conquerInfo[room]["guild__name"].length === 3
                              ? "2.3rem"
                              : /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(
                                  conquerInfo[room]["guild__name"]
                                ) && conquerInfo[room]["guild__name"].length > 3
                              ? "2.3rem"
                              : "auto"
                          }`,
                          fontSize: `${
                            /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(
                              conquerInfo[room]["guild__name"]
                            ) && conquerInfo[room]["guild__name"].length >= 5
                              ? "0.5rem"
                              : "0.7rem"
                          }`,
                        }}
                      >
                        {conquerInfo[room]["guild__name"]}
                      </span>
                    ) : null}
                  </span>
                ))}
              </div>
            ))}
          </div>
        </div>
        <br />
        <div className="row">
          <div
            className="col-md-8"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "right",
            }}
          >
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
