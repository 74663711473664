import React from 'react';
import WebSocketProvider from './websocketProvider';
import Chatting from './chatting';
import TextInputBox from './text_input_box';
import ChatHeader from "./chat_header";

function ChatParent() {
    return (
        <div style={{height:"100%"}}>
            <WebSocketProvider>
                <ChatHeader />
                <Chatting />
                <TextInputBox />
            </WebSocketProvider>
        </div>
    );
}

export default ChatParent;