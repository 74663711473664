import React, { useState, useEffect } from "react";
import {useSelector} from "react-redux";
import { getGuildInfo } from "../../utils/api/guild";

function GuildManagement(props) {
    const character  = useSelector(state=>state.character).value
    const town = useSelector(state=>state.town).value
    const [guildMoney, setGuildMoney] = useState()

    useEffect(() => {
        getGuildInfo().then(res => setGuildMoney(res.data.money))
    })

    return(
        <div className="section" style={{minHeight:"50%"}}>
            <div className="container">
                <h1>길드 관리</h1>
                <h5 style={{ textAlign: 'right' }}>길드 자금: {guildMoney ? guildMoney.toLocaleString() : null}</h5>
                <div className="row" style={{ marginBottom: "5%" }}>
                    <div className="col-md-3">
                        <button style={{padding:"0"}} onClick={()=>props.history.push("/guild/manage/info")}>
                            <img src="/placeholder.png" alt="guild_info"
                                 className="img-responsive" style={{height: '100%', width: '100%', objectFit: 'contain'}}/>
                            <div className="carousel-caption text-dark">
                                <h4>길드 정보</h4>
                            </div>
                        </button>
                    </div>
                    <div className="col-md-3">
                        <button style={{padding:"0"}} onClick={()=>props.history.push("/guild/manage/member")}>
                            <img src="/placeholder.png" alt="guild_member"
                                 className="img-responsive" style={{height: '100%', width: '100%', objectFit: 'contain'}}/>
                            <div className="carousel-caption text-dark">
                                <h4>길드 인원</h4>
                            </div>
                        </button>
                    </div>
                    <div className="col-md-3">
                        <button style={{padding:"0"}} onClick={()=>props.history.push("/guild/manage/war")}>
                            <img src="/placeholder.png" alt="guild_war"
                                 className="img-responsive" style={{height: '100%', width: '100%', objectFit: 'contain'}}/>
                            <div className="carousel-caption text-dark">
                                <h4>전쟁</h4>
                            </div>
                        </button>
                    </div>
                    {(()=>{
                        if(town[0]['own'] === character.guild)
                            return(
                                <div className="col-md-3">
                                <button style={{padding:"0"}} onClick={()=>props.history.push("/guild/manage/town")}>
                                    <img src="/placeholder.png" alt="city_info"
                                         className="img-responsive" style={{height: '100%', width: '100%', objectFit: 'contain'}}/>
                                    <div className="carousel-caption text-dark">
                                        <h4>도시 관리</h4>
                                    </div>
                                </button>
                            </div>)
                    })()}
                </div>
            </div>
        </div>
    )
}

export default GuildManagement;