import React, {useEffect, useState} from "react";
import {useToasts} from "react-toast-notifications";
import {jobCounter} from "../../../utils/character";
import {useDispatch, useSelector} from "react-redux";
import {errorOccur, guildWarAttackNotAvailableCity, warClameLessHour} from "../../../utils/toast";
import {
    clameWar,
    getAttackParticipation,
    getBoundaryCity,
    getDefendList,
    getDefendParticipation,
} from "../../../utils/api/war";
import { getTown, getConquerInfo } from "../../../utils/api/town";

function ManageWar() {
    const { addToast } = useToasts()
    const dispatch = useDispatch()
    const town = useSelector(state => state.town).value
    const [defendList, setDefendList] = useState([])
    const [attackList, setAttackList] = useState([])
    const [realDefend, setRealDefend] = useState([])
    const [time, setTime] = useState(0)
    const townPosition = useSelector((state) => state.town_position).value;
    const [conquerInfo, setConquerInfo] = useState(false);

    useEffect(()=>{
        getConquerInfo().then((res) => {
            getBoundaryCity()
                .then(boundaryRes=>{

                    for (let i = 0; i < res.data.length; i++) {
                        boundaryRes.data.forEach(boundaryCity => {
                            if (res.data[i].name === boundaryCity.nameKorean) {
                                res.data[i].bound = true
                            }
                        })
                    }
                    setConquerInfo(res.data)
                })
                .catch((error)=> {
                    if (error.response.status === 401) dispatch({type: 'EXPIRED', payload: true})
                    else if (error.response.status === 428) warClameLessHour(addToast)
                    else errorOccur(addToast)
                })
        });
    },[addToast, dispatch, town]);
    useEffect(()=>{
        getAttackParticipation()
            .then(res=>setAttackList(res.data))
    },[town]);
    useEffect(()=>{
        getDefendParticipation()
            .then(res=>setDefendList(res.data))
    },[town]);
    useEffect(()=>{
        getDefendList()
            .then(res=>setRealDefend(res.data))
    },[town]);
    const timeChange = event => setTime(event.target.value);
    const handleSubmit = (room) => {
        if(0>time && time>59)
            addToast('불가능한 시간입니다.', {
                appearance: 'warning',
                autoDismiss: true,
            })
        else
            clameWar(room, "20:"+String(time))
                .then(()=>{
                    getTown().then(response=>dispatch({type:"TOWN", payload: response.data }))
                    addToast('공격 신청을 완료했습니다.', {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                })
                .catch((error)=> {
                    if (error.response.status === 401) dispatch({type: 'EXPIRED', payload: true})
                    else if (error.response.status === 412) guildWarAttackNotAvailableCity(addToast)
                    else if (error.response.status === 428) warClameLessHour(addToast)
                    else errorOccur(addToast)
                })
    }

    return(
        <div className="section">
            <div className="container" style={{textAlign:"center"}}>
                <h1 style={{marginBottom:'5%'}}>전쟁 관리</h1>
                <div className="row">
                    <div className="col-md-4" style={{minHeight:"400px"}}>
                        <div className="col-12" style={{minHeight:"200px", padding:"0px"}}>
                            <h3 style={{marginBottom:"30px"}}>진군 계획</h3>
                            <div className="border border-dark" style={{marginLeft:"auto", marginRight:"auto", width:"100%", padding:"20px"}}>
                                8시 <input type="number" defaultValue="0" min="0" max="59" onChange={timeChange}/>분
                                <button
                                    className="btn btn-danger "
                                    style={{ marginLeft: "20px" }}
                                    onClick={() => {
                                        return window.jQuery("#attackCity").modal("show");
                                    }}
                                >
                                    진군
                                </button>
                            </div>
                        </div>

                        <div className="dropdown-divider" style={{marginTop:"40px", marginBottom:"40px"}}/>
                        <div className="col-12" style={{minHeight:"200px", padding:"0px"}}>
                            <h3 style={{marginBottom:"30px"}}>공격 신청 상황</h3>
                            {attackList.length < 1 ? <><br/><h4>없음</h4></>:<></>}
                            {attackList.map(town => {return(
                                <>
                                    <div className="list-group-item border-bottom-0 border-dark">
                                        <b>{town[0]}</b> : 총 {town.length-1} 명
                                    </div>
                                    {town.length !== 1?
                                        <table className="table table-striped table-bordered">
                                            <thead>
                                            <tr>
                                                <th width="15%">레벨</th>
                                                <th width="35%">직업</th>
                                                <th width="50%">이름</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {town.slice(1).map(single=>(
                                                <tr>
                                                    <td width="15%">{single.character__lev}</td>
                                                    <td width="35%">{jobCounter(single.character__job, single.character__job_class)}</td>
                                                    <td width="50%">{single.character__name}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>: <></>}
                                </>
                            )})
                            }
                        </div>
                        <div className="dropdown-divider" style={{marginTop:"40px", marginBottom:"40px"}}/>
                    </div>
                    <div className="col-md-4">
                        <div className="col-12" style={{minHeight:"200px", padding:"0px"}}>
                            <h3 style={{marginBottom:"30px"}}>수비 신청 상황</h3>
                            {defendList.length < 1 ? <><br/><h4>없음</h4></>:<></>}
                            {defendList.map(town => {return(
                                <>
                                    <div className="list-group-item border-bottom-0 border-dark">
                                        <b>{town[0]}</b> : 총 {town.length-1} 명
                                    </div>
                                    {town.length !== 1?
                                        <table className="table table-striped table-bordered">
                                            <thead>
                                            <tr>
                                                <th width="20%">레벨</th>
                                                <th width="35%">직업</th>
                                                <th width="45%">이름</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {town.slice(1).map(single=>(
                                                <tr>
                                                    <td width="20%">{single.character__lev}</td>
                                                    <td width="35%">{jobCounter(single.character__job, single.character__job_class)}</td>
                                                    <td width="45%">{single.character__name}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>: <></>}
                                </>
                            )})
                            }
                        </div>
                        <div className="dropdown-divider" style={{marginTop:"40px", marginBottom:"40px"}}/>
                    </div>
                    <div className="col-md-4">
                        <div className="col-12" style={{minHeight:"200px", padding:"0px"}}>
                            <h3 style={{marginBottom:"30px"}}>현재 수비 상황</h3>
                            {realDefend.length < 1 ? <><br/><h4>없음</h4></>:<></>}
                            {realDefend.map(town => {return(
                                <>
                                    <div className="list-group-item border-bottom-0 border-dark">
                                        <b>{town[0]}</b> : 총 {town.length-1} 명
                                    </div>
                                    {town.length !== 1?
                                        <table className="table table-striped table-bordered">
                                            <thead>
                                            <tr>
                                                <th width="20%">레벨</th>
                                                <th width="35%">직업</th>
                                                <th width="45%">이름</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {town.slice(1).map(single=>(
                                                <tr>
                                                    <td width="20%">{single.character__lev}</td>
                                                    <td width="35%">{jobCounter(single.character__job, single.character__job_class)}</td>
                                                    <td width="45%">{single.character__name}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>: <></>}
                                </>
                            )})
                            }
                        </div>
                    </div>
                </div>

                <div className="modal" id="attackCity" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">진군</h5>
                            </div>
                            <div className="modal-body" style={{ padding: 0 }}>
                                <div>
                                    <div style={{ position: 'absolute' }}>
                                        <img
                                            alt="map"
                                            src="/map.png"
                                            className="img-responsive"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        />
                                    </div>
                                    {townPosition.map((floor, id) => (
                                        <div key={id}>
                                            {floor.map((room, id) => (
                                                <span
                                                    key={id}
                                                    style={{
                                                        position: "relative",
                                                        float: "left",
                                                        border: "1px solid tranparent",
                                                        width: "11.11%",
                                                        paddingBottom: "11.11%",
                                                    }}
                                                >
                                                    {conquerInfo[room] && conquerInfo[room].bound ?
                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                                position: 'absolute',
                                                                left: '10%',
                                                                top: '10%',
                                                                border: "5px solid black",
                                                                width: "80%",
                                                                height: '80%',
                                                            }}
                                                            onClick={() => {
                                                                handleSubmit(room)
                                                                return window.jQuery("#attackCity").modal("hide");
                                                            }}
                                                        >
                                                        </span> : null}
                                                </span>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageWar