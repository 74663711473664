import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSkillAdaptPoints,
  getActiveSkillPoints,
  getPassiveSkillPoints,
} from "../../utils/api/skill";
import { useToasts } from "react-toast-notifications";
import {
  errorOccur,
  activeAdapt,
  activeReset,
  slotReset,
} from "../../utils/toast";
import {
  getSum,
  active_skill_list,
  getSkillInfo,
  empty_skill_slot_list,
  initialSlotList,
} from "../../utils/skill";
import axios from "axios";
import { api_url } from "../../utils/api/basic";

export default function ActiveSkill(props) {
  const character = useSelector((state) => state.character).value;
  const [newPoint, setNewPoint] = useState();
  const [curPoint, setCurPoint] = useState();
  const [initial, setInitial] = useState();
  const [adapt, setAdapt] = useState();
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  function skill_point(job_class, skill_number, calculation) {
    const new_array = adapt;
    if (calculation === "plus") {
      if (
        curPoint >= 1 &&
        initial[job_class][skill_number] + adapt[job_class][skill_number] < 5
      ) {
        new_array[job_class][skill_number] += 1;
        setAdapt(new_array);
        setCurPoint(curPoint - 1);
      }
    } else {
      if (
        character.lev - curPoint >= 1 &&
        adapt[job_class][skill_number] >= 1
      ) {
        new_array[job_class][skill_number] -= 1;
        setAdapt(new_array);
        setCurPoint(curPoint + 1);
      }
    }
  }
  const handleSubmit = () => {
    let body = {};
    for (let jobClass = 0; jobClass <= character.job_class; jobClass++) {
      for (let index = 0; index < initial[jobClass].length; index++) {
        body[`class${jobClass}_${index + 1}`] =
          initial[jobClass][index] + adapt[jobClass][index];
      }
    }
    axios
      .post(api_url + "/api/v1/active_skill/", body, {
        headers: { Authorization: `jwt ${localStorage.getItem("token")}` },
      })
      .then(() => {
        let newInitial = initial.slice();
        for (let i = 0; i < newInitial.length; i++) {
          for (let j = 0; j < newInitial[i].length; j++) {
            newInitial[i][j] = newInitial[i][j] + adapt[i][j];
          }
        }
        setInitial(newInitial);
        let newAdapt = resetSlot(adapt);
        setAdapt(newAdapt);
        activeAdapt(addToast);
      });

    axios.post(
      api_url + "/api/v1/skill_slot/",
      {
        slot_first: slotList[0].title,
        slot_1: slotList[1].title,
        slot_2: slotList[2].title,
        slot_3: slotList[3].title,
        slot_4: slotList[4].title,
        slot_5: slotList[5].title,
        slot_6: slotList[6].title,
        percent_slot_1: slotList[1].per,
        percent_slot_2: slotList[2].per,
        percent_slot_3: slotList[3].per,
        percent_slot_4: slotList[4].per,
        percent_slot_5: slotList[5].per,
        percent_slot_6: slotList[6].per,
      },
      { headers: { Authorization: `jwt ${localStorage.getItem("token")}` } }
    );
  };
  function resetSlot(slot) {
    for (let i = 0; i < slot.length; i++) {
      for (let j = 0; j < slot[i].length; j++) {
        slot[i][j] = 0;
      }
    }
    return slot;
  }
  const handleReset = () => {
    axios
      .put(
        api_url + "/api/v1/active_skill/",
        {},
        { headers: { Authorization: `jwt ${localStorage.getItem("token")}` } }
      )
      .then(() => {
        let newInitial = resetSlot(initial);
        setInitial(newInitial);
        let newAdapt = resetSlot(adapt);
        setAdapt(newAdapt);
        setCurPoint(newPoint);
        activeReset(addToast);
      });
  };

  // Drag & Drop //
  const [slotList, setSlotList] = useState(empty_skill_slot_list);
  const [dragAndDrop, setDragAndDrop] = useState({
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: [],
  });
  const onDragStart = (event) => {
    const initialPosition = event.currentTarget.dataset.position;
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: slotList,
    });
    event.dataTransfer.setData("text/html", "");
  };
  const onDragOver = (event) => {
    event.preventDefault();
    if (dragAndDrop.draggedFrom.slice(0, 4) === "list") {
      let newList = dragAndDrop.originalOrder;
      const draggedFrom = dragAndDrop.draggedFrom;
      const draggedTo = event.currentTarget.dataset.position;
      const jobclass = draggedFrom[4];
      let itemDragged =
        active_skill_list[character.job][jobclass][
          Number(draggedFrom.slice(6))
        ];
      if (Number(draggedTo.slice(5)) === newList.length) {
        newList = [
          ...newList.slice(0, Number(draggedTo.slice(5))),
          itemDragged,
        ];
      } else {
        newList = [
          ...newList.slice(0, Number(draggedTo.slice(5))),
          itemDragged,
          ...newList.slice(Number(draggedTo.slice(5)) + 1),
        ];
      }
      if (draggedTo !== dragAndDrop.draggedTo) {
        setDragAndDrop({
          ...dragAndDrop,
          updatedOrder: newList,
          draggedTo: draggedTo,
        });
      }
    } else if (dragAndDrop.draggedFrom.slice(0, 5) === "slot_") {
      let newList = dragAndDrop.originalOrder;
      const draggedFrom = dragAndDrop.draggedFrom;
      const draggedTo = event.currentTarget.dataset.position;
      const itemDragged = newList[Number(draggedFrom.slice(5))];
      const itemToReplaced = newList[Number(draggedTo.slice(5))];
      if (Number(draggedFrom.slice(5)) < Number(draggedTo.slice(5))) {
        if (Number(draggedTo.slice(5)) === newList.length) {
          newList = [
            ...newList.slice(0, Number(draggedFrom.slice(5))),
            itemToReplaced,
            ...newList.slice(
              Number(draggedFrom.slice(5)) + 1,
              Number(draggedTo.slice(5))
            ),
            itemDragged,
          ];
        } else {
          newList = [
            ...newList.slice(0, Number(draggedFrom.slice(5))),
            itemToReplaced,
            ...newList.slice(
              Number(draggedFrom.slice(5)) + 1,
              Number(draggedTo.slice(5))
            ),
            itemDragged,
            ...newList.slice(Number(draggedTo.slice(5)) + 1),
          ];
        }
      } else if (Number(draggedFrom.slice(5)) > Number(draggedTo.slice(5))) {
        if (Number(draggedFrom.slice(5)) === newList.length) {
          newList = [
            ...newList.slice(0, Number(draggedTo.slice(5))),
            itemDragged,
            ...newList.slice(
              Number(draggedTo.slice(5)) + 1,
              Number(draggedFrom.slice(5))
            ),
            itemToReplaced,
          ];
        } else {
          newList = [
            ...newList.slice(0, Number(draggedTo.slice(5))),
            itemDragged,
            ...newList.slice(
              Number(draggedTo.slice(5)) + 1,
              Number(draggedFrom.slice(5))
            ),
            itemToReplaced,
            ...newList.slice(Number(draggedFrom.slice(5)) + 1),
          ];
        }
      }
      if (draggedTo !== dragAndDrop.draggedTo) {
        setDragAndDrop({
          ...dragAndDrop,
          updatedOrder: newList,
          draggedTo: draggedTo,
        });
      }
    }
  };
  const onDrop = (event) => {
    setSlotList(dragAndDrop.updatedOrder);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
    });
  };
  const onDragLeave = (event) => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null,
    });
  };
  const [targetSkill, setTargetSkill] = useState();
  function skillChange(index) {
    if (targetSkill) {
      slotList[index] = targetSkill;
      setSlotList(slotList.slice());
      setTargetSkill();
    }
  }
  /////////////////
  const skillPerChange = (event) => {
    let tempList = slotList;
    const currentPos = Number(event.currentTarget.dataset.position.slice(5));
    tempList[currentPos].per = Number(event.target.value);
    setSlotList(tempList);
  };
  const skillPointCheck = (draggedFrom) => {
    if (draggedFrom.slice(0, 4) === "list") {
      const jobclass = Number(draggedFrom[4]);
      const skillnum = Number(draggedFrom.slice(6));
      if (initial[jobclass][skillnum] + adapt[jobclass][skillnum] === 0) {
        return "false";
      } else {
        return "true";
      }
    }
  };

  useLayoutEffect(() => {
    window
      .jQuery('[data-toggle="popover"]')
      .popover({
        animation: true,
        container: "body",
        html: true,
        placement: "left",
        trigger: "focus",
      })
      .on("show.bs.popover", function (e) {
        let skillList = active_skill_list[character.job];
        for (let i = 0; i < skillList.length; i++) {
          for (let j = 0; j < skillList[i].length; j++) {
            if (skillList[i][j].id === e.target.id) {
              if (
                initial[skillList[i][j].id[1]][j] +
                  adapt[skillList[i][j].id[1]][j] !==
                0
              ) {
                setTargetSkill(skillList[i][j]);
              }
            }
          }
        }
      });
  });

  useEffect(() => {
    if (character !== "null" && !!localStorage.getItem("token")) {
      getPassiveSkillPoints()
        .then((passiveRes) => {
          setNewPoint(character.lev - getSum(passiveRes.data));

          if (Number.isInteger(newPoint))
            getActiveSkillPoints().then((res) => {
              setCurPoint(
                character.lev - getSum(passiveRes.data) - getSum(res.data)
              );

              getSkillAdaptPoints().then((response) => {
                if (character.job === "없음") {
                  setInitial([[res.data.class0_1]]);
                  setAdapt([[0]]);
                  setSlotList(
                    initialSlotList(
                      response.data,
                      active_skill_list["없음"],
                      [[res.data.class0_1]],
                      character
                    )
                  );
                } else if (character.job === "투술") {
                  setInitial([
                    [res.data.class0_1],
                    [res.data.class1_1, res.data.class1_2],
                    [res.data.class2_1, res.data.class2_2, res.data.class2_3],
                    [
                      res.data.class3_1,
                      res.data.class3_2,
                      res.data.class3_3,
                      res.data.class3_4,
                      res.data.class3_5,
                    ],
                  ]);
                  setAdapt([[0], [0, 0], [0, 0, 0], [0, 0, 0, 0, 0]]);
                  setSlotList(
                    initialSlotList(
                      response.data,
                      active_skill_list[character.job],
                      [
                        [res.data.class0_1],
                        [res.data.class1_1, res.data.class1_2],
                        [
                          res.data.class2_1,
                          res.data.class2_2,
                          res.data.class2_3,
                        ],
                        [
                          res.data.class3_1,
                          res.data.class3_2,
                          res.data.class3_3,
                          res.data.class3_4,
                          res.data.class3_5,
                        ],
                      ],
                      character
                    )
                  );
                } else if (character.job === "검술") {
                  setInitial([
                    [res.data.class0_1],
                    [res.data.class1_1, res.data.class1_2],
                    [
                      res.data.class2_1,
                      res.data.class2_2,
                      res.data.class2_3,
                      res.data.class2_4,
                    ],
                    [
                      res.data.class3_1,
                      res.data.class3_2,
                      res.data.class3_3,
                      res.data.class3_4,
                    ],
                  ]);
                  setAdapt([[0], [0, 0], [0, 0, 0, 0], [0, 0, 0, 0]]);
                  setSlotList(
                    initialSlotList(
                      response.data,
                      active_skill_list[character.job],
                      [
                        [res.data.class0_1],
                        [res.data.class1_1, res.data.class1_2],
                        [
                          res.data.class2_1,
                          res.data.class2_2,
                          res.data.class2_3,
                          res.data.class2_4,
                        ],
                        [
                          res.data.class3_1,
                          res.data.class3_2,
                          res.data.class3_3,
                          res.data.class3_4,
                        ],
                      ],
                      character
                    )
                  );
                } else if (character.job === "마술") {
                  setInitial([
                    [res.data.class0_1],
                    [res.data.class1_1, res.data.class1_2, res.data.class1_3],
                    [
                      res.data.class2_1,
                      res.data.class2_2,
                      res.data.class2_3,
                      res.data.class2_4,
                      res.data.class2_5,
                    ],
                    [
                      res.data.class3_1,
                      res.data.class3_2,
                      res.data.class3_3,
                      res.data.class3_4,
                      res.data.class3_5,
                    ],
                  ]);
                  setAdapt([[0], [0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0]]);
                  setSlotList(
                    initialSlotList(
                      response.data,
                      active_skill_list[character.job],
                      [
                        [res.data.class0_1],
                        [
                          res.data.class1_1,
                          res.data.class1_2,
                          res.data.class1_3,
                        ],
                        [
                          res.data.class2_1,
                          res.data.class2_2,
                          res.data.class2_3,
                          res.data.class2_4,
                          res.data.class2_5,
                        ],
                        [
                          res.data.class3_1,
                          res.data.class3_2,
                          res.data.class3_3,
                          res.data.class3_4,
                          res.data.class3_5,
                        ],
                      ],
                      character
                    )
                  );
                } else if (character.job === "궁술") {
                  setInitial([
                    [res.data.class0_1],
                    [res.data.class1_1, res.data.class1_2],
                    [
                      res.data.class2_1,
                      res.data.class2_2,
                      res.data.class2_3,
                      res.data.class2_4,
                    ],
                    [
                      res.data.class3_1,
                      res.data.class3_2,
                      res.data.class3_3,
                      res.data.class3_4,
                      res.data.class3_5,
                    ],
                  ]);
                  setAdapt([[0], [0, 0], [0, 0, 0, 0], [0, 0, 0, 0, 0]]);
                  setSlotList(
                    initialSlotList(
                      response.data,
                      active_skill_list[character.job],
                      [
                        [res.data.class0_1],
                        [res.data.class1_1, res.data.class1_2],
                        [
                          res.data.class2_1,
                          res.data.class2_2,
                          res.data.class2_3,
                          res.data.class2_4,
                        ],
                        [
                          res.data.class3_1,
                          res.data.class3_2,
                          res.data.class3_3,
                          res.data.class3_4,
                          res.data.class3_5,
                        ],
                      ],
                      character
                    )
                  );
                } else if (character.job === "암술") {
                  setInitial([
                    [res.data.class0_1],
                    [res.data.class1_1, res.data.class1_2, res.data.class1_3],
                    [
                      res.data.class2_1,
                      res.data.class2_2,
                      res.data.class2_3,
                      res.data.class2_4,
                      res.data.class2_5,
                    ],
                    [
                      res.data.class3_1,
                      res.data.class3_2,
                      res.data.class3_3,
                      res.data.class3_4,
                      res.data.class3_5,
                    ],
                  ]);
                  setAdapt([[0], [0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0]]);
                  setSlotList(
                    initialSlotList(
                      response.data,
                      active_skill_list[character.job],
                      [
                        [res.data.class0_1],
                        [
                          res.data.class1_1,
                          res.data.class1_2,
                          res.data.class1_3,
                        ],
                        [
                          res.data.class2_1,
                          res.data.class2_2,
                          res.data.class2_3,
                          res.data.class2_4,
                          res.data.class2_5,
                        ],
                        [
                          res.data.class3_1,
                          res.data.class3_2,
                          res.data.class3_3,
                          res.data.class3_4,
                          res.data.class3_5,
                        ],
                      ],
                      character
                    )
                  );
                } else if (character.job === "주술") {
                  setInitial([
                    [res.data.class0_1],
                    [res.data.class1_1, res.data.class1_2],
                    [
                      res.data.class2_1,
                      res.data.class2_2,
                      res.data.class2_3,
                      res.data.class2_4,
                      res.data.class2_5,
                    ],
                    [
                      res.data.class3_1,
                      res.data.class3_2,
                      res.data.class3_3,
                      res.data.class3_4,
                    ],
                  ]);
                  setAdapt([[0], [0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0]]);
                  setSlotList(
                    initialSlotList(
                      response.data,
                      active_skill_list[character.job],
                      [
                        [res.data.class0_1],
                        [res.data.class1_1, res.data.class1_2],
                        [
                          res.data.class2_1,
                          res.data.class2_2,
                          res.data.class2_3,
                          res.data.class2_4,
                          res.data.class2_5,
                        ],
                        [
                          res.data.class3_1,
                          res.data.class3_2,
                          res.data.class3_3,
                          res.data.class3_4,
                        ],
                      ],
                      character
                    )
                  );
                }
              });
            });
        })
        .catch((error) => {
          if (error.response.status === 401)
            dispatch({ type: "EXPIRED", payload: true });
          else errorOccur(addToast);
        });
    }
  }, [character, addToast, dispatch, newPoint]);

  return (
    <div className="section">
      <div className="container">
        <div
          className="row"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "300px",
          }}
        >
          <div className="col">
            <button
              className="btn btn-outline-primary"
              style={{ float: "left" }}
              onClick={() => props.history.push("/skill/passive")}
            >
              패시브 스킬
            </button>
            <button className="btn btn-warning" style={{ float: "right" }}>
              액티브 스킬
            </button>
          </div>
        </div>

        <div>
          <div style={{ textAlign: "center", marginTop: "2%" }}>
            <h5>남은 스킬포인트: {curPoint}</h5>
          </div>
          {initial && adapt ? (
            <div className="row">
              <div
                className="col-md-auto"
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                <div
                  className="col-md-auto"
                  style={{
                    float: "left",
                    textAlign: "center",
                    padding: "20px",
                  }}
                >
                  <div>기본</div>
                  <br />
                  {active_skill_list[character.job][0].map((skill, index) => (
                    <div style={{ marginBottom: "20px" }} key={index}>
                      <div
                        id={skill.id}
                        tabIndex="0"
                        className="border skill_icon"
                        style={{
                          width: "75px",
                          height: "75px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        data-toggle="popover"
                        title={skill.title}
                        data-content={
                          skill.structure +
                          getSkillInfo(
                            skill.title,
                            initial[0][index] + adapt[0][index],
                            character
                          )
                        }
                        data-position={"list" + skill.id[1] + "_" + index}
                        onDragStart={onDragStart}
                        onDragOver={onDragOver}
                        draggable={skillPointCheck(
                          "list" + skill.id[1] + "_" + index
                        )}
                      >
                        <img
                          alt={"warrior_" + skill.id}
                          src={skill.img_path}
                          style={{ width: "100%" }}
                          draggable={skillPointCheck(
                            "list" + skill.id[1] + "_" + index
                          )}
                        />
                      </div>
                      <button
                        className="btn btn-light"
                        style={{
                          width: "25px",
                          height: "25px",
                          padding: "0px",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          skill_point(0, index, "minus");
                        }}
                      >
                        -
                      </button>
                      {` ${initial[0][index] + adapt[0][index]} `}
                      <button
                        className="btn btn-light"
                        style={{
                          width: "25px",
                          height: "25px",
                          padding: "0px",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          skill_point(0, index, "plus");
                        }}
                      >
                        +
                      </button>
                    </div>
                  ))}
                </div>
                {character.job_class >= 1 ? (
                  <div
                    className="col-md-auto"
                    style={{
                      float: "left",
                      textAlign: "center",
                      padding: "20px",
                    }}
                  >
                    <div>1차</div>
                    <br />
                    {active_skill_list[character.job][1].map((skill, index) => (
                      <div style={{ marginBottom: "20px" }} key={index}>
                        <div
                          id={skill.id}
                          tabIndex="0"
                          className="border skill_icon"
                          style={{
                            width: "75px",
                            height: "75px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          data-toggle="popover"
                          title={skill.title}
                          data-content={
                            skill.structure +
                            getSkillInfo(
                              skill.title,
                              initial[1][index] + adapt[1][index],
                              character
                            )
                          }
                          data-position={"list" + skill.id[1] + "_" + index}
                          onDragStart={onDragStart}
                          onDragOver={onDragOver}
                          draggable={skillPointCheck(
                            "list" + skill.id[1] + "_" + index
                          )}
                        >
                          <img
                            alt={"job_" + skill.id}
                            src={skill.img_path}
                            style={{ width: "100%" }}
                            draggable={skillPointCheck(
                              "list" + skill.id[1] + "_" + index
                            )}
                          />
                        </div>
                        <button
                          className="btn btn-light"
                          style={{
                            width: "25px",
                            height: "25px",
                            padding: "0px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            skill_point(1, index, "minus");
                          }}
                        >
                          -
                        </button>
                        {` ${initial[1][index] + adapt[1][index]} `}
                        <button
                          className="btn btn-light"
                          style={{
                            width: "25px",
                            height: "25px",
                            padding: "0px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            skill_point(1, index, "plus");
                          }}
                        >
                          +
                        </button>
                      </div>
                    ))}
                  </div>
                ) : null}
                {character.job_class >= 2 ? (
                  <div
                    className="col-md-auto"
                    style={{
                      float: "left",
                      textAlign: "center",
                      padding: "20px",
                    }}
                  >
                    <div>2차</div>
                    <br />
                    {active_skill_list[character.job][2].map((skill, index) => (
                      <div style={{ marginBottom: "20px" }} key={index}>
                        <div
                          id={skill.id}
                          tabIndex="0"
                          className="border skill_icon"
                          style={{
                            width: "75px",
                            height: "75px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          data-toggle="popover"
                          title={skill.title}
                          data-content={
                            skill.structure +
                            getSkillInfo(
                              skill.title,
                              initial[2][index] + adapt[2][index],
                              character
                            )
                          }
                          data-position={"list" + skill.id[1] + "_" + index}
                          onDragStart={onDragStart}
                          onDragOver={onDragOver}
                          draggable={skillPointCheck(
                            "list" + skill.id[1] + "_" + index
                          )}
                        >
                          <img
                            alt={"job_" + skill.id}
                            src={skill.img_path}
                            style={{ width: "100%" }}
                            draggable={skillPointCheck(
                              "list" + skill.id[1] + "_" + index
                            )}
                          />
                        </div>
                        <button
                          className="btn btn-light"
                          style={{
                            width: "25px",
                            height: "25px",
                            padding: "0px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            skill_point(2, index, "minus");
                          }}
                        >
                          -
                        </button>
                        {` ${initial[2][index] + adapt[2][index]} `}
                        <button
                          className="btn btn-light"
                          style={{
                            width: "25px",
                            height: "25px",
                            padding: "0px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            skill_point(2, index, "plus");
                          }}
                        >
                          +
                        </button>
                      </div>
                    ))}
                  </div>
                ) : null}
                {character.job_class >= 3 ? (
                  <div
                    className="col-md-auto"
                    style={{
                      float: "left",
                      textAlign: "center",
                      padding: "20px",
                    }}
                  >
                    <div>3차</div>
                    <br />
                    {active_skill_list[character.job][3].map((skill, index) => (
                      <div style={{ marginBottom: "20px" }} key={index}>
                        <div
                          id={skill.id}
                          tabIndex="0"
                          className="border skill_icon"
                          style={{
                            width: "75px",
                            height: "75px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          data-toggle="popover"
                          title={skill.title}
                          data-content={
                            skill.structure +
                            getSkillInfo(
                              skill.title,
                              initial[3][index] + adapt[3][index],
                              character
                            )
                          }
                          data-position={"list" + skill.id[1] + "_" + index}
                          onDragStart={onDragStart}
                          onDragOver={onDragOver}
                          draggable={skillPointCheck(
                            "list" + skill.id[1] + "_" + index
                          )}
                        >
                          <img
                            alt={"job_" + skill.id}
                            src={skill.img_path}
                            style={{ width: "100%" }}
                            draggable={skillPointCheck(
                              "list" + skill.id[1] + "_" + index
                            )}
                          />
                        </div>
                        <button
                          className="btn btn-light"
                          style={{
                            width: "25px",
                            height: "25px",
                            padding: "0px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            skill_point(3, index, "minus");
                          }}
                        >
                          -
                        </button>
                        {` ${initial[3][index] + adapt[3][index]} `}
                        <button
                          className="btn btn-light"
                          style={{
                            width: "25px",
                            height: "25px",
                            padding: "0px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            skill_point(3, index, "plus");
                          }}
                        >
                          +
                        </button>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
              <div
                className="col-md-4"
                style={{ float: "right", padding: "40px" }}
              >
                <button
                  className="btn btn-danger"
                  style={{ float: "right", width: "120px" }}
                  onClick={() => {
                    setSlotList(empty_skill_slot_list);
                    slotReset(addToast);
                  }}
                >
                  슬롯 초기화
                </button>
                <br />
                <br />
                {slotList.map((item, index) => (
                  <div key={index}>
                    {(() => {
                      if (index === 0) {
                        return (
                          <div
                            id={"slot_" + index}
                            className="border"
                            style={{
                              textAlign: "center",
                              height: "76px",
                              marginBottom: "20px",
                            }}
                            onClick={() => skillChange(index)}
                          >
                            <span>{item.title}</span>
                            <span
                              key={index}
                              data-position={"slot_" + index}
                              draggable="true"
                              onDragStart={onDragStart}
                              onDragOver={onDragOver}
                              onDrop={onDrop}
                              onDragLeave={onDragLeave}
                            >
                              <span
                                className="border"
                                style={{
                                  display: "inline-block",
                                  float: "left",
                                  width: "75px",
                                  height: "75px",
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                              >
                                <img
                                  alt={"slot_" + index}
                                  src={item.img_path}
                                  style={{ width: "100%" }}
                                />
                              </span>
                              <br />
                              <div>
                                <span
                                  style={{
                                    display: "inline-block",
                                    border: "1px solid rgba(0, 0, 0, 0.15)",
                                    textAlign: "center",
                                  }}
                                >
                                  <b>전투 시작 시 자동 발동</b>
                                </span>
                              </div>
                            </span>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            id={"slot_" + index}
                            className="border"
                            style={{
                              textAlign: "center",
                              height: "76px",
                              marginBottom: "20px",
                            }}
                            onClick={() => skillChange(index)}
                          >
                            <span>{item.title}</span>
                            <span
                              key={index}
                              data-position={"slot_" + index}
                              draggable="true"
                              onDragStart={onDragStart}
                              onDragOver={onDragOver}
                              onDrop={onDrop}
                              onDragLeave={onDragLeave}
                            >
                              <span
                                className="border"
                                style={{
                                  display: "inline-block",
                                  float: "left",
                                  width: "75px",
                                  height: "75px",
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                              >
                                <img
                                  alt={"slot_" + index}
                                  src={item.img_path}
                                  style={{ width: "100%" }}
                                />
                              </span>
                              <br />
                              <div>
                                <span
                                  style={{
                                    display: "inline-block",
                                    border: "1px solid rgba(0, 0, 0, 0.15)",
                                    textAlign: "center",
                                    height: "90%",
                                    float: "left",
                                  }}
                                >
                                  시전 확률
                                </span>
                                <input
                                  data-position={"slot_" + index}
                                  style={{
                                    display: "inline-block",
                                    border: "1px solid rgba(0, 0, 0, 0.15)",
                                    textAlign: "right",
                                    width: "50%",
                                    height: "90%",
                                    float: "right",
                                  }}
                                  placeholder={item.per}
                                  onChange={skillPerChange}
                                />
                              </div>
                            </span>
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
                <button
                  className="btn btn-success"
                  style={{ float: "left", width: "100px" }}
                  onClick={() => handleSubmit()}
                >
                  적용
                </button>
                <button
                  className="btn btn-danger"
                  style={{ float: "right", width: "100px" }}
                  onClick={() => handleReset()}
                >
                  초기화
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
