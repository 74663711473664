import React, { useEffect, useState, useLayoutEffect } from "react";
import {jobCounter} from "../../utils/character";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import {actionTimeLeft, arenaNoCoin, arenaNoMatch, errorOccur, noHP,} from "../../utils/toast";
import { BattlePage } from "../../utils/battle";
import {battleArena, getMyArenaInfo, getMyGradeRank} from "../../utils/api/battle";
import {arena_grade} from "../../utils/arena";

function ArenaBattle() {
    const { addToast } = useToasts()
    const dispatch = useDispatch()
    const character = useSelector(state=>state.character).value
    const [battle, setBattle] = useState('null')
    const [grade_rank, setGradeRank] = useState([])
    const [my_info, setMyInfo] = useState({})
    const [isPlayerEquip, setIsPlayerEquip] = useState(false)
    const [isEnemyEquip, setIsEnemyEquip] = useState(false)

    useEffect(() => {
        getMyGradeRank()
            .then(res => {
                setGradeRank(res.data)
            })
            .catch(error => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else errorOccur(addToast)
            })
        getMyArenaInfo()
            .then(res=>{
                setMyInfo(res.data[0])
            })
            .catch(error => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else errorOccur(addToast)
            })
    }, [addToast, dispatch, battle]);

    useLayoutEffect(()=> {
        window.jQuery('[data-toggle="popover"]').popover({
            animation: true,
            container: "body",
            html: true,
            placement: "right",
            trigger: "hover"
        })
    })

    const handleSubmit = () => {
        battleArena()
            .then(res => {
                setBattle(res.data)
            })
            .catch((error) => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else if (error.response.status === 412) arenaNoMatch(addToast)
                else if (error.response.status === 402) arenaNoCoin(addToast)
                else if (error.response.status === 417) actionTimeLeft(addToast, error.response.data)
                else if (error.response.status === 406) noHP(addToast)
                else errorOccur(addToast)
            })
    }
    const hp = (character.hp / character.max_hp) * 100 + "%";
    const mp = (character.mp / character.max_mp) * 100 + "%";
    if (battle === 'null')
        return (
            <div className="section">
                <div className="container">
                    <h1>아레나</h1>
                    <h5 style={{ marginLeft: "30px" }}>플레이어와의 전투를 통해 등급을 올리실 수 있습니다.</h5>
                    <div className="row" style={{marginTop: 20, border:'2px solid', padding:'10px'}}>
                        <div className="col-md-6" style={{ textAlign: "center"}}>
                            <img src="/placeholder.png" className="media-object" height="256" width="256"
                                 alt="grade_image" style={{marginBottom:10}}/>
                            <table className="table table-bordered" style={{ tableLayout: 'fixed' }}>
                                <thead>
                                <tr>
                                    <th>등 급</th>
                                    <th>MMR</th>
                                    <th>입장권</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{arena_grade(my_info.grade)}</td>
                                    <td>{my_info.exp} 점</td>
                                    <td>{my_info.coin}/10</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-6"><br/>
                            <h4 className="text-center">{character.name} [Lv.{character.lev} {jobCounter(character.job, character.job_class)}]</h4>
                            <h4 className="text-right">{my_info.win}승 {my_info.lose}패
                            {(() => {
                                if (my_info.sequence > 0) {
                                    return (
                                        <>({my_info.sequence}연승)</>
                                    )
                                }
                                else if (my_info.sequence < 0) {
                                    return (
                                        <>({my_info.sequence * -1}연패)</>
                                    )
                                }
                            })()}</h4>
                            <b>HP</b>
                            <div className="progress">
                                <div className="progress-bar bg-danger" role="progressbar" style={{width: hp}}/>
                                <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                                    color="white"><b>{character.hp} / {character.max_hp}</b></font></div>
                            </div>

                            <b>MP</b>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar"  style={{width: mp}}/>
                                <div className="justify-content-center d-flex position-absolute w-100 align-self-center">
                                    <font color="white"><b>{character.mp} / {character.max_mp}</b></font></div>
                            </div>
                            <br/>
                            <table className="table">
                                <tbody>
                                <tr>
                                    <th width="50"><img src="/icon/attack.png" alt="attack icon" title="공격력"/></th>
                                    <td width="120" className="text-center">{character.attack}</td>
                                    <th width="50"><img src="/icon/magic.png" alt="attack icon" title="마력"/></th>
                                    <td width="120" className="text-center">{character.magic}</td>
                                </tr>
                                <tr>
                                    <th width="50"><img src="/icon/defend.png" alt="attack icon" title="방어력"/></th>
                                    <td width="120" className="text-center">{character.defend}</td>
                                    <th width="50"><img src="/icon/mag_defend.png" alt="attack icon" title="마법저항력"/></th>
                                    <td width="120" className="text-center">{character.mag_defend}</td>
                                </tr>
                                <tr>
                                    <th width="50"><img src="/icon/speed.png" alt="attack icon" title="속도"/></th>
                                    <td width="120" className="text-center">{character.speed}</td>
                                    <th width="50"><img src="/icon/critical.png" alt="attack icon" title="치명타율"/></th>
                                    <td width="120" className="text-center">{parseFloat(character.critical).toFixed(1)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <button className="btn btn-info btn-lg" style={{marginLeft:"auto", marginRight:"auto"}}
                        onClick={()=>{handleSubmit()}}>
                            상대 찾기
                        </button>
                    </div>
                    <div className="row" style={{ marginTop: "3%" }}>
                        <h2>등급 순위표</h2>
                        <div className="col-md-18">
                            <table className="table table-bordered" style={{ tableLayout: 'fixed' }}>
                                <thead>
                                <tr style={{ textAlign: "center" }}>
                                    <th>순 위</th>
                                    <th>이 름</th>
                                    <th>직 업</th>
                                    <th width="80px">레 벨</th>
                                    <th>등 급</th>
                                    <th>MMR</th>
                                    <th>전 적</th>
                                </tr>
                                </thead>
                                <tbody>
                                {grade_rank.map((item, index) => (
                                    <tr key={index} style={{ textAlign: "center" }}>
                                        <td>{index + 1}</td>
                                        <td>{item.character__name}</td>
                                        <td>{jobCounter(item.character__job, item.character__job_class)}</td>
                                        <td>{item.character__lev}</td>
                                        <td>{arena_grade(item.grade)}</td>
                                        <td>{item.exp} 점</td>
                                        <td>
                                            {item.win}승 {item.lose}패
                                            {(() => {
                                                if (item.sequence > 0) {
                                                    return (
                                                        <>({item.sequence}연승)</>
                                                    )
                                                }
                                                else if (item.sequence < 0) {
                                                    return (
                                                        <>({item.sequence * -1}연패)</>
                                                    )
                                                }
                                            })()}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    else
        return (
            <div className="section">
                <div className="container text-center">
                    <h1>아레나</h1>
                    <button className=" nav-item bg-light border"
                            onClick={() => { setBattle('null') }}>돌아가기
                    </button>
                    {BattlePage(
                        battle,
                        isPlayerEquip,
                        setIsPlayerEquip,
                        isEnemyEquip,
                        setIsEnemyEquip
                    )}
                </div>
            </div>
        )
}

export default ArenaBattle