import React from "react";
import Body from "./router"
import { BrowserRouter as Router} from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

function App() {
    return (
        <ToastProvider>
            <Router>
                <Body/>
            </Router>
        </ToastProvider>
    );
}
export default App;