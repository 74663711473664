import React from "react";

function Footer(props) {
    return(
        <footer className="section section-info" style={{backgroundColor: '#5bc0de', color: '#ffffff'}}>
            <div className="container">
                <div className="row">
                    <div className="col-sm-6">
                        <h1 style={{fontFamily: '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'}}>Erion World</h1>
                        <p>
                            생각치도 못한 많은 일들이
                            <br/>여러분들을 기다리고 있습니다. <br/>
                            여러분들의 이야기를 에리온 대륙에서 써내려가 보세요!</p>
                    </div>
                    <div className="col-sm-6">
                        <p className="text-left" style={{color:'rgb(0, 251, 255)'}}>
                            <br/>(주) 에리온 게임즈 Since 2020
                        </p>
                        <div className="row">
                            <div className="col-md-12 hidden-xs text-right">
                                <i className="fa fa-3x fa-fw fa-instagram text-inverse"/>
                                <i className="fa fa-3x fa-fw fa-twitter text-inverse"/>
                                <i className="fa fa-3x fa-fw fa-facebook text-inverse"/>
                                <i className="fa fa-3x fa-fw fa-github text-inverse"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer