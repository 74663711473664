import React,{useRef, useEffect} from "react";

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

function logType(type) {
    if (type === "guild") {
        return (
            <div className="btn btn-sm btn-warning disabled" style={{width:"60px"}}>
                길드
            </div>
        )
    } else if (type === "chr") {
        return (
            <div className="btn btn-sm btn-success" style={{width:"60px"}}>
                뉴비
            </div>
        )
    }else if (type === "practice") {
        return (
            <div className="btn btn-sm btn-dark" style={{width:"60px"}}>
                연습
            </div>
        )
    }else if (type === "war") {
        return (
            <div className="btn btn-sm btn-danger" style={{width:"60px"}}>
                전쟁
            </div>
        )
    }

}

function smallLogType(type) {
    if (type === "guild") {
        return (
            <div className="btn btn-sm btn-warning disabled"/>
        )
    } else if (type === "chr") {
        return (
            <div className="btn btn-sm btn-success"/>
        )
    }else if (type === "practice") {
        return (
            <div className="btn btn-sm btn-dark"/>
        )
    }else if (type === "war") {
        return (
            <div className="btn btn-sm btn-danger"/>
        )
    }

}

function setBattleLog(battle_log, dispatch, history) {
        dispatch({type:"BATTLE", payload:battle_log.battle})
        history.push('/log/battle')
}

export {logType, smallLogType, setBattleLog, useInterval}