// Components/Routes.js
import React, {useEffect, useLayoutEffect} from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {useDispatch, useSelector} from "react-redux";

import Login from './member/login'
import SignUp from './member/sign_up'
import Intro from "./intro";
import Main from "./main";
import ChrSelect from "./character/select";
import ChrInfo from "./character/info";
import FreeStat from "./character/free_stat";
import sub from "./header/sub.js";
import HeaderMain from "./header/main.js";
import PassiveSkill from "./character/passive_skill";
import ActiveSkill from "./character/active_skill";
import ChrMake from "./character/create";
import TownMove from "./town/move";
import TownRest from "./town/rest";
import FieldSelect from "./field/field";
import GuildInfo from "./guild/guild_info";
import WarRouter from "./guild/war/war_router";
import GuildCreate from "./guild/create";
import GuildJoin from "./guild/join";
import GuildManagement from "./guild/management";
import ManageMember from "./guild/manage/member";
import ManageApply from "./guild/manage/apply";
import ManageWar from "./guild/manage/war";
import ArenaBattle from "./battle/arena";
import PractiveBattle from "./battle/practice";
import JobClass from "./character/job_upgrade";
import TokenRefresh from "./tokenrefresh";
import {errorOccur, reLogin} from "../utils/toast";
import ChrDelete from "./character/delete";
import TownBank from "./town/bank";
import Footer from "./footer";
import Log from "./log";
import BattleLog from "./battle/battle_log";
import ManageInfo from "./guild/manage/info";
import TownConquer from "./town/TownConquer";
import TownShop from "./town/shop";
import ChrInvt from "./character/inven"
import EmailValidation from "./member/email_validation";
import ChatParent from "./chat/chatting_room";
import ManageTown from "./guild/manage/town";

import {getCharacter} from "../utils/api/character";
import {getTown} from "../utils/api/town";
import {getMyMemberData} from "../utils/api/guild";

const Body = () => {
    let history = useHistory()
    const { addToast } = useToasts()

    const expired = useSelector(state=>state.expired)
    const character = useSelector(state=>state.character).value
    const guild  = useSelector(state=>state.guild_info).value
    const dispatch = useDispatch()

    if (expired.value){
        dispatch({type:"EXPIRED", payload:false})
        localStorage.removeItem('token');
        dispatch({type:'CHARACTER', payload:'null'})
        reLogin(addToast)
        history.push('/login')
    }

    useLayoutEffect(()=>history.listen((location, action) => TokenRefresh(addToast, dispatch)))

    useEffect(() => {
        if(!!localStorage.getItem('token')){
            getCharacter().then(res=>{
                if(res.status===202){
                    dispatch({type:'CHARACTER', payload:res.data})
                    getTown().then(res=>dispatch({type:"TOWN", payload:res.data}))
                    if (res.data.guild!=="없음") getMyMemberData().then(res=>dispatch({type:"GUILD", payload:res.data[0]}))
                }
                else if(res.status===200) dispatch({type:'CHARACTER', payload:'none'})
            })
                .catch((error)=>{
                    if (error.response.status === 401) dispatch({type:"EXPIRED", payload:true})
                    else errorOccur(addToast)
                })
        }
    }, [dispatch, addToast]);
    return(<>
        <div style={window.innerWidth>1000 && character !== 'none' && character !== 'null' ?
            {float:"left", height:"100%", width:"80%", overflowY:"scroll"}:{float:"left", height:"100%", width:"100%"} }>
            <Route path="/">
                <div>
                    <Switch>
                        <Route path='/email/validation' component={sub}/>
                        <Route path="/login" component={sub}/>
                        <Route path="/signup" component={sub}/>
                        <Route path='/chr/select' component={sub}/>
                        <Route path='/chr/delete' component={sub}/>
                        <Route path='/chr/create' component={sub}/>
                        {character !== 'null' && <Route path='/chat' render={() => (<></>)}/>}
                        {character !== 'null' && <Route path='/' render={() => (<HeaderMain history={history}/>)}/>}
                    </Switch>
                </div>
            </Route>

            <Route exact path="/email/validation/:token" render={() => (<EmailValidation history={history}/>)}/>
            <Route exact path="/login" render={() => (<Login history={history}/>)}/>
            <Route exact path="/signup" render={() => (<SignUp history={history}/>)}/>
            {(character === 'null'&&!localStorage.getItem('token')) ? <Route exact path="/" render={() => (<Intro history={history}/>)}/>:<>
                <Route exact path="/log" render={() => (<Log history={history}/>)}/>
                <Route exact path="/log/battle" render={() => (<BattleLog/>)}/>
                <Route exact path="/chr/delete" render={() => (<ChrDelete history={history}/>)}/>
                <Route exact path="/chr/select" render={() => (<ChrSelect history={history}/>)}/>
                <Route exact path="/chr/create" render={() => (<ChrMake history={history} />)}/>
                {character === 'none' ? <></>:<>
                    <Route exact path="/" render={() => (<Main history={history}/>)}/>
                    <Route exact path="/chr/info" render={() => (<ChrInfo history={history}/>)}/>
                    <Route exact path="/chr/stat" render={() => (<FreeStat history={history}/>)}/>
                    <Route exact path="/chr/invt" render={() => (<ChrInvt history={history}/>)}/>
                    <Route path="/chat/" component={ChatParent}/>
                    {character.job_class < 3?
                        <Route exact path="/chr/job_class" render={() => (<JobClass history={history}/>)}/>:<></> }
                    <Route exact path="/skill/passive" render={() => (<PassiveSkill history={history} />)}/>
                    <Route exact path="/skill/active" render={() => (<ActiveSkill history={history} />)}/>
                    <Route exact path="/town/move" render={() => (<TownMove history={history}/>)}/>
                    <Route exact path="/town/rest" render={() => (<TownRest/>)}/>
                    <Route exact path="/town/bank" render={() => (<TownBank/>)}/>
                    <Route exact path="/town/conquer" render={() => (<TownConquer/>)}/>
                    <Route exact path="/town/shop" render={() => (<TownShop/>)}/>
                    <Route exact path="/field/" render={() => (<FieldSelect/>)}/>
                    <Route exact path="/arena/" render={() => (<ArenaBattle/>)}/>
                    <Route exact path="/practice/" render={() => (<PractiveBattle/>)}/>
                    {character.guild !== '없음' ?
                        <>
                            <Route exact path="/guild/info" render={() => (<GuildInfo history={history}/>)}/>
                            <Route exact path="/war" render={() => (<WarRouter history={history}/>)}/>
                            <Route exact path="/guild/manage/town" render={() => (<ManageTown history={history}/>)}/>
                            {guild.op > 0 ? <>
                                <Route exact path="/guild/manage" render={() => (<GuildManagement history={history}/>)}/>
                                <Route exact path="/guild/manage/war" render={() => (<ManageWar history={history}/>)}/>
                                <Route exact path="/guild/manage/member" render={() => (<ManageMember history={history}/>)}/>
                                <Route exact path="/guild/manage/apply" render={() => (<ManageApply history={history}/>)}/>
                                <Route exact path="/guild/manage/info" render={() => (<ManageInfo history={history}/>)}/>
                            </> : <></>
                            }
                        </>
                        :
                        <>
                            <Route exact path="/guild/join" render={()=> (<GuildJoin history={history} />)}/>
                            <Route exact path="/guild/create" render={()=> (<GuildCreate history={history} />)}/>
                        </>
                    }
                </>}
            </>}
            <Switch>
                {character !== 'none' && <Route path='/chat' render={() => (<></>)}/>}
                <>

                    <Footer/>
                </>
            </Switch>
        </div>
        {window.innerWidth>1000 && character !== 'none' && character !== 'null' ?
            <div className="border border-dark" style={{width: "20%", float:"left", height:"100%", position:"fixed", right:0, top:0}}>
                <ChatParent/>
            </div>
            :
            <></>}

    </>)
}

export default Body
