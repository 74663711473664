import React from "react";
import {useSelector} from "react-redux";

function HeaderMain(props) {
    const character = useSelector(state=>state.character).value
    const guild  = useSelector(state=>state.guild_info).value
    const handle_logout=()=>{
        localStorage.removeItem('token');
        window.location.href='/'
    }
    return (
        <nav className="navbar navbar-expand-md bg-light navbar-light">
            <div className="container">
                <div onClick={()=>props.history.push("/")}><img src="/logo.png" className="img-responsive" alt="Erion logo" style={{cursor: 'pointer'}} /></div>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                        data-target="#navbar2SupportedContent" aria-controls="navbar2SupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/></button>
                <div className="collapse navbar-collapse text-center justify-content-end" id="navbar2SupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <div id="c_btn-dropdown" className="btn-group pi-draggable">
                                <button className="btn dropdown-toggle text-dark btn-light"
                                        data-toggle="dropdown">
                                    <img src="/icon/character.png" alt="character_icon" style={{marginBottom:3}} width={20} height={20}/> 캐릭터&nbsp;
                                </button>
                                <div className="dropdown-menu">
                                    <div className="dropdown-item" onClick={()=>props.history.push("/chr/info/")}>캐릭터 정보</div>
                                    <div className="dropdown-item" onClick={()=>props.history.push("/chr/invt")}>캐릭터 인벤</div>
                                    <div className="dropdown-item" onClick={()=>props.history.push("/skill/passive")}>캐릭터 스킬</div>
                                    {character.job_class <3?
                                        <div className="dropdown-item" onClick={()=>props.history.push("/chr/job_class/")}>캐릭터 전직</div>:<></>}
                                    <div className="dropdown-divider"/>
                                    <div className="dropdown-item" onClick={()=>props.history.push("/chr/select")}>캐릭터 선택</div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div id="c_btn-dropdown" className="btn-group pi-draggable">
                                <button className="btn dropdown-toggle text-dark btn-light"
                                        data-toggle="dropdown">
                                    <img src="/icon/battle.png" alt="battle_icon" style={{marginBottom:3}} width={20} height={20}/> 전투&nbsp;
                                </button>
                                <div className="dropdown-menu">
                                    <div className="dropdown-item" onClick={()=>props.history.push("/field/")}>필드 전투</div>
                                    {/*<div className="dropdown-item" onClick={()=>props.history.push("/field/")}>레이드</div>*/}
                                    <div className="dropdown-divider"/>
                                    <div className="dropdown-item" onClick={()=>props.history.push("/arena")}>아레나</div>
                                    <div className="dropdown-divider"/>
                                    <div className="dropdown-item" onClick={()=>props.history.push("/practice")}>연습 전투</div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div id="c_btn-dropdown" className="btn-group pi-draggable">
                                <button className="btn dropdown-toggle text-dark btn-light"
                                        data-toggle="dropdown">
                                    <img src="/icon/town.png" alt="town_icon" style={{marginBottom:3}} width={20} height={20}/> 지역&nbsp;
                                </button>
                                <div className="dropdown-menu">
                                    <div className="dropdown-item" onClick={()=>props.history.push("/town/rest")}>여관</div>
                                    <div className="dropdown-item" onClick={()=>props.history.push("/town/bank")}>에리온 은행</div>
                                    {/*<div className="dropdown-item" onClick={()=>props.history.push("/trade")}>거래소</div>*/}
                                    <div className="dropdown-item" onClick={()=>props.history.push("/town/shop")}>상점</div>
                                    <div className="dropdown-divider"/>
                                    <div className="dropdown-item" onClick={()=>props.history.push("/town/move")}>지역 이동</div>
                                    <div className="dropdown-divider"/>
                                    <div className="dropdown-item" onClick={()=>props.history.push("/town/conquer")}>점령 현황</div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div id="c_btn-dropdown" className="btn-group pi-draggable">
                                <button className="btn dropdown-toggle text-dark btn-light"
                                        data-toggle="dropdown">
                                    <img src="/icon/guild.png" alt="guild_icon" style={{marginBottom:3}} width={20} height={20}/> 길드&nbsp;
                                </button>
                                <div className="dropdown-menu">
                                    {character.guild === '없음' ?
                                        <>
                                            <div className="dropdown-item" onClick={() => props.history.push("/guild/create")}>길드 생성</div>
                                            <div className="dropdown-divider"/>
                                            <div className="dropdown-item" onClick={() => props.history.push("/guild/join")}>길드 가입</div>
                                        </>
                                        :
                                        <>
                                            <div className="dropdown-item" onClick={() => props.history.push("/guild/info")}>길드 정보</div>
                                            <div className="dropdown-item" onClick={() => props.history.push("/war")}>전쟁</div>
                                            {guild.op > 0?<>
                                                    <div className="dropdown-divider"/>
                                                    <div className="dropdown-item" onClick={() => props.history.push("/guild/manage")}>길드 관리</div></>
                                                :<></>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div id="c_btn-dropdown" className="btn-group pi-draggable">
                                <button className="btn dropdown-toggle text-dark btn-light"
                                        data-toggle="dropdown">
                                    <img src="/icon/community.png" alt="community_icon" style={{marginBottom:3}} width={20} height={20}/> 커뮤니티&nbsp;
                                </button>
                                <div className="dropdown-menu">
                                    <div className="dropdown-item" onClick={()=>props.history.push("/")}>공지사항/이벤트</div>
                                    <div className="dropdown-item" onClick={()=>props.history.push("/")}>공략/팁게시판</div>
                                    <div className="dropdown-item" onClick={()=>props.history.push("/")}>유저게시판</div>
                                    <div className="dropdown-item" onClick={()=>props.history.push("/")}>에리온 위키</div>
                                    <div className="dropdown-divider"/>
                                    <div className="dropdown-item" onClick={()=>props.history.push("/")}>고객센터</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <button className="btn text-primary btn-light" onClick={()=>{handle_logout()}}>로그아웃</button>
                </div>
            </div>
        </nav>
    );
}


export default HeaderMain;