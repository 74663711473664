import React, {useEffect, useLayoutEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {jobCounter} from "../../utils/character";
import {errorOccur, guildOut, guildOutImpossible, guildDonated, notNumber, noMoney} from "../../utils/toast";
import {useToasts} from "react-toast-notifications";
import {getGuildInfo, getGuildMemberList, outGuild, donateGuild} from "../../utils/api/guild";
import {getCharacter} from "../../utils/api/character";

function GuildInfo(props) {
    const[guild, setGuild] = useState()
    const[member, setMember] = useState([])
    const dispatch = useDispatch()
    const { addToast } = useToasts()
    const money  = useSelector(state=>state.character).value.money
    const [donation, setDonation] = useState(0)

    function donate() {
        const el = document.querySelector('.inputReset')
        el.click()
        donateGuild(donation)
            .then(res => {
                guildDonated(addToast, donation.toLocaleString())
            })
            .catch(err => {
                if (err.response.status === 406) noMoney(addToast)
            })
        setDonation(0)
        window.jQuery("#donateCheck").modal("hide")
    }

    useLayoutEffect(() => {
        window.jQuery('[data-modal="guildout"]').click(function () {
            window.jQuery('#guildout').modal('show');
        });
    })
    useEffect(() => {
        getGuildInfo()
            .then(res => setGuild(res.data))
            .catch(error => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else errorOccur(addToast)
            })
    }, [dispatch, addToast]
    )
    useEffect(() => {
        getGuildMemberList()
            .then(res => setMember(res.data))
    }, [dispatch]
    )

    const handleSubmit = () => {
        outGuild()
            .then(()=>{
                getCharacter().then(res=>{dispatch({type:'CHARACTER', payload:res.data})})
                dispatch({type:"GUILD", payload:{guild__name:"없음", op:0}})
                guildOut(addToast)
                props.history.push("/")
                window.location.reload()
            })
            .catch(()=>guildOutImpossible(addToast))
    }

    if(guild==null || member.length===0)
        return(<>loading</>)
    else
        return(
            <div className="section">
                <div className="container">
                    <br/><br/><br/>
                    <h1>길드 정보</h1>
                    <div className="row" style={{marginTop:"20px"}}>
                        <div className="col-md-4" style={{ marginBottom: "20px" }}>
                            <img src="http://pingendo.github.io/pingendo-bootstrap/assets/placeholder.png" alt="guild_image"
                                 width="256" height="256" style={{marginLeft: 'auto', marginRight: 'auto', display: 'block'}}/>
                        </div>
                        <div className="col-md-8">
                            <div className="row" style={{ marginBottom: "10px" }}>
                                <h3 style={{ margin: '0 auto' }}>길드 기부</h3>
                            </div>
                            <div className="row">
                                <button
                                    className="btn btn-success btn-lg"
                                    style={{ margin: '0 auto' }}
                                    onClick={() => window.jQuery("#donate").modal("show")}
                                >
                                    기부
                                </button>
                            </div>
                            <br />
                            <table className="table table-bordered table" style={{tableLayout: 'fixed'}}>
                                <thead>
                                <tr>
                                    <th>길드명</th>
                                    <td>{ guild.name }</td>
                                    <th>길드 순위</th>
                                    <td>? 위</td>
                                </tr>
                                <tr>
                                    <th>길드 레벨</th>
                                    <td>{ guild.lev } 레벨</td>
                                    <th>길드 경험치</th>
                                    <td>{ guild.exp } / {Math.pow(guild.lev, 3) * 100000}</td>
                                </tr>
                                <tr>
                                    <th>길드 인원</th>
                                    <td>{ guild.mem } / { guild.maxmem }</td>
                                    <th>가입 방식</th>
                                    <td>{guild.join_type ? '승인' : '자유가입'}</td>
                                </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                    {/*
                    <div className="row" style={{marginTop:"3%"}}>
                        <h3>길드 건물 현황</h3><br/><br/>
                        <div className="col-md-18">
                            <table className="table table-bordered table" style={{tableLayout: 'fixed'}}>
                                <thead>
                                <tr>
                                    <th>전장의 메아리</th>
                                    <td>{ guild.wars }</td>
                                    <th>워프 게이트</th>
                                    <td>{ guild.gate }</td>
                                    <th>교역소</th>
                                    <td>{ guild.trade }</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th>훈련소</th>
                                    <td>{ guild.train }</td>
                                    <th>대장간</th>
                                    <td>{ guild.forge }</td>
                                    <th> </th>
                                    <td> </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    */}
                    <div className="row" style={{marginTop:"3%"}}>
                        <h3>길드원 현황</h3><br/><br/>
                        <div className="col-md-12">
                            <table className="table table-bordered" style={{tableLayout: 'fixed'}}>
                                <thead>
                                <tr>
                                    <th>직위</th>
                                    <th>이름</th>
                                    <th>직업</th>
                                    <th>레벨</th>
                                    <th>길드 기여도</th>
                                </tr>
                                </thead>
                                <tbody>
                                {member.map(mem => (
                                    <tr key={mem.character__name}>
                                        {(() => {
                                            if (mem.op === 2) {
                                                return (
                                                    <td>길드장</td>
                                                )
                                            } else if (mem.op === 1) {
                                                return (
                                                    <td>길드임원</td>
                                                )
                                            } else {
                                                return (
                                                    <td>길드원</td>
                                                )
                                            }
                                        })()}
                                        <td>{ mem.character__name }</td>
                                        <td>{ jobCounter(mem.character__job, mem.character__job_class) }</td>
                                        <td>{ mem.character__lev }</td>
                                        <td>{ mem.donate }</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row text-center" style={{marginTop:"3%"}}>
                        <h3 style={{marginLeft:"auto", marginRight:"auto"}}>길드 탈퇴</h3><br/><br/>
                        <div className="col-md-12">
                            <button className="btn btn-danger btn-lg" data-modal="guildout">탈퇴</button>
                        </div>
                    </div>
                    <div className="modal" id="guildout" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="guildout">길드 탈퇴</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>정말 길드를 탈퇴하시겠습니까?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-primary" onClick={()=>handleSubmit()}>확인</button>
                                    <button type="button" className="btn btn-outline-danger" data-dismiss="modal">취소</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal" id="donate" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">길드 기부</h5>
                                </div>
                                <div className="modal-body">
                                    <p>
                                        금액을 입력해주세요 (보유금액: {money ? money.toLocaleString(): null}원)<br />
                                        기부금액의 10%가 길드자금으로 전환됩니다
                                    </p>
                                    <div className="input-group mb-3">
                                        <form
                                            id="donateForm"
                                            style={{ width: '100%' }}
                                            onSubmit={(e) => {
                                                e.preventDefault()
                                                donate()
                                            }}
                                        >
                                            <input
                                                type="text"
                                                className="form-control"
                                                aria-label="gold amount"
                                                onChange={({ target: { value } }) => setDonation(Number(value))}
                                            />
                                            <input type="reset" className="inputReset" style={{ display: 'none' }}></input>
                                        </form>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        className="btn btn-outline-primary"
                                        onClick={() => {
                                            window.jQuery("#donate").modal("hide")
                                            if (donation) window.jQuery("#donateCheck").modal("show")
                                            else notNumber(addToast)
                                        }}
                                    >
                                        확인
                                    </button>
                                    <button className="btn btn-outline-danger" data-dismiss="modal">
                                        취소
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal" id="donateCheck" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">길드 기부</h5>
                                </div>
                                <div className="modal-body">
                                    <p>{donation ? donation.toLocaleString(): null}원을 기부하시겠습니까?</p>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="submit"
                                        form="donateForm"
                                        className="btn btn-outline-primary"
                                    >
                                        확인
                                    </button>
                                    <button className="btn btn-outline-danger" data-dismiss="modal">
                                        취소
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default GuildInfo;