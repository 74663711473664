import React from "react";
import {useSelector} from "react-redux";

function Sub() {
    const character = useSelector(state=>state.character).value
    const handle_logout=()=>{
        localStorage.removeItem('token');
        window.location.href='/'
    }
    return (
        <nav className="navbar navbar-expand-md bg-light navbar-light">
            <div className="container">
                <img src="/logo.png" className="img-responsive" alt="main logo"/>
                {!(character === 'null'&&!localStorage.getItem('token')) ?
                <button className="btn text-primary btn-light" onClick={()=>{handle_logout()}}>로그아웃</button>:
                <></>}
            </div>
        </nav>
    );

}

export default Sub;