import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getItemList, takeItem } from "../../utils/api/town";
import { getCharacter } from "../../utils/api/character";
import { useToasts } from "react-toast-notifications";
import { buySuccess, noStock, shortOfMoney, noChoose } from "../../utils/toast";
import {instrType, itemGrade} from '../../utils/item'
import {jobCounter} from "../../utils/character";
import { tablePaging, pageIndexing, instrFilter } from '../../utils/item';

export default function TownShop() {
  const town = useSelector(state=>state.town).value
  const money = useSelector(state=>state.character).value.money
  const [isLoading, setIsLoading] = useState(true)
  const [allItem, setAllItem] = useState()
  const [itemList, setItemList] = useState()
  const [choosedItem, setChoosedItem] = useState()
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const [hoverTable, setHoverTable] = useState(false)
  const tableSize = useState(10)[0]
  const [listNum, setListNum] = useState(0);
  const [pageIndex, setPageIndex] = useState()
  const [pageNum, setPageNum] = useState(0);
  const [isDESC, setIsDESC] = useState(true)
  function itemOrder() {
    takeItem(choosedItem.id)
      .then(res => {
        document.querySelectorAll('input[name="radio_item"]:checked')[0].checked = false
        setChoosedItem()
        buySuccess(addToast, choosedItem.name)
        // dispatch({ type: null, payload: null })
        getCharacter().then(res => dispatch({ type: "CHARACTER", payload: res.data }))
      })
      .catch(err => {
        if (err.response.status === 428) noStock(addToast);
        if (err.response.status === 402) shortOfMoney(addToast);
      })
  }

  useEffect(() => {
    getItemList().then((res) => {
      setAllItem(res.data)
      const pages = tablePaging(res.data, tableSize)
      setItemList(pages)
      setPageIndex(pageIndexing(pages.length))
      setIsLoading(false)
    });
  }, [tableSize]);

  useLayoutEffect(()=> {
    window.jQuery('[data-toggle="popover"]').popover({
      animation: true,
      container: "body",
      html: true,
      placement: "right",
      trigger: "hover"
    })
  })

  return isLoading ? (
      <div>...loading</div>
    ) : (
      <div className="container">
        <br />
        <h1>{town[0]["nameKorean"]} 상점</h1>
        <br />
        <div className="row">
          <div className="col-md-2" style={{ padding: 0 }}>
            <table
              className="table"
              style={{ tableLayout: 'fixed', textAlign: 'center' }}
            >
              <thead>
                <tr>
                  <th scope="col">카테고리</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>
                      <details style={{ textAlign: 'left'}}>
                      <summary
                        onClick={() => {
                          setPageNum(0)
                          setListNum(0)
                          const pages = tablePaging(allItem)
                          setItemList(pages)
                          setPageIndex(pageIndexing(pages.length))
                        }}
                      >
                        <b>전체</b>
                      </summary>
                      </details>
                      <div style={{ textAlign: 'left' }}>
                        <details style={{ paddingLeft: '10px' }}>
                        <summary
                          onClick={() => {
                            setPageNum(0)
                            setListNum(0)
                            const pages = tablePaging(instrFilter(allItem, 'weapon', 'weapon'), tableSize)
                            setItemList(pages)
                            setPageIndex(pageIndexing(pages.length))
                          }}
                        >
                          <b>무기</b>
                        </summary>
                          <div style={{ paddingLeft: '20px', cursor: 'pointer' }}>
                            {[
                              ["투술", "양날도끼"],
                              ["검술", "검"],
                              ["암술", "단검"],
                              ["궁술", "활"],
                              ["마술", "지팡이"],
                              ["주술", "낫"],
                            ].map((grade, index) => (
                              <p
                                key={index}
                                className="d-inline"
                                onClick={() => {
                                  setPageNum(0)
                                  setListNum(0)
                                  const pages = tablePaging(instrFilter(allItem, 'weapon', grade[0]), tableSize)
                                  setItemList(pages)
                                  setPageIndex(pageIndexing(pages.length))
                                }}
                              >
                                {grade[1]}
                                <br />
                              </p>
                            ))}
                          </div>
                        </details>
                        <details style={{ paddingLeft: '10px' }}>
                        <summary
                          onClick={() => {
                            setPageNum(0)
                            setListNum(0)
                            const pages = tablePaging(instrFilter(allItem, 'sub_weapon', 'sub_weapon'), tableSize)
                            setItemList(pages)
                            setPageIndex(pageIndexing(pages.length))
                          }}
                        >
                          <b>보조무기</b>
                        </summary>
                          <div style={{ paddingLeft: '20px', cursor: 'pointer' }}>
                          {[
                              ["투술", "건틀렛"],
                              ["검술", "방패"],
                              ["암술", "수리검"],
                              ["궁술", "화살통"],
                              ["마술", "마도서"],
                              ["주술", "정령석"],
                            ].map((grade, index) => (
                              <p
                                key={index}
                                className="d-inline"
                                onClick={() => {
                                  setPageNum(0)
                                  setListNum(0)
                                  const pages = tablePaging(instrFilter(allItem, 'sub_weapon', grade[0]), tableSize)
                                  setItemList(pages)
                                  setPageIndex(pageIndexing(pages.length))
                                }}
                              >
                                {grade[1]}
                                <br />
                              </p>
                            ))}
                          </div>
                        </details>
                        <details style={{ paddingLeft: '10px' }}>
                        <summary
                          onClick={() => {
                            setPageNum(0)
                            setListNum(0)
                            const pages = tablePaging(instrFilter(allItem, 'armor'), tableSize)
                            setItemList(pages)
                            setPageIndex(pageIndexing(pages.length))
                          }}
                        >
                          <b>갑옷</b>
                        </summary>
                        </details>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="col-md-10"
            style={{ marginRight:"auto", marginLeft:"auto", padding: 0 }}
          >
            <table
              className="table"
              style={{ tableLayout: 'fixed', textAlign: 'center' }}
            >
              <thead>
                <tr>
                  <th scope="col" width="5%">{/*radioButton*/}</th>
                  <th scope="col">이름</th>
                  <th
                    scope="col"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setIsDESC(!isDESC);
                      for (let i = 0; i < itemList.length; i++) {
                        itemList[i].sort(function (a, b) {
                          if (isDESC) {
                            return b.level - a.level;
                          }
                          return a.level - b.level;
                        });
                      }
                      setItemList(itemList.slice());
                    }}
                  >
                    레벨 {isDESC ? "▲" : "▼"}
                  </th>
                  <th scope="col">가격</th>
                </tr>
              </thead>
              <tbody>
                {itemList[listNum] ? itemList[listNum].map((item, index) => (
                  <tr
                    key={index}
                    onMouseEnter={() => setHoverTable(index)}
                    onMouseLeave={() => setHoverTable(null)}
                    style={hoverTable === index
                      ? { backgroundColor: 'rgb(245, 245, 245)' }
                      : null}
                  >
                    <td>
                      <input
                        type="radio"
                        name="radio_item"
                        value={index}
                        onClick={()=> {
                          const itemIndex = document.querySelectorAll('input[name="radio_item"]:checked')[0].value
                          setChoosedItem(itemList[listNum][itemIndex])
                        }}
                      />
                    </td>
                    <td
                      style={{ textAlign: 'left' }}
                      data-toggle="popover"
                      data-content={`
                        직업: ${jobCounter(item.job, 1)}<br />
                        등급: ${itemGrade(item.grade)}<br />
                        부위: ${instrType(item.instr_type, item.job)}<br />
                        ${item.attack !== undefined && item.attack !== 0 ? `공격력: ${item.attack}<br />` : ''}
                        ${item.magic !== undefined && item.magic !== 0 ? `마력: ${item.magic}<br />` : ''}
                        ${item.defend !== undefined && item.defend !== 0 ? `방어력: ${item.defend}<br />` : ''}
                        ${item.spirit !== undefined && item.spirit !== 0 ? `마법저항력: ${item.spirit}<br />` : ''}
                        ${item.weight !== undefined && item.weight !== 0 ? `무게: ${item.weight}<br />` : ''}
                      `}
                    >
                      {item.name}
                    </td>
                    <td>{item.level}</td>
                    <td>{item.cost.toLocaleString()}</td>
                  </tr>
                )) : null}
                {itemList[listNum] ? [...Array(tableSize - itemList[listNum].length)].map((i, index) => (
                  <tr
                    key={index}
                    onMouseEnter={() => setHoverTable(index + itemList[listNum].length)}
                    onMouseLeave={() => setHoverTable(null)}
                    style={hoverTable === index + itemList[listNum].length
                      ? { backgroundColor: 'rgb(245, 245, 245)' }
                      : null}
                  >
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )) :
                [...Array(tableSize)].map((i, index) => (
                  <tr
                    key={index}
                    onMouseEnter={() => setHoverTable(index)}
                    onMouseLeave={() => setHoverTable(null)}
                    style={hoverTable === index
                      ? { backgroundColor: 'rgb(245, 245, 245)' }
                      : null}
                  >
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="row">
              <nav style={{ margin: '0 auto' }}>
                <ul className="pagination pagination-sm">
                  {pageIndex[pageNum - 1] ? (
                    <li className="page-item">
                      <a
                        className="page-link"
                        href="##"
                        onClick={(e) => {
                          e.preventDefault()
                          setPageNum(pageNum - 1)
                        }}
                      >
                        Previous
                      </a>
                    </li>
                  ) : null}
                  {pageIndex[pageNum] ? pageIndex[pageNum].map((i, index) => (
                    <li className="page-item" key={index}>
                      <a
                        className="page-link"
                        href="##"
                        onClick={(e) => {
                          e.preventDefault()
                          setListNum(index)
                          if(choosedItem) {
                            document.querySelectorAll('input[name="radio_item"]:checked')[0].checked = false
                            setChoosedItem()
                          }
                        }}
                      >
                        {index + 1}
                      </a>
                    </li>
                  )) : (
                    <li className="page-item">
                      <a className="page-link" href="##">1</a>
                    </li>
                  )}
                  {pageIndex[pageNum + 1] ? (
                    <li className="page-item">
                      <a
                        className="page-link"
                        href="##"
                        onClick={(e) => {
                          e.preventDefault()
                          setPageNum(pageNum + 1)
                        }}
                      >
                        Next
                      </a>
                    </li>
                  ) : null}
                </ul>
              </nav>
            </div>
            <div>
              <h5 style={{ float: 'left' }}>가진 금액: {money.toLocaleString()}원</h5>
              <button
                className="btn btn-light"
                style={{ float: 'right' }}
                onClick={() => {
                  if(choosedItem) return window.jQuery("#buyItem").modal("show");
                  return noChoose(addToast)
                }}
                >
                산다
              </button>
            </div>
          </div>
        </div>
        <br />

        <div className="modal" id="buyItem" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">구매하기</h5>
              </div>
              <div className="modal-body">
                <p>{choosedItem ? choosedItem.name : null}(을)를 구매하겠습니까?</p>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-outline-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    itemOrder()
                  }}
                >
                  확인
                </button>
                <button className="btn btn-outline-danger" data-dismiss="modal">
                  취소
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  ;
} 