function errorOccur(toast) {
    toast('오류가 발생하였습니다', {
        appearance: 'error',
        autoDismiss: true,
    })
}
function reLogin(toast) {
    toast('다시 로그인 해주세요', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function actionTimeLeft(toast, time) {
    toast('행동 대기시간이 '+String(parseInt(time+1))+'초 남았습니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function noAccountExist(toast) {
    toast('아이디 혹은 비밀번호가 틀렸습니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function emptyID(toast) {
    toast('아이디가 비었습니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function emptyPassword(toast) {
    toast('비밀번호가 비었습니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function inactiveID(toast) {
    toast('이메일 인증이 필요합니다. 이메일을 확인해주세요', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function alreadyNameExist(toast) {
    toast('이미 사용 중인 이름입니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function alreadyIDExist(toast) {
    toast('이미 사용 중인 아이디입니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function signUpSuccess(toast) {
    toast('회원가입이 완료되었습니다. 이메일 확인해주세요.', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function alreadyEmailExist(toast) {
    toast('이미 사용 중인 이메일입니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function passNotConfirm(toast) {
    toast('비밀번호가 같지 않습니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function invalidID(toast) {
    toast('사용 불가능한 아이디입니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function invalidPassword(toast) {
    toast('불가능한 아이디입니다(8자 이상, 숫자만 있으면 안됨, 흔한 비번 안됨,  사용자 정보와 비슷하면 안됨)', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function characterCreated(toast) {
    toast('캐릭터가 생성되었습니다.', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function nameTooLong(toast) {
    toast('이름 길이가 올바르지 않습니다 (1~8자)', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function freeStatReset(toast) {
    toast('자유스탯이 초기화되었습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function freeStatAdapt(toast) {
    toast('자유스탯이 적용되었습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function restAdapt(toast) {
    toast('회복되었습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function passiveAdapt(toast) {
    toast('패시브 스킬이 등록되었습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function passiveReset(toast) {
    toast('패시브 스킬이 초기화되었습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function activeAdapt(toast) {
    toast('액티브 스킬이 등록되었습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function activeReset(toast) {
    toast('액티브 스킬이 초기화되었습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function guildWarAttackNotAvailable(toast) {
    toast('공격신청이 가능한 도시가 없습니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function guildWarAttackNotAvailableCity(toast) {
    toast('공격이 불가능한 도시입니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function guildWarDefendNotAvailable(toast) {
    toast('현재 위치는 길드의 영토가 아닙니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function guildWarSubmitParticipation(toast, type, city) {
    if (type === 'attack') {
        toast(city+' 공격신청이 등록되었습니다', {
            appearance: 'success',
            autoDismiss: true,
        })
    }
    else if (type === 'defend') {
        toast(city+' 수비신청이 등록되었습니다', {
            appearance: 'success',
            autoDismiss: true,
        })
    }
}
function guildWarDeleteParticipation(toast) {
    toast('전쟁 신청이 취소되었습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function guildOpLimit(toast) {
    toast('길드임원은 최대 3명까지입니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function guildCreated(toast) {
    toast('길드 생성이 완료되었습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function guildRemove(toast) {
    toast('길드 삭제가 완료되었습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function guildRemoveImpossible(toast) {
    toast('인원이 2명이상이면 삭제할 수 없습니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function guildOut(toast) {
    toast('길드에서 탈퇴하였습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function guildOutImpossible(toast) {
    toast('일반 길드원만 탈퇴할 수 있습니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function guildJoinTypeChanged(toast, join_type) {
    if (join_type === false) {
        toast("길드 가입 방식이 '자유 가입'으로 변경되었습니다", {
            appearance: 'success',
            autoDismiss: true,
        })
    }
    else if (join_type === true) {
        toast("길드 가입 방식이 '승인 가입'으로 변경되었습니다", {
            appearance: 'success',
            autoDismiss: true,
        })
    }
}
function guildJoinTypeIsSame(toast, join_type) {
    if (join_type === false) {
        toast("이미 길드 가입 방식이 '자유 가입'입니다", {
            appearance: 'warning',
            autoDismiss: true,
        })
    }
    else if (join_type === true) {
        toast("이미 길드 가입 방식이 '승인 가입'입니다", {
            appearance: 'warning',
            autoDismiss: true,
        })
    }
}
function guildIntroChanged(toast) {
    toast('길드 설명이 변경되었습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function guildIntroIsSame(toast) {
    toast('길드 설명이 이전과 동일하여 변경되지 않습니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function impossibleName(toast) {
    toast('사용할 수 없는 이름입니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function noHP(toast) {
    toast('체력이 없습니다. 여관에서 회복하세요', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function moneyInBank(toast) {
    toast('입금되었습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function moneyOutBank(toast) {
    toast('출금되었습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function noMoney(toast) {
    toast('금액이 부족합니다', {
        appearance: 'error',
        autoDismiss: true,
    })
}
function joinGuild(toast, name) {
    toast(name+' 길드에 가입되었습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function applyGuild(toast, name) {
    toast(name+' 길드에 지원하셨습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function cancelGuildApply(toast, name) {
    toast(name+' 길드의 가입신청을 취소하셨습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function masterCanNotDeleteCharacter(toast) {
    toast('길드장은 삭제할 수 없습니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function jobChanged(toast, name) {
    toast(String(name)+'(으)로 전직하셨습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function jobUpgraded(toast, job, job_class) {
    toast(String(job_class)+'차 '+String(job)+'(으)로 전직하셨습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function jobLevelLow(toast) {
    toast('현재 레벨이 낮습니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function townMoved(toast, town) {
    toast(String(town)+'(으)로 이동하였습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function notAnyMore(toast) {
    toast('더 이상 신청하실 수 없습니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}

function notWarTime(toast) {
    toast('전쟁 시간이 아닙니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function noWarLife(toast) {
    toast('목숨을 모두 소지하였습니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function earlyToAttack(toast) {
    toast('아직 진군 도착 시간이 아닙니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function alreadyDefend(toast) {
    toast('이미 수비를 섰습니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function guildMasterPassed(toast) {
    toast('길드장을 위임하였습니다. 수고하셨습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function guildOpDown(toast) {
    toast('임원을 강등하였습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function guildOpUP(toast) {
    toast('임원으로 승급하였습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function slotReset(toast) {
    toast('슬롯이 초기화되었습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function townConquered(toast, town) {
    toast(town+'을(를) 점령하였습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}
function warAttackLost(toast, life) {
    toast('전투 중 사망하셨습니다\n남은 목숨: '+life, {
        appearance: 'error',
        autoDismiss: true,
    })
}
function warClameLessHour(toast) {
    toast('해당 시간까지 한 시간도 남지 않았습니다', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function buySuccess(toast, itemName) {
    toast(`${itemName}(을)를 구매했습니다.`, {
        appearance: 'success',
        autoDismiss: true,
    }) 
}
function noStock(toast) {
    toast('아이템의 재고가 없습니다.', {
        appearance: 'error',
        autoDismiss: true,
    }) 
}
function shortOfMoney(toast) {
    toast('금액이 부족합니다.', {
        appearance: 'error',
        autoDismiss: true,
    }) 
}
function noChoose(toast) {
    toast('아이템을 선택해주세요.', {
        appearance: 'error',
        autoDismiss: true,
    }) 
}
function equipSuccess(toast, itemName) {
    toast(`${itemName}(을)를 장착했습니다.`, {
        appearance: 'success',
        autoDismiss: true,
    }) 
}
function notFoundItem(toast) {
    toast('장비할 아이템을 찾을 수 없습니다.', {
        appearance: 'error',
        autoDismiss: true,
    }) 
}
function differntJob(toast) {
    toast('착용할 수 없는 직업입니다.', {
        appearance: 'error',
        autoDismiss: true,
    }) 
}
function shortOfLevel(toast) {
    toast('레벨이 부족합니다.', {
        appearance: 'error',
        autoDismiss: true,
    }) 
}
function dumpSuccess(toast, itemName) {
    toast(`${itemName}(을)를 버렸습니다.`, {
        appearance: 'success',
        autoDismiss: true,
    }) 
}
function notMine(toast) {
    toast('아이템의 소유권이 없습니다.', {
        appearance: 'error',
        autoDismiss: true,
    }) 
}
function wearItem(toast) {
    toast('착용중인 아이템입니다.', {
        appearance: 'error',
        autoDismiss: true,
    }) 
}
function arenaNoMatch(toast) {
    toast('매칭할 상대가 없습니다.', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function arenaNoCoin(toast) {
    toast('입장권을 모두 소진하였습니다.', {
        appearance: 'warning',
        autoDismiss: true,
    })
}
function warDefend(toast) {
    toast('수비를 시작합니다.', {
        appearance: 'success',
        autoDismiss: true,
    }) 
}
function guildDonated(toast, money) {
    toast(`${money}원을 기부했습니다.`, {
        appearance: 'success',
        autoDismiss: true,
    })
}
function notNumber(toast) {
    toast('금액을 바르게 입력해주세요.', {
        appearance: 'error',
        autoDismiss: true,
    })
}
export function guardStatAdapt(toast) {
    toast('근위대의 스탯이 적용되었습니다', {
        appearance: 'success',
        autoDismiss: true,
    })
}

export {actionTimeLeft, noAccountExist, alreadyNameExist, alreadyIDExist, signUpSuccess, characterCreated, nameTooLong,
    freeStatReset, freeStatAdapt, restAdapt, passiveAdapt, passiveReset, activeAdapt, activeReset, guildOpLimit,
    reLogin, noHP, guildCreated, impossibleName, guildWarAttackNotAvailable, guildWarDefendNotAvailable,
    guildWarSubmitParticipation, guildWarDeleteParticipation, moneyInBank, moneyOutBank, noMoney, applyGuild, joinGuild,
    masterCanNotDeleteCharacter, jobChanged, jobUpgraded, jobLevelLow, errorOccur, townMoved, notAnyMore, notWarTime,
    noWarLife, earlyToAttack, alreadyDefend, cancelGuildApply, guildRemove, guildOut, guildOutImpossible, guildRemoveImpossible,
    guildWarAttackNotAvailableCity, guildMasterPassed, guildOpDown, guildOpUP, slotReset, townConquered, warAttackLost,
    warClameLessHour, guildJoinTypeChanged, guildJoinTypeIsSame, guildIntroChanged, guildIntroIsSame, alreadyEmailExist,
    invalidID, invalidPassword, passNotConfirm, emptyID, emptyPassword, inactiveID, buySuccess, noStock, shortOfMoney,
    noChoose, equipSuccess, notFoundItem, differntJob, shortOfLevel, dumpSuccess, notMine, wearItem, arenaNoMatch, arenaNoCoin,
    warDefend, guildDonated, notNumber
}