import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
    errorOccur,
    guildWarAttackNotAvailable,
    guildWarDefendNotAvailable,
    guildWarDeleteParticipation,
    guildWarSubmitParticipation
} from "../../../utils/toast"
import { jobCounter } from "../../../utils/character";
import { getTown, getConquerInfo } from "../../../utils/api/town";
import {getCharacter} from "../../../utils/api/character";
import { cancelParticipation, getClameList, participateWar, getRelatedParticipation } from "../../../utils/api/war";

function WarParticipation() {
    const town = useSelector(state => state.town).value
    const [war_clame_list, setWarClame] = useState([])
    const [list, setList] = useState([])
    const dispatch = useDispatch()
    const { addToast } = useToasts()
    const townPosition = useSelector((state) => state.town_position).value;
    const [conquerInfo, setConquerInfo] = useState(false);

    useLayoutEffect(() => {
        window.jQuery('[data-modal="attackCity"]').click(function () {
            if (war_clame_list.length) {
                window.jQuery('#attackCity').modal('show');
            }
        });
    })
  
    useEffect(() => {
        getConquerInfo().then((res) => {
            getClameList()
                .then(clameRes => {
                    setWarClame(clameRes.data)
                    for (let i = 0; i < res.data.length; i++) {
                        clameRes.data.forEach(attackCity => {
                            if (res.data[i].name === attackCity.attack_to) {
                                res.data[i].time = attackCity.time
                            }
                        })
                    };
                    setConquerInfo(res.data)
                })
                .catch(error => {
                    if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                    else errorOccur(addToast)
                })
        });

        getRelatedParticipation()
            .then(res => setList(res.data))
            .catch(error => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else errorOccur(addToast)
            })
    }, [dispatch, addToast, town])

    const handleParticipation = (action_type, attack_to_name, attack_to_id) => {
        participateWar(action_type, attack_to_id)
            .then(() => {
                getCharacter().then(res => {
                    dispatch({ type: "CHARACTER", payload: res.data })
                    getTown().then(response=>dispatch({type:"TOWN", payload: response.data }))})
                setList([])
                if (action_type === 'attack') {
                    guildWarSubmitParticipation(addToast, 'attack', attack_to_name)
                }
                else if (action_type === 'defend') {
                    guildWarSubmitParticipation(addToast, 'defend', town[0]["nameKorean"])
                }
            })
            .catch((error) => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else if (action_type === 'defend' && (error.response.status === 423 || 500)) {
                    guildWarDefendNotAvailable(addToast)
                }
            })
    }
    const deleteParticipation = () => {
        cancelParticipation()
            .then(() => {
                getCharacter().then(res => {
                    dispatch({ type: "CHARACTER", payload: res.data })
                    getTown().then(response=>dispatch({type:"TOWN", payload: response.data }))})
                setList([])
                guildWarDeleteParticipation(addToast)
            }
            )
    }

    return (
        <div className="section">
            <div className='container'>
                <h1 style={{ marginTop: '30px', textAlign: "center" }}>전쟁</h1>
                <h4 style={{ marginBottom: "30px", textAlign: "center" }}>현재 마을: {town[0]["nameKorean"]}</h4>

                <div className="row">
                    <div className="col-md-6" style={{ textAlign: "center", paddingLeft: "30px", paddingRight: "30px", marginBottom:'30px' }}>
                        <h2 style={{ marginBottom: '50px' }}>전쟁 신청</h2>
                        <div className="col-md" style={{ width: '50%', minWidth: '300px', marginLeft: "auto", marginRight: "auto", marginBottom: "10%" }}>
                            <button data-modal="attackCity" style={{ padding: "0" }} onClick={() => { if (!war_clame_list.length) guildWarAttackNotAvailable(addToast) }}>
                                <img src="/placeholder.png" className="img-responsive" style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                                    alt="par_attack" />
                                <div className="carousel-caption text-dark">
                                    <h4>공격</h4>
                                </div>
                            </button>
                        </div>
                        <div className="col-md" style={{ width: '50%', minWidth: '300px', marginLeft: "auto", marginRight: "auto", marginBottom: "5%" }}>
                            <button style={{ padding: "0" }} onClick={() => handleParticipation("defend")}>
                                <img src="/placeholder.png" className="img-responsive" style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                                    alt="par_defend" />
                                <div className="carousel-caption text-dark">
                                    <h4>수비</h4>
                                </div>
                            </button>
                        </div>

                        <button className='btn-lg btn-danger' onClick={() => deleteParticipation()}>신청 취소</button>

                    </div>

                    <div className="col-md-6" style={{ textAlign: "center", paddingLeft: "30px", paddingRight: "30px" }}>
                        <h2>신청 상황</h2>
                        {list.length > 0 ?
                            <h5 style={{ marginBottom: '20px' }}>- {list[0].station} {list[0].action_type === 'attack' ?
                                <>공격</>
                                : <>수비</>} 신청 -</h5> :
                            <h5 style={{ marginBottom: '20px' }}>- 지원한 신청이 없습니다. -</h5>
                        }
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th width="20%">레벨</th>
                                    <th width="40%">직업</th>
                                    <th width="40%">이름</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map((charac, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{charac.character__lev}</td>
                                            <td>{jobCounter(charac.character__job, charac.character__job_class)}</td>
                                            <td>{charac.character__name}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>

            <div className="modal" id="attackCity" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">도시 공격</h5>
                        </div>
                        <div className="modal-body" style={{ padding: 0 }}>
                            <div>
                                <div style={{ position: 'absolute' }}>
                                    <img
                                        alt="map"
                                        src="/map.png"
                                        className="img-responsive"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    />
                                </div>
                                {townPosition.map((floor, id) => (
                                    <div key={id}>
                                        {floor.map((room, id) => (
                                            <span
                                                key={id}
                                                style={{
                                                    position: "relative",
                                                    float: "left",
                                                    border: "1px solid tranparent",
                                                    width: "11.11%",
                                                    paddingBottom: "11.11%",
                                                }}
                                            >
                                                {conquerInfo[room] && conquerInfo[room].time ?
                                                    <span
                                                        style={{
                                                            cursor: 'pointer',
                                                            position: 'absolute',
                                                            left: '10%',
                                                            top: '10%',
                                                            border: "5px solid black",
                                                            width: "80%",
                                                            height: '80%',
                                                        }}
                                                        onClick={() => {
                                                            handleParticipation("attack", conquerInfo[room].name, room)
                                                            return window.jQuery("#attackCity").modal("hide");
                                                        }}
                                                    >
                                                    </span> : null}
                                                    {conquerInfo[room] && conquerInfo[room].time ?
                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                bottom: "0%",
                                                                left: "50%",
                                                                transform: "translateX(-50%)",
                                                                border: "1px solid gray",
                                                                backgroundColor: "white",
                                                                fontSize: '12px',
                                                                fontWeight: 'bold'
                                                            }}
                                                            onClick={() => {
                                                                handleParticipation("attack", conquerInfo[room].name, room)
                                                                return window.jQuery("#attackCity").modal("hide");
                                                            }}
                                                        >
                                                            {conquerInfo[room].time.slice(0,5)}
                                                        </span> : null}
                                            </span>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WarParticipation