import React, {useEffect, useLayoutEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useToasts} from "react-toast-notifications";
import {errorOccur, passiveAdapt, passiveReset} from "../../utils/toast";
import {getPassiveSkillPoints, getActiveSkillPoints} from "../../utils/api/skill";
import { auto_center, skill_icon, column_style, button, getSum, passive_skill_list } from "../../utils/skill";
import axios from "axios";
import { api_url } from "../../utils/api/basic";
import {getCharacter} from "../../utils/api/character";

function PassiveSkill(props) {
    const character = useSelector(state=>state.character).value
    const [initial, setInitial] = useState()
    const [adapt, setAdapt] = useState()
    const [newPoint, setNewPoint] = useState()
    const [curPoint, setCurPoint] = useState()
    const { addToast } = useToasts()
    const dispatch = useDispatch()

    function skill_point(job_class, skill_number, calculation) {
        const new_array = adapt
        if (calculation === "plus") {
            if (curPoint >= 1 && (initial[job_class][skill_number] + adapt[job_class][skill_number]) < 5) {
                new_array[job_class][skill_number] += 1
                setAdapt(new_array)
                setCurPoint(curPoint-1)
            }
        }
        else {
            if (((character.lev - curPoint) >= 1) && (adapt[job_class][skill_number] >= 1)) {
                new_array[job_class][skill_number] -= 1
                setAdapt(new_array)
                setCurPoint(curPoint+1)
            }
        }
    }

    function resetSlot(slot) {
        for (let i = 0; i < slot.length; i++) {
            for (let j = 0; j < slot[i].length; j++) {
                slot[i][j] = 0
            }
        }
        return slot
    }

    const handleSubmit = function() {
        let body = {};
        for (let jobClass = 0; jobClass <= character.job_class; jobClass++) {
            for (let index = 0; index < initial[jobClass].length; index++) {
                body[`class${jobClass}_${index + 1}`] = initial[jobClass][index] + adapt[jobClass][index]
            }
        }
        axios
            .post(
                api_url+"/api/v1/passive_skill/",
                body,
                {
                    headers: {
                        Authorization: `jwt ${localStorage.getItem('token')}`
                    }
                }
            )
            .then(() => {
                getCharacter().then(res => dispatch({ type: "CHARACTER", payload: res.data }))
                let newInitial = initial.slice()
                for (let i = 0; i < newInitial.length; i++) {
                    for (let j = 0; j < newInitial[i].length; j++) {
                        newInitial[i][j] = (newInitial[i][j] + adapt[i][j])
                    }
                }
                setInitial(newInitial)
                let newAdapt = resetSlot(adapt)
                setAdapt(newAdapt)
                passiveAdapt(addToast)
            })
    };

    const handleReset = () => {
        axios
            .put(
                api_url+"/api/v1/passive_skill/",
                {},
                { 
                    headers: {
                        Authorization: `jwt ${localStorage.getItem('token')}`
                    }
                }
            )
            .then(()=>{
                getCharacter().then(res => dispatch({ type: "CHARACTER", payload: res.data }))
                let newInitial = resetSlot(initial)
                setInitial(newInitial)
                let newAdapt = resetSlot(adapt)
                setAdapt(newAdapt)
                setCurPoint(newPoint)
                passiveReset(addToast)
            })
    };

    useLayoutEffect(()=> {
        window.jQuery('[data-toggle="popover"]').popover({
            animation: true,
            container: "body",
            html: true,
            placement: "left",
            trigger: "focus"
        })
    })

    useEffect(() => {
        if (character !== 'null' && !!localStorage.getItem('token')) {
            getActiveSkillPoints()
                .then((first_res) => {
                    setNewPoint(character.lev - getSum(first_res.data))
                    
                    if(character.lev - getSum(first_res.data) !== null) {
                        getPassiveSkillPoints()
                            .then(res => {
                                setCurPoint(character.lev - getSum(first_res.data) - getSum(res.data))
                                if (character.job === "없음") {
                                    setInitial([[res.data.class0_1, res.data.class0_2, res.data.class0_3, res.data.class0_4]])
                                    setAdapt([[0, 0, 0, 0]])
                                }
                                else if (character.job === "투술" || character.job === "검술" || character.job === "궁술"
                                    || character.job === "암술" || character.job === "마술" || character.job === "주술"){
                                    setInitial([
                                        [res.data.class0_1, res.data.class0_2 ,res.data.class0_3, res.data.class0_4],
                                        [res.data.class1_1, res.data.class1_2],
                                        [res.data.class2_1, res.data.class2_2],
                                        [res.data.class3_1, res.data.class3_2, res.data.class3_3],
                                    ])
                                    setAdapt([[0, 0, 0, 0], [0, 0], [0, 0], [0, 0, 0]])
                                }
                        })
                    }
                })
                .catch(error=>{
                    if(error.response.status===401) dispatch({type:'EXPIRED', payload:true})
                    else errorOccur(addToast)
                })
        }
    }, [character, addToast, dispatch])
    
    return (
        <div className="section">
            <div className="container">
                <div className="row" style={{marginLeft:"auto", marginRight:"auto", width:"300px"}}>
                    <div className="col" >
                        <button className="btn btn-primary" style={{float:"left"}}>패시브 스킬</button>
                        <button className="btn btn-outline-warning" style={{float:"right"}}
                                onClick={()=>props.history.push('/skill/active')}>액티브 스킬</button>
                    </div>
                </div>

                <div style={{textAlign:"center", marginTop:"2%"}}>
                    <h5>남은 스킬포인트: {curPoint}</h5>
                </div>
                {initial && adapt ? <div className="row">
                    <div className="col-md-auto" style={{ marginLeft:"auto", marginRight:"auto" }}>
                        <div className="col-md-auto" style={column_style}>
                            <div>기본</div>
                            <br/>
                            {passive_skill_list['공용'].class0.map((skill, index) => (
                                <div key={index}>
                                    <div>
                                        <div
                                            className="border"
                                            data-toggle="popover"
                                            tabIndex="0"
                                            title={skill.name}
                                            data-content={skill.value.replace(/#(\d+)/,
                                                Number(skill.value.match(/#(\d+)/, '$1')[1]) * (initial[0][index] + adapt[0][index])
                                            )}
                                            style={skill_icon}
                                        >
                                            <img
                                                alt={skill.imgAlt}
                                                src={skill.imgSrc}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                        <button
                                            className="btn btn-light"
                                            style={button}
                                            onClick={() => skill_point(0, index, "minus")}
                                        >
                                            -
                                        </button>
                                        {` ${initial[0][index] + adapt[0][index]} `}
                                        <button
                                            className="btn btn-light"
                                            style={button}
                                            onClick={() => skill_point(0, index, "plus")}>
                                                +
                                        </button>
                                    </div>
                                    <br />
                                </div>
                            ))}
                        </div>
                        {character.job_class >= 1 ? <div className="col-md-auto" style={column_style}>
                            <div>1차</div>
                            <br/>
                            {passive_skill_list[character.job].class1.map((skill, index) => (
                                <div key={index}>
                                    <div>
                                        <div
                                            className="border"
                                            data-toggle="popover"
                                            tabIndex="0"
                                            title={skill.name}
                                            data-content={skill.value.replace(/#(\d+)/,
                                                Number(skill.value.match(/#(\d+)/, '$1')[1]) * (initial[1][index] + adapt[1][index])
                                            )}
                                            style={skill_icon}
                                        >
                                            <img
                                                alt={skill.imgAlt}
                                                src={skill.imgSrc}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                        <button
                                            className="btn btn-light"
                                            style={button}
                                            onClick={() => skill_point(1, index, "minus")}
                                        >
                                            -
                                        </button>
                                        {` ${initial[1][index] + adapt[1][index]} `}
                                        <button
                                            className="btn btn-light"
                                            style={button}
                                            onClick={() => skill_point(1, index, "plus")}>
                                                +
                                        </button>
                                    </div>
                                    <br />
                                </div>
                            ))}
                        </div> : null}
                        {character.job_class >= 2 ? <div className="col-md-auto" style={column_style}>
                            <div>2차</div>
                            <br/>
                            {passive_skill_list[character.job].class2.map((skill, index) => (
                                <div key={index}>
                                    <div>
                                        <div
                                            className="border"
                                            data-toggle="popover"
                                            tabIndex="0"
                                            title={skill.name}
                                            data-content={skill.value.replace(/#(\d+)/,
                                                Number(skill.value.match(/#(\d+)/, '$1')[1]) * (initial[2][index] + adapt[2][index])
                                            )}
                                            style={skill_icon}
                                        >
                                            <img
                                                alt={skill.imgAlt}
                                                src={skill.imgSrc}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                        <button
                                            className="btn btn-light"
                                            style={button}
                                            onClick={() => skill_point(2, index, "minus")}
                                        >
                                            -
                                        </button>
                                        {` ${initial[2][index] + adapt[2][index]} `}
                                        <button
                                            className="btn btn-light"
                                            style={button}
                                            onClick={() => skill_point(2, index, "plus")}>
                                                +
                                        </button>
                                    </div>
                                    <br />
                                </div>
                            ))}
                        </div> : null}
                        {character.job_class >= 3 ? <div className="col-md-auto" style={column_style}>
                            <div>3차</div>
                            <br/>
                            {passive_skill_list[character.job].class3.map((skill, index) => (
                                <div key={index}>
                                    <div>
                                        <div
                                            className="border"
                                            data-toggle="popover"
                                            tabIndex="0"
                                            title={skill.name}
                                            data-content={
                                                skill.value.match(/##(\d+)/, "$1")
                                                    ? skill.value
                                                        .replace(
                                                            /#(\d+)/,
                                                            Number(
                                                            skill.value.match(/#(\d+)/, "$1")[1]
                                                            ) *
                                                            (initial[3][index] + adapt[3][index])
                                                        )
                                                        .replace(
                                                            /##(\d+)/,
                                                            Number(
                                                            skill.value.match(/##(\d+)/, "$1")[1]
                                                            ) *
                                                            (initial[3][index] + adapt[3][index])
                                                        )
                                                    : skill.value.replace(
                                                        /#(\d+)/,
                                                        Number(
                                                            skill.value.match(/#(\d+)/, "$1")[1]
                                                        ) *
                                                            (initial[3][index] + adapt[3][index])
                                                        )
                                            }
                                            style={skill_icon}
                                        >
                                            <img
                                                alt={skill.imgAlt}
                                                src={skill.imgSrc}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                        <button
                                            className="btn btn-light"
                                            style={button}
                                            onClick={() => skill_point(3, index, "minus")}
                                        >
                                            -
                                        </button>
                                        {` ${initial[3][index] + adapt[3][index]} `}
                                        <button
                                            className="btn btn-light"
                                            style={button}
                                            onClick={() => skill_point(3, index, "plus")}>
                                            +
                                        </button>
                                    </div>
                                    <br />
                                </div>
                            ))}
                        </div> : null}
                    </div>
                </div> : null}
                <div className="col" style={auto_center}>
                    <button className="btn btn-success" style={{float:"left", width:"100px"}} onClick={()=>handleSubmit()}>적용</button>
                    <button className="btn btn-danger" style={{float:"right", width:"100px"}} onClick={()=>handleReset()}>초기화</button>
                </div>
            </div>
            <br/>
        </div>
    )
}

export default PassiveSkill