import React, {useEffect, useState} from "react";
import {emailValidation} from "../../utils/api/basic";
import {useParams} from "react-router-dom"

function EmailValidation() {
    const [loading, setLoading] = useState(true)
    const [validation, setValidation] = useState(false)
    const {token} = useParams()
    useEffect(()=>{
        emailValidation(token)
            .then(()=>{
                setValidation(true)
                setLoading(false)
            })
            .catch(()=>{
                setValidation(false)
                setLoading(false)
            })
    },[token])
    if(loading)
        return(
            <div className="section" style={{height:"70%", marginTop:"10%"}}>
                <div className="container text-center">
                    <h2>이메일 인증 중...</h2>
                </div>
            </div>
        )
    else
        return (
            <div className="section" style={{height:"70%", marginTop:"10%"}}>
                <div className="container text-center">
                    <h2>이메일 인증</h2>
                    <div className="row" style={{marginTop:"50px"}}>
                        {validation ?
                            <div className="w-100 list-group-item list-group-item-info">
                                <h4>인증이 완료되었습니다. 로그인 해주세요</h4>
                            </div>:
                            <div className="w-100 list-group-item list-group-item-danger">
                                <h4>인증이 실패했습니다.</h4>
                                <h4>이미 인증된 계정이거나 존재하지 않는 계정입니다.</h4>
                            </div>}
                    </div>
                </div>
            </div>
        );
}
export default EmailValidation;