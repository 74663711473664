import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {alreadyNameExist, errorOccur, guildCreated, impossibleName, nameTooLong} from "../../utils/toast";
import {useToasts} from "react-toast-notifications";
import {createGuild, getMyMemberData} from "../../utils/api/guild";
import {getCharacter} from "../../utils/api/character";
import {guildJoinTimeLeft} from "../../utils/toast/guild";

function GuildCreate(props) {
    const { addToast } = useToasts()
    const [name, setName] = useState('')
    const nameChange = event => {
        setName(event.target.value);
    };
    const dispatch = useDispatch()
    const handleSubmit = (name) =>{
        createGuild(name)
            .then(()=>{
                getCharacter().then(res=>{dispatch({type:'CHARACTER', payload:res.data})})
                getMyMemberData().then(res=>{dispatch({type:"GUILD", payload:res.data[0]})})
                guildCreated(addToast)
                props.history.push('/guild/info')}
            )
            .catch((error)=> {
                if (error.response.status === 401) dispatch({type: 'EXPIRED', payload: true})
                else if (error.response.status === 400) nameTooLong(addToast)
                else if (error.response.status === 503) impossibleName(addToast)
                else if (error.response.status === 406) alreadyNameExist(addToast)
                else if (error.response.status === 408) guildJoinTimeLeft(addToast)
                else errorOccur(addToast)
            })
    }

    return(
        <div className="section">
            <div className="container" style={{textAlign:"center"}}>
                <h1 style={{marginTop:"5%"}}>길드 생성</h1>
                <h5 style={{marginTop:"30px", marginBottom:"70px"}}>자신만의 길드를 창설할 수 있습니다.</h5>
                <div className="row">
                    <div className="col">
                        <h4 style={{marginBottom:"30px"}}>길드 이름</h4>
                        <h5>* 공백 사용 불가 및 8자 이하</h5>
                        <input className="input-group-lg" placeholder={"8자 이내"} onChange={nameChange}/>
                        <button className="btn-dark" onClick={()=>{handleSubmit(name)}}>결정</button>
                        <h5 style={{marginTop:"30px"}}>(주의! 30레벨 이상, 5백만 골드가 필요합니다.) * 베타라 무료 진행 중</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GuildCreate