import React from "react";
import {useSelector} from "react-redux";
import {BattlePage} from "../../utils/battle";

function BattleLog() {
    const battle_log = useSelector(state=>state.battle_log).value
    if (battle_log === null)
        return(
            <div className="section">
                <div className="container" style={{textAlign:"center"}}>
                    <h1>상대가 없습니다</h1>
                    <h5>로그에서 클릭하세요</h5>
                </div>
            </div>
        )
    else {
        return (
            <div className="section">
                <div className="container text-center">
                    {BattlePage(battle_log)}
                </div>
            </div>
        )
    }
}

export default BattleLog