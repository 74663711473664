import React, {useEffect, useState} from "react";
import { useToasts } from 'react-toast-notifications';
import TokenRefresh from '../tokenrefresh'
import {useDispatch, useSelector} from "react-redux";
import {actionTimeLeft, errorOccur, noHP} from "../../utils/toast";
import {FieldPage} from "../../utils/battle";
import {battleField, getField} from "../../utils/api/battle";
import {getCharacter} from "../../utils/api/character";
import {useInterval} from "../../utils/log";

function FieldSelect() {
    const [list, setList]=useState([])
    const [battle,setBattle] = useState(null)

    const [last, setLast] = useState(null)
    const [count, setCount] = useState(4.99)
    const [auto, setAuto] = useState(false)
    const { addToast } = useToasts()
    const dispatch = useDispatch()
    const character = useSelector(state=>state.character).value

    useEffect(()=>{
        getField()
            .then(res=>{
                setList(res.data)
            })
            .catch(error=>{
                if(error.response.status===401) dispatch({type:'EXPIRED', payload:true})
                else errorOccur(addToast)
            })
    },[addToast, dispatch]);

    const handleSubmit = (field) => {
        TokenRefresh()
        battleField(field)
            .then(res=>{
                setLast(field)
                setBattle(res.data)
                getCharacter().then(res=>{
                    dispatch({type:'CHARACTER', payload:res.data})
                    if (res.data.hp <= 0) setAuto(false)
                })
                setCount(4.99)})
            .catch(error=>{
                if(error.response.status===401) dispatch({type:'EXPIRED', payload:true})
                else if(error.response.status===417) actionTimeLeft(addToast, error.response.data)
                else if(error.response.status===406) noHP(addToast)
                else errorOccur(addToast)
                if(error.response.status!==417) setAuto(false)
            })
    };

    useInterval(()=>{ if (battle !== null && count>0) setCount(count-0.1)},101)
    if (auto && count <= 0){
        handleSubmit(last)
        setCount(4.99)
    }

    if (battle === null) {
        return (
            <div className="section">
                <div className="container">
                    <div className="row" style={{marginTop: "5%"}}>
                        <h1>필드 선택</h1>
                        <div className="col-md-12" style={{marginTop: "2%"}}>
                            <ul className="nav nav-pills flex-column">
                                {list.map(field => (
                                    <button className="nav-item bg-light border text-center" name="field_choice"
                                            onClick={(e) => handleSubmit(e.target.value)} style={{marginBottom:"20px"}}
                                            value={field.id} key={field.id}>{field.name}</button>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    else
        return(
            <div className="section">
                <div className="container">
                    <div className="col-12" style={{textAlign:"center"}}>
                        <h1 style={{marginBottom:"25px"}}>필드 전투</h1>
                        <button className="btn btn-light border" onClick={()=>{setBattle(null); setAuto(false)}}  style={{marginBottom:"20px"}}>필드 이동
                        </button>
                        <button className="btn btn-light border" onClick={()=>{handleSubmit(last)}} style={{marginBottom:"20px"}}>재전투
                        </button>
                        <div>
                            {Math.floor(count)+1}초 남았습니다 <br/>
                            자동전투 <input type={'checkbox'} onChange={()=>{if(auto) setAuto(false); else setAuto(true); }} checked={auto} style={{cursor: 'pointer'}} />
                        </div>

                    </div>
                    {FieldPage(battle, character)}
                </div>
            </div>
        )
}

export default FieldSelect