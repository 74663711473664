import axios from "axios";
import {api_url} from "./basic";

function getBoundaryCity() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/war_clame/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getClameList() {
    return Promise.resolve(
        axios.patch(api_url+'/api/v1/war_clame/', {},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getAttackParticipation() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/war_attack/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getDefendParticipation() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/war_defend/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getDefendList() {
    return Promise.resolve(
        axios.patch(api_url+'/api/v1/war_defend/',{}
            ,{headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function clameWar(target, time) {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/war_clame/", {target: target, time:time},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function attackCity() {
    return Promise.resolve(
        axios.post(api_url+'/api/v1/war_attack/', {},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function defendCity() {
    return Promise.resolve(
        axios.post(api_url+'/api/v1/war_defend/', {},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function participateWar(type, town_id) {
    return Promise.resolve(
        axios.post(api_url+'/api/v1/war_participate/', {action_type:type, town_id:town_id},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function cancelParticipation() {
    return Promise.resolve(
        axios.delete(api_url+'/api/v1/war_participate/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getRelatedParticipation() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/war_participate',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}


export {getBoundaryCity, getAttackParticipation, getDefendParticipation, getDefendList, clameWar, attackCity, defendCity,
    participateWar, cancelParticipation, getClameList, getRelatedParticipation}