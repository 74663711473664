import React, {useEffect, useState} from "react";
import moment from "moment";
import {logType, setBattleLog} from "../utils/log";
import {useDispatch} from "react-redux";
import {getLog} from "../utils/api/basic";
import { pageIndexing } from "../utils/item"

function Log(props) {
    const [list, setList] = useState([])
    const dispatch = useDispatch()
    const [pageNum, setPageNum] = useState(0)
    const [pageIndex, setPageIndex] = useState([0])

    useEffect(() => {
        getLog(1)
            .then(res=> {
                setList(res.data)
                setPageIndex(pageIndexing(pageIndexing(res.data[0].id).length))
            })
    }, [])
    
    return (
        <div className="section">
            <div className="container">
                <h1>로그</h1>
                <div className="row">
                    <div className="col-md-12">
                        <div className="list-group">
                            {list.map((item, index)=>(
                                <div className="list-group-item" key={index}
                                     onClick={item.battle !==null ? ()=>{setBattleLog(item, dispatch, props.history)}: null}>
                                    {logType(item.type)}
                                    &nbsp;
                                    <b style={item.battle ? {cursor: 'pointer'} : null}>[{moment(item.time).format("LT")}]</b>
                                    &nbsp;
                                    <font style={item.battle ? {cursor: 'pointer'} : null}>{item.log}</font>
                                </div>
                            ))}
                        </div>
                    </div>
                    <nav style={{ margin: '0 auto', marginTop: '10px' }}>
                        <ul className="pagination pagination-sm">
                        {pageIndex[pageNum - 1] ? (
                            <li className="page-item">
                                <a
                                    className="page-link"
                                    href="##"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setPageNum(pageNum - 1)
                                    }}
                                >
                                    Previous
                                </a>
                            </li>
                        ) : null}
                        {pageIndex[pageNum] ? pageIndex[pageNum].map((i, index) => (
                            <li className="page-item" key={index}>
                                <a
                                    className="page-link"
                                    href="##"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        getLog(pageIndex[pageNum][index] + 1).then(res => setList(res.data))
                                        getLog(1).then(res=> setPageIndex(pageIndexing(pageIndexing(res.data[0].id).length)))
                                    }}
                                >
                                    {pageIndex[pageNum][index] + 1}
                                </a>
                            </li>
                        )) : (
                            <li className="page-item">
                                <a className="page-link" href="##">1</a>
                            </li>
                        )}
                        {pageIndex[pageNum + 1] ? (
                            <li className="page-item">
                                <a
                                    className="page-link"
                                    href="##"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setPageNum(pageNum + 1)
                                    }}
                                >
                                    Next
                                </a>
                            </li>
                        ) : null}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    )
}
export  default Log