export function itemGrade(word) {
  switch (word) {
    case 'normal':
      return '일반'
    case 'rare':
      return '희귀'
    case 'hero':
      return '영웅'
    case 'legend':
      return '전설'
    default:
      return '버그이용자(문의)'
  }
}
export function instrType(type, job) {
  switch (type) {
    case 'weapon':
      if(job==='투술')
        return '양날도끼'
      else if(job==='검술')
        return '검'
      else if(job==='암술')
        return '단검'
      else if(job==='궁술')
        return '활'
      else if(job==='마술')
        return '지팡이'
      else if(job==='주술')
        return '낫'
      return '한손무기'
    case 'sub_weapon':
      if(job==='투술')
        return '건틀렛'
      else if(job==='검술')
        return '방패'
      else if(job==='암술')
        return '수리검'
      else if(job==='궁술')
        return '화살통'
      else if(job==='마술')
        return '마도서'
      else if(job==='주술')
        return '정령석'
      return '방패'
    case 'armor':
      return '갑옷'
    case 'necklace':
      return '목걸이'
    case 'bracelet':
      return '팔찌'
    case 'ring':
      return '반지'
    default:
      return '버그이용자(문의)'
  }
}

export function typeFilter(items, type) {
  return items.filter(item => {
    if (type === 'equipment') {
      if (
          item.instr_type === 'weapon' ||
          item.instr_type === 'sub_weapon' ||
          item.instr_type === 'armor' ||
          item.instr_type === 'necklace' ||
          item.instr_type === 'bracelet' ||
          item.instr_type === 'ring'
      ) {
        return item
      }
    }
    if (type === 'consumable' && item.instr_type === 'consumable') return item
    if (type === 'etc' && item.instr_type === 'etc') return item
    return false
  })
}

export function instrFilter(items, type, job) {
  return items.filter(item => {
    if (item.instr_type === type) {
      if (item.instr_type === 'weapon') {
        if (item.job === job || job === 'weapon') return item
        return false
      }
      else if (item.instr_type === 'sub_weapon') {
        if (item.job === job || job === 'sub_weapon') return item
        return false
      }

      return true
    }
    return false
  })
}


export function tablePaging(list, tableSize = 10) {
  let dividedList = [];
  let row = [];
  for (let num = 0; num < list.length; num++) {
    row.push(list[num])
    if (row.length === tableSize) {
      dividedList.push(row)
      row = []
    }
  }
  if (row.length !== 0) {
    dividedList.push(row);
  }
  return dividedList
}

export function pageIndexing(listLength, pageSize = 10) {
  let index = [];
  let page = [];
  for (let num = 0; num < listLength; num++) {
    page.push(num)
    if (page.length === pageSize) {
      index.push(page)
      page = []
    }
  }
  if (page.length !== 0) {
    index.push(page);
  }
  return index
}