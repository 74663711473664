import React, {useEffect, useState} from "react";
import {useToasts} from "react-toast-notifications";
import {useDispatch, useSelector} from "react-redux";
import {errorOccur, moneyInBank, moneyOutBank, noMoney} from "../../utils/toast";
import {getCharacter} from "../../utils/api/character";
import {checkBankMoney, moveBankMoney} from "../../utils/api/town";

function TownBank() {
    const character = useSelector(state=>state.character).value
    const town = useSelector(state=>state.town).value
    const [in_money, setInMoney] = useState(0)
    const [out_money, setOutMoney] = useState(0)
    const [bank, setBank] = useState(0)
    const dispatch = useDispatch()
    const { addToast } = useToasts()
    const inMoneyChange = event => setInMoney(event.target.value);
    const outMoneyChange = event => setOutMoney(event.target.value);
    const handleInMoney = () => {
        moveBankMoney("in", in_money)
            .then(()=>{
                getCharacter().then(res=>{dispatch({type:'CHARACTER', payload:res.data})})
                setInMoney(0)
                moneyInBank(addToast)
            })
            .catch(error=>{
                if (error.response.status === 401) dispatch({type: 'EXPIRED', payload: true})
                else if(error.response.status===406) noMoney(addToast)
                else errorOccur(addToast)
            })
    }
    const handleOutMoney = () => {
        moveBankMoney("out", out_money)
            .then(()=>{
                getCharacter().then(res=>{dispatch({type:'CHARACTER', payload:res.data})})
                setOutMoney(0)
                moneyOutBank(addToast)
            })
            .catch(error=>{
                if(error.response.status===406) noMoney(addToast)
            })
    }
    const setMaxInMoney = () => setInMoney(character.money)
    const setMaxOutMoney = () => setOutMoney(bank.money)

    useEffect(()=>{
        checkBankMoney()
            .then(res=> setBank(res.data))
            .catch(error=>{
                if(error.response.status===401) dispatch({type:'EXPIRED', payload:true})
                else errorOccur(addToast)
            })
    },[character, addToast, dispatch])

    return(
        <div className="section">
            <div className="container">
                <br/><br/><br/>
                <h1 className="text-center">{town[0]["nameKorean"]} 은행</h1><br/>
                <div className="row">
                    <div className="col-md-8" style={{marginBottom: '10%', marginRight:"auto", marginLeft:"auto"}}>
                        <h3 align="center">어서오세요, {town[0]["nameKorean"]} 은행입니다.</h3><br/>
                        <h3 align="center"><b>{character.name}</b> 님의 자금을 보관할 수 있습니다.</h3>
                        <br/><br/>
                        <table className="table-bordered table">
                            <tbody>
                            <tr>
                                <th>저축 금액</th>
                                <td>{bank.money ? bank.money.toLocaleString() : null}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="list-group-item border-top-0 border-dark" style={{textAlign:"right"}}>
                            <input className="input-group" type="number" onChange={outMoneyChange}
                                   style={{textAlign:"right"}} min="0" max={bank.money} value={out_money}/>
                            <button className="btn btn-warning" onClick={()=>setMaxOutMoney()}>전액</button> &nbsp;
                            <button className="btn btn-danger" onClick={()=>handleOutMoney()}>출금</button>
                        </div>
                        <table className="table-bordered table">
                            <tbody>
                            <tr>
                                <th>소지 금액</th>
                                <td>{character.money ? character.money.toLocaleString() : null}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="list-group-item border-top-0 border-dark" style={{textAlign:"right"}}>
                            <input className="input-group" type="number" onChange={inMoneyChange}
                                   style={{textAlign:"right"}} min="0" max={character.money} value={in_money}/>
                            <button className="btn btn-warning" onClick={()=>setMaxInMoney()}>전액</button> &nbsp;
                            <button className="btn btn-success" onClick={()=>handleInMoney()}>입금</button>
                        </div>
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TownBank