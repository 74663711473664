import React from "react";
import {useToasts} from "react-toast-notifications";
import {useDispatch, useSelector} from "react-redux";
import {actionTimeLeft, errorOccur, restAdapt} from "../../utils/toast";
import {takeRest} from "../../utils/api/town";
import {getCharacter} from "../../utils/api/character";

function TownRest() {
    const character = useSelector(state=>state.character).value
    const town = useSelector(state=>state.town).value
    const hp = (character.hp/character.max_hp*100)+"%"
    const mp = (character.mp/character.max_mp*100)+"%"
    const dispatch = useDispatch()
    const { addToast } = useToasts()

    const handleSubmit = () => {
        takeRest()
            .then(()=>{
                getCharacter().then(res=>{dispatch({type:'CHARACTER', payload:res.data})})
                restAdapt(addToast)
            })
            .catch(error=>{
                if (error.response.status === 401) dispatch({type: 'EXPIRED', payload: true})
                else if(error.response.status===417) actionTimeLeft(addToast, error.response.data)
                else errorOccur(addToast)
            })
    }

    return(
        <div className="section">
            <div className="container">
                <br/><br/><br/>
                <h1>{town[0]["nameKorean"]} 여관</h1><br/>
                <div className="row">
                    <div className="col-md-8" style={{marginBottom: '10%', marginRight:"auto", marginLeft:"auto"}}>
                        <h3 align="center">어서오세요, {town[0]["nameKorean"]} 여관입니다.</h3><br/>
                        <h3 align="center"> 조금 쉬다 가시는 건 어떠신가요?</h3><br/><br/>
                        HP
                        <div className="progress">
                            <div className="progress-bar bg-danger" role="progressbar" style={{width: hp}}/>
                            <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                                color="white"><b>{character.hp} / {character.max_hp}</b></font></div>
                        </div><br/>
                        MP
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: mp}}/>
                            <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                                color="white"><b>{character.mp} / {character.max_mp}</b></font></div>
                        </div>
                        <br/><br/>
                        <button className="btn btn-danger" onClick={()=>handleSubmit()} style={{float: "right"}}>회복</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TownRest