import React, { useState, useContext } from 'react'
import { WebSocketContext } from './websocketProvider';
import {useDispatch, useSelector} from "react-redux";
import {getCharacter} from "../../utils/api/character";

function TextInputBox() {
    const [message, setMessage] = useState("");
    const dispatch = useDispatch()
    const cur_channel  = useSelector(state=>state.chat_room).value
    const ws = useContext(WebSocketContext);
    const handleChangeText = (e) => {
        setMessage(e.target.value);
    }

    function noRefresh(event){
        event.preventDefault();
    }
    const handleClickSubmit = () => {
        ws[cur_channel].current.send(JSON.stringify({
            JWT: localStorage.getItem('token'),
            message: message
        }))
        getCharacter().then(res => dispatch({ type: "CHARACTER", payload: res.data }))
        setMessage('');
    }

    return (
        <form onSubmit={noRefresh} style={{position:"absolute", left:0, bottom:0, width:"100%"}}>
            <input className="col-md-12" type="text" value={message} placeholder="입력해주세요" onChange={handleChangeText}/>
            <button className="col-md-12" type="submit" onClick={handleClickSubmit}>Send!</button>
        </form>
    )
}

export default TextInputBox