import React, { useState } from "react";
import {useSelector} from "react-redux";
import {emptyID, emptyPassword, errorOccur, inactiveID, noAccountExist} from "../../utils/toast";
import {useToasts} from "react-toast-notifications";
import {tryLogin} from "../../utils/api/basic";

function Login(props) {
    const character = useSelector(state=> state.character).value
    const { addToast } = useToasts()
    if (!(character === 'null'&&!localStorage.getItem('token'))) {
        props.history.push('/chr/select')
    }
    const [username,SetUsername] = useState('');
    const [password,SetPassword] = useState('');

    const nameChange = event => {
        SetUsername(event.target.value);
    };

    const passChange = event => {
        SetPassword(event.target.value);
    };

    const handleSubmit = event => {
        event.preventDefault();
        tryLogin(username, password)
            .then(res => {
                localStorage.setItem('token', res.data.token);
                window.location.reload();
            })
            .catch(error => {
                if (error.response.status===400) noAccountExist(addToast)
                else if (error.response.status===489) emptyID(addToast)
                else if (error.response.status===488) emptyPassword(addToast)
                else if (error.response.status===487) inactiveID(addToast)
                else errorOccur(addToast)
            })
    };

    const login_style={
        marginLeft:'auto',
        marginRight:'auto',
        marginTop:'10%',
        marginBottom: '20%',
    };

    return (
        <div className="section">
            <div className="container">
                <div className="row">
                    <div className="text-center"
                         style={login_style}>
                        <h1>로그인</h1><br/><br/>
                        <form name="login_form" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input className="form-control" placeholder="Enter ID" type="text" name="username"
                                       id="username" autoComplete="username" onChange={nameChange}/>
                            </div>
                            <div className="form-group">
                                <input className="form-control" id="password" placeholder="Password" type="password"
                                       name="password" autoComplete="current-password" onChange={passChange} />
                            </div>
                            <div className="text-right">처음이신가요?&nbsp;
                                <a href="/signup/">회원가입</a>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                                <button type="submit"
                                        className="btn btn-light">로그인
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Login;