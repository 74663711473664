import React from "react";
import {getPercentData, jobCounter} from "./character";
import { instrType, itemGrade } from '../../src/utils/item'

function BattlePage(battle, isPlayerEquip, setIsPlayerEquip, isEnemyEquip, setIsEnemyEquip) {
    return(<>
        <div className="row" style={{marginTop: "5%"}}>
            <div className="col-md-3"
                 style={{border: "2px solid", padding: "10px", marginRight: "auto"}}><br/>
                <h4 className="text-right">{jobCounter(battle['player']['job'], battle['player']['jobClass'])}</h4>
                <h3 className="text-right">{battle['player']['name']}</h3>
                <h4 className="text-right" style={{marginBottom:"30px"}}>Lv. {battle['player']['level']}</h4>
                <br/>
                HP
                <div className="progress">
                    <div className="progress-bar bg-danger" role="progressbar"
                         style={{width: String(battle['player']['perhp']) + "%"}}/>
                    <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                        color="white">
                        <b>{battle['player']['curhp']} / {battle['player']['maxhp']}</b></font>
                    </div>
                </div><br/>MP
                <div className="progress">
                    <div className="progress-bar" role="progressbar"
                         style={{width: String(battle['player']['permp']) + "%"}}/>
                    <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                        color="white"><b>{battle['player']['curmp']} / {battle['player']['maxmp']}</b></font>
                    </div>
                </div>
                <br/>
                {isPlayerEquip ? 
                    <table className="table">
                        <tbody>
                            {battle.player.equip.map((item, index) => (
                                <tr key={index}>
                                    <th>{instrType(item.instr_type, item.job)}</th>
                                    <td
                                        style={{ textAlign: 'left' }}
                                        data-toggle="popover"
                                        data-content={`
                                            직업: ${jobCounter(item.job, 1)}<br />
                                            등급: ${itemGrade(item.grade)}<br />
                                            부위: ${instrType(item.instr_type, item.job)}<br />
                                            ${item.attack !== undefined ? `공격력: ${item.attack}<br />` : ''}
                                            ${item.magic !== undefined ? `마력: ${item.magic}<br />` : ''}
                                            ${item.def !== undefined ? `방어력: ${item.def}<br />` : ''}
                                            ${item.spirit !== undefined ? `정신력: ${item.spirit}<br />` : ''}
                                            ${item.weight !== undefined ? `무게: ${item.weight}<br />` : ''}
                                        `}
                                    >
                                        {item.name}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    : 
                    <table className="table">
                        <tbody>
                            <tr>
                                <th style={{padding:5}}><img src="/icon/attack.png" alt="attack icon" title="공격력"/></th>
                                <td style={{padding:5}} className="text-center">{battle['player']['atk']}</td>
                                <th style={{padding:5}}><img src="/icon/magic.png" alt="attack icon" title="마력"/></th>
                                <td style={{padding:5}} className="text-center">{battle['player']['mag']}</td>
                            </tr>
                            <tr>
                                <th style={{padding:5}}><img src="/icon/defend.png" alt="attack icon" title="방어력"/></th>
                                <td style={{padding:5}} className="text-center">{battle['player']['def']}</td>
                                <th style={{padding:5}}><img src="/icon/mag_defend.png" alt="attack icon" title="마법저항력"/></th>
                                <td style={{padding:5}} className="text-center">{battle['player']['spr']}</td>
                            </tr>
                            <tr>
                                <th style={{padding:5}}><img src="/icon/speed.png" alt="attack icon" title="속도"/></th>
                                <td style={{padding:5}} className="text-center">{battle['player']['spd']}</td>
                                <th style={{padding:5}}><img src="/icon/critical.png" alt="critical icon" title="치명타율"/></th>
                                <td style={{padding:5}} className="text-center">{parseFloat(battle['player']['cri']).toFixed(1)}</td>
                            </tr>
                        </tbody>
                    </table>}
                <button
                    className="btn btn-light"
                    onClick={() => setIsPlayerEquip(!isPlayerEquip)}
                >
                    장비
                </button>
            </div>
            <div className="col-md-5"
                 style={{padding: "0", marginRight: "auto", marginLeft:"auto"}}>
                <h1 style={{marginBottom:"30px", marginTop:"30px"}}>
                    전투 결과
                </h1>
                <h3 style={{marginBottom:"15px"}}>
                    승자
                </h3>
                <h2 style={{marginBottom:"30px"}}>
                    {battle['winner']}
                </h2>
                <div className="col-md" style={{width:'45%', padding:'0', float:"left"}}>
                    <h4 style={{marginBottom:"15px"}}>{battle['player']['name']}</h4>
                    HP
                    <div className="progress" style={{marginBottom:"15px"}}>
                        <div className="progress-bar bg-danger" role="progressbar"
                             style={{width: String(battle['log'][battle['log'].length-1]['player']['perhp']) + "%"}}/>
                        <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                            color="white"><b>{battle['log'][battle['log'].length-1]['player']['hp']} / {battle['player']['maxhp']}</b></font>
                        </div>
                    </div>
                    MP
                    <div className="progress">
                        <div className="progress-bar" role="progressbar"
                             style={{width: String(battle['log'][battle['log'].length-1]['player']['permp']) + "%"}}/>
                        <div className="justify-content-center d-flex position-absolute w-100 align-self-center">
                            <font
                                color="white"><b>{battle['log'][battle['log'].length-1]['player']['mp']} / {battle['player']['maxmp']}</b></font>
                        </div>
                    </div>
                </div>
                <div className="col-md" style={{width:'45%', padding:'0', float:"right"}}>
                    <h4 style={{marginBottom:"15px"}}>{battle['enemy']['name']}</h4>

                    HP
                    <div className="progress" style={{marginBottom:"15px"}}>
                        <div className="progress-bar bg-danger" role="progressbar"
                             style={{width: String(battle['log'][battle['log'].length-1]['enemy']['perhp']) + "%"}}/>
                        <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                            color="white"><b>{battle['log'][battle['log'].length-1]['enemy']['hp']} / {battle['enemy']['maxhp']}</b></font>
                        </div>
                    </div>
                    MP
                    <div className="progress">
                        <div className="progress-bar" role="progressbar"
                             style={{width: String(battle['log'][battle['log'].length-1]['enemy']['permp']) + "%"}}/>
                        <div className="justify-content-center d-flex position-absolute w-100 align-self-center">
                            <font
                                color="white"><b>{battle['log'][battle['log'].length-1]['enemy']['mp']} / {battle['enemy']['maxmp']}</b></font>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3"
                 style={{border: "2px solid", padding: "10px", marginLeft: "auto"}}><br/>
                <h4 className="text-right">{jobCounter(battle['enemy']['job'], battle['enemy']['jobClass'])}</h4>
                <h3 className="text-right">{battle['enemy']['name']}</h3>
                <h4 className="text-right" style={{marginBottom:"30px"}}>Lv. {battle['enemy']['level']}</h4>
                <br/>HP
                <div className="progress">
                    <div className="progress-bar bg-danger" role="progressbar"
                         style={{width: String(battle['enemy']['perhp']) + "%"}}/>
                    <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                        color="white"><b>{battle['enemy']['curhp']} / {battle['enemy']['maxhp']}</b></font>
                    </div>
                </div><br/>MP
                <div className="progress">
                    <div className="progress-bar" role="progressbar"
                         style={{width: String(battle['enemy']['permp']) + "%"}}/>
                    <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                        color="white"><b>{battle['enemy']['curmp']} / {battle['enemy']['maxmp']}</b></font>
                    </div>
                </div>
                <br/>
                {isEnemyEquip ? 
                    <table className="table">
                        <tbody>
                            {battle.enemy.equip.map((item, index) => (
                                <tr key={index}>
                                    <th>{instrType(item.instr_type, item.job)}</th>
                                    <td
                                        style={{ textAlign: 'left' }}
                                        data-toggle="popover"
                                        data-content={`
                                            직업: ${jobCounter(item.job, 1)}<br />
                                            등급: ${itemGrade(item.grade)}<br />
                                            부위: ${instrType(item.instr_type, item.job)}<br />
                                            ${item.attack !== undefined ? `공격력: ${item.attack}<br />` : ''}
                                            ${item.magic !== undefined ? `마력: ${item.magic}<br />` : ''}
                                            ${item.def !== undefined ? `방어력: ${item.def}<br />` : ''}
                                            ${item.spirit !== undefined ? `정신력: ${item.spirit}<br />` : ''}
                                            ${item.weight !== undefined ? `무게: ${item.weight}<br />` : ''}
                                        `}
                                    >
                                        {item.name}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table> 
                    :
                    <table className="table">
                        <tbody>
                            <tr>
                                <th style={{padding:5}}><img src="/icon/attack.png" alt="attack icon" title="공격력"/></th>
                                <td style={{padding:5}} className="text-center">{battle['enemy']['atk']}</td>
                                <th style={{padding:5}}><img src="/icon/magic.png" alt="attack icon" title="마력"/></th>
                                <td style={{padding:5}} className="text-center">{battle['enemy']['mag']}</td>
                            </tr>
                            <tr>
                                <th style={{padding:5}}><img src="/icon/defend.png" alt="attack icon" title="방어력"/></th>
                                <td style={{padding:5}} className="text-center">{battle['enemy']['def']}</td>
                                <th style={{padding:5}}><img src="/icon/mag_defend.png" alt="attack icon" title="마법저항력"/></th>
                                <td style={{padding:5}} className="text-center">{battle['enemy']['spr']}</td>
                            </tr>
                            <tr>
                                <th style={{padding:5}}><img src="/icon/speed.png" alt="attack icon" title="속도"/></th>
                                <td style={{padding:5}} className="text-center">{battle['enemy']['spd']}</td>
                                <th style={{padding:5}}><img src="/icon/critical.png" alt="critical icon" title="치명타율"/></th>
                                <td style={{padding:5}} className="text-center">{parseFloat(battle['enemy']['cri']).toFixed(1)}</td>
                            </tr>
                        </tbody>
                    </table>}
                <button
                    className="btn btn-light"
                    onClick={() => setIsEnemyEquip(!isEnemyEquip)}
                >
                    장비
                </button>
            </div>
        </div>
        {battle["log"].slice(0, -1).map((l, index) => (
            <div className="row"
                 style={{paddingTop: "3%", paddingBottom: "3%", borderBottom: "1px solid"}} key={index}>
                <div className="col-md-3" style={{marginRight: "auto"}}>
                    HP
                    <div className="progress">
                        <div className="progress-bar bg-danger" role="progressbar"
                             style={{width: String(l['player']['perhp']) + "%"}}/>
                        <div className="justify-content-center d-flex position-absolute w-100 align-self-center">
                            <font
                                color="white"><b>{l['player']['hp']} / {battle['player']['maxhp']}</b></font>
                        </div>
                    </div> MP
                    <div className="progress">
                        <div className="progress-bar" role="progressbar"
                             style={{width: String(l['player']['permp']) + "%"}}/>
                        <div className="justify-content-center d-flex position-absolute w-100 align-self-center">
                            <font
                                color="white"><b>{l['player']['mp']} / {battle['player']['maxmp']}</b></font>
                        </div>
                    </div>
                </div>
                <div style={{verticalAlign:"center", whiteSpace:"pre-wrap", textAlign:"center"}}>
                    <br/>{l.text}<br/>
                </div>
                <div className="col-md-3" style={{marginLeft: "auto"}}>
                    HP
                    <div className="progress">
                        <div className="progress-bar bg-danger" role="progressbar"
                             style={{width: String(l['enemy']['perhp']) + "%"}}/>
                        <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                            color="white"><b>{l['enemy']['hp']} / {battle['enemy']['maxhp']}</b></font>
                        </div>
                    </div>MP
                    <div className="progress">
                        <div className="progress-bar" role="progressbar"
                             style={{width: String(l['enemy']['permp']) + "%"}}/>
                        <div className="justify-content-center d-flex position-absolute w-100 align-self-center">
                            <font
                                color="white"><b>{l['enemy']['mp']} / {battle['enemy']['maxmp']}</b></font>
                        </div>
                    </div>
                </div>
            </div>
        ))}
        <div className="row" style={{paddingTop: "3%", paddingBottom: "3%"}}>
            <div className="col-md-3" style={{marginRight: "auto"}}/>
            <div style={{paddingTop:"3%", paddingBottom:"3%"}}>
                <br/>{battle["log"].slice(-1)[0].text}<br/>
            </div>
            <div className="col-md-3" style={{marginLeft: "auto"}}/>
        </div>
    </>)
}

function FieldPage(battle, character) {
    const {max_exp, hp, mp, exp} = getPercentData(character)
    return(<>
        <div className="row" style={{marginTop: "5%"}}>
            <div className="col-md-3" style={{border:"2px solid", padding:"10px", marginRight: "auto"}}><br/>
                <h4 className="text-right">{jobCounter(battle['player']['job'], battle['player']['jobClass'])}</h4>
                <h3 className="text-right">{battle['player']['name']}</h3>
                <h4 className="text-right" style={{marginBottom:"30px"}}>Lv. {battle['player']['level']}</h4>
                <br/>HP
                <div className="progress">
                    <div className="progress-bar bg-danger" role="progressbar"
                         style={{width: String(battle['player']['perhp'])+"%"}}/>
                    <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font color="white">
                        <b>{battle['player']['curhp']} / {battle['player']['maxhp']}</b></font></div>
                </div><br/>MP
                <div className="progress">
                    <div className="progress-bar" role="progressbar"
                         style={{width: String(battle['player']['permp'])+"%"}}/>
                    <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                        color="white"><b>{battle['player']['curmp']} / {battle['player']['maxmp']}</b></font></div>
                </div>
                <br/>
                <table className="table">
                    <tbody>
                    <tr>
                        <th style={{padding:5}}><img src="/icon/attack.png" alt="attack icon" title="공격력"/></th>
                        <td style={{padding:5}} className="text-center">{battle['player']['atk']}</td>
                        <th style={{padding:5}}><img src="/icon/magic.png" alt="attack icon" title="마력"/></th>
                        <td style={{padding:5}} className="text-center">{battle['player']['mag']}</td>
                    </tr>
                    <tr>
                        <th style={{padding:5}}><img src="/icon/defend.png" alt="attack icon" title="방어력"/></th>
                        <td style={{padding:5}} className="text-center">{battle['player']['def']}</td>
                        <th style={{padding:5}}><img src="/icon/mag_defend.png" alt="attack icon" title="마법저항력"/></th>
                        <td style={{padding:5}} className="text-center">{battle['player']['spr']}</td>
                    </tr>
                    <tr>
                        <th style={{padding:5}}><img src="/icon/speed.png" alt="attack icon" title="속도"/></th>
                        <td style={{padding:5}} className="text-center">{battle['player']['spd']}</td>
                        <th style={{padding:5}}><img src="/icon/critical.png" alt="critical icon" title="치명타율"/></th>
                        <td style={{padding:5}} className="text-center">{parseFloat(battle['player']['cri']).toFixed(1)}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="col-md-3  text-center"
                 style={{padding: "0", marginRight: "auto", marginLeft:"auto"}}>
                <h2 style={{marginBottom:"30px", marginTop:"30px"}}>
                    전투 결과
                </h2>

                {battle['win']? <h4 style={{marginBottom:"25px"}}>승리</h4>:
                    <h4 style={{marginBottom:"25px"}}>패배</h4>}

                {(() => {
                    if (battle['win'] && battle['enemy']['exp'] > character.exp) {
                        return (
                            <h5 style={{marginBottom:"25px"}}>
                                레벨업하였습니다
                            </h5>
                        )
                    } else if(character.lev === 10 && character.job === '없음') {
                        return (
                            <h5 style={{marginBottom:"25px"}}>
                                전직할 수 있습니다
                            </h5>
                        )
                    } else if(character.lev === 50) {
                        return (
                            <h5 style={{marginBottom:"25px"}}>
                                최대 레벨입니다
                            </h5>
                        )
                    } else {
                        return (
                            <h5 style={{marginBottom:"25px"}}>
                                레벨업까지 {max_exp-character.exp} 경험치
                            </h5>
                        )
                    }
                })()}

                <div className="progress" style={{marginBottom:"15px"}}>
                    <div className="progress-bar bg-danger" role="progressbar"
                         style={{width: hp}}/>
                    <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                        color="white"><b>{battle['log'][battle['log'].length-1]['player']['hp']} / {character.max_hp}</b></font>
                    </div>
                </div>
                <div className="progress" style={{marginBottom:"15px"}}>
                    <div className="progress-bar" role="progressbar"
                         style={{width: mp}}/>
                    <div className="justify-content-center d-flex position-absolute w-100 align-self-center">
                        <font
                            color="white"><b>{battle['log'][battle['log'].length-1]['player']['mp']} / {character.max_mp}</b></font>
                    </div>
                </div>
                <div className="progress" style={{marginBottom:"30px"}}>
                    <div className="progress-bar bg-warning" role="progressbar" style={{width: exp}}/>
                    <div className="justify-content-center d-flex position-absolute w-100 align-self-center">
                        <font color="white"><b>{character.exp} / {max_exp}</b></font></div>
                </div>
                <table className="table">
                    <tbody>
                    <tr>
                        <th>경험치</th>
                        {battle['win'] ?
                            <td>{character.exp}(+{battle['enemy']['exp']}) / {max_exp}</td>:
                            <td>{character.exp} / {max_exp}</td>}
                    </tr>
                    <tr>
                        <th>소지금</th>
                        {battle['win'] ? <td>{character.money.toLocaleString()}(+{battle['enemy']['mon'].toLocaleString()})</td>:
                            <td>{character.money.toLocaleString()}(-{character.money.toLocaleString()})</td>}
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="col-md-3" style={{border:"2px solid", padding:"10px", marginLeft: "auto"}}><br/><h1
                className="text-right">{battle['enemy']['name']}</h1><h3 className="text-right">Lv. {battle['enemy'].level}</h3><br/>HP
                <div className="progress">
                    <div className="progress-bar bg-danger" role="progressbar"
                         style={{width: String(battle['enemy']['perhp'])+"%"}}/>
                    <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                        color="white"><b>{battle['enemy']['curhp']} / {battle['enemy']['maxhp']}</b></font></div>
                </div><br/>MP
                <div className="progress">
                    <div className="progress-bar" role="progressbar"
                         style={{width: String(battle['enemy']['permp'])+"%"}}/>
                    <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                        color="white"><b>{battle['enemy']['curmp']} / {battle['enemy']['maxmp']}</b></font>
                    </div>
                </div>
                <br/>
                <table className="table">
                    <tbody>
                    <tr>
                        <th style={{padding:5}}><img src="/icon/attack.png" alt="attack icon" title="공격력"/></th>
                        <td style={{padding:5}} className="text-center">{battle['enemy']['atk']}</td>
                        <th style={{padding:5}}><img src="/icon/magic.png" alt="attack icon" title="마력"/></th>
                        <td style={{padding:5}} className="text-center">{battle['enemy']['mag']}</td>
                    </tr>
                    <tr>
                        <th style={{padding:5}}><img src="/icon/defend.png" alt="attack icon" title="방어력"/></th>
                        <td style={{padding:5}} className="text-center">{battle['enemy']['def']}</td>
                        <th style={{padding:5}}><img src="/icon/mag_defend.png" alt="attack icon" title="마법저항력"/></th>
                        <td style={{padding:5}} className="text-center">{battle['enemy']['spr']}</td>
                    </tr>
                    <tr>
                        <th style={{padding:5}}><img src="/icon/speed.png" alt="attack icon" title="속도"/></th>
                        <td style={{padding:5}} className="text-center">{battle['enemy']['spd']}</td>
                        <th style={{padding:5}}><img src="/icon/critical.png" alt="critical icon" title="치명타율"/></th>
                        <td style={{padding:5}} className="text-center">{parseFloat(battle['enemy']['cri']).toFixed(1)}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        {battle["log"].slice(0,-1).map((l,index)=>(
            <div className="row" style={{paddingTop:"3%", paddingBottom:"3%", borderBottom:"1px solid"}} key={index}>
                <div className="col-md-3" style={{marginRight: "auto"}}>
                    HP
                    <div className="progress">
                        <div className="progress-bar bg-danger" role="progressbar" style={{width: String(l['player']['perhp'])+"%"}}/>
                        <div className="justify-content-center d-flex position-absolute w-100 align-self-center">
                            <font color="white"><b>{l['player']['hp']} / {battle['player']['maxhp']}</b></font>
                        </div>
                    </div> MP
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{width: String(l['player']['permp'])+"%"}}/>
                        <div className="justify-content-center d-flex position-absolute w-100 align-self-center">
                            <font color="white"><b>{l['player']['mp']} / {battle['player']['maxmp']}</b></font>
                        </div>
                    </div>
                </div>
                <div style={{verticalAlign:"center", whiteSpace:"pre-wrap", textAlign:"center"}}>
                    <br/>{l.text}<br/>
                </div>
                <div className="col-md-3" style={{marginLeft: "auto"}}>
                    HP
                    <div className="progress">
                        <div className="progress-bar bg-danger" role="progressbar"
                             style={{width: String(l['enemy']['perhp'])+"%"}}/>
                        <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                            color="white"><b>{l['enemy']['hp']} / {battle['enemy']['maxhp']}</b></font></div>
                    </div>MP
                    <div className="progress">
                        <div className="progress-bar" role="progressbar"
                             style={{width: String(l['enemy']['permp'])+"%"}}/>
                        <div className="justify-content-center d-flex position-absolute w-100 align-self-center">
                            <font color="white"><b>{l['enemy']['mp']} / {battle['enemy']['maxmp']}</b></font></div>
                    </div>
                </div>
            </div>
        ))}
        <div className="row" style={{paddingTop:"3%", paddingBottom:"3%"}}>
            <div className="col-md-3" style={{marginRight: "auto"}}/>
            <div style={{paddingTop:"3%", paddingBottom:"3%"}}>
                {battle["log"].slice(-1)[0].text}
            </div>
            <div className="col-md-3" style={{marginLeft: "auto"}}/>
        </div>
    </>)

}

export {BattlePage, FieldPage}