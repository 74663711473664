import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { errorOccur, freeStatAdapt, freeStatReset } from "../../utils/toast";
import {
  resetFreeStat,
  adaptFreeStat,
  getCharacter,
} from "../../utils/api/character";

function FreeStat() {
  const dispatch = useDispatch();
  const character = useSelector((state) => state.character).value;
  const { addToast } = useToasts();

  const [str, setStr] = useState(0);
  const [ind, setInd] = useState(0);
  const [agl, setAgl] = useState(0);
  const [spr, setSpr] = useState(0);
  const [mag, setMag] = useState(0);
  const [luk, setLuk] = useState(0);
  const [last, setLast] = useState(
      character.tot_frstat - character.used_frstat
  );
  const [tot_last, setTotLast] = useState(
      character.tot_frstat - character.used_frstat
  );
  const statList = useState([
    ["강인"],
    ["단련"],
    ["민첩"],
    ["정신"],
    ["마력"],
    ["행운"],
  ])[0];

  const hp = (character.hp / character.max_hp) * 100 + "%";
  const mp = (character.mp / character.max_mp) * 100 + "%";

  const auto_center = {
    marginLeft: "auto",
    marginRight: "auto",
  };
  const inline_display = {
    display: "inline",
  };
  const center_text = {
    textAlign: "center",
  };
  const width40 = {
    textAlign: "center",
    width: "100",
  };

  function str_stat(value, calculation) {
    if (calculation === "plus") {
      if (last >= value) {
        setStr(str + value);
        setLast(last - value);
      } else {
        setStr(str + last);
        setLast(0);
      }
    } else {
      if (tot_last - last >= value && str >= value) {
        setStr(str - value);
        setLast(last + value);
      } else {
        setStr(0);
        setLast(last + str);
      }
    }
  }
  function ind_stat(value, calculation) {
    if (calculation === "plus") {
      if (last >= value) {
        setInd(ind + value);
        setLast(last - value);
      } else {
        setInd(ind + last);
        setLast(0);
      }
    } else {
      if (tot_last - last >= value && ind >= value) {
        setInd(ind - value);
        setLast(last + value);
      } else {
        setInd(0);
        setLast(last + ind);
      }
    }
  }
  function agl_stat(value, calculation) {
    if (calculation === "plus") {
      if (last >= value) {
        setAgl(agl + value);
        setLast(last - value);
      } else {
        setAgl(agl + last);
        setLast(0);
      }
    } else {
      if (tot_last - last >= value && agl >= value) {
        setAgl(agl - value);
        setLast(last + value);
      } else {
        setAgl(0);
        setLast(last + agl);
      }
    }
  }
  function spr_stat(value, calculation) {
    if (calculation === "plus") {
      if (last >= value) {
        setSpr(spr + value);
        setLast(last - value);
      } else {
        setSpr(spr + last);
        setLast(0);
      }
    } else {
      if (tot_last - last >= value && spr >= value) {
        setSpr(spr - value);
        setLast(last + value);
      } else {
        setSpr(0);
        setLast(last + spr);
      }
    }
  }
  function mag_stat(value, calculation) {
    if (calculation === "plus") {
      if (last >= value) {
        setMag(mag + value);
        setLast(last - value);
      } else {
        setMag(mag + last);
        setLast(0);
      }
    } else {
      if (tot_last - last >= value && mag >= value) {
        setMag(mag - value);
        setLast(last + value);
      } else {
        setMag(0);
        setLast(last + mag);
      }
    }
  }
  function luk_stat(value, calculation) {
    if (calculation === "plus") {
      if (last >= value) {
        setLuk(luk + value);
        setLast(last - value);
      } else {
        setLuk(luk + last);
        setLast(0);
      }
    } else {
      if (tot_last - last >= value && luk >= value) {
        setLuk(luk - value);
        setLast(last + value);
      } else {
        setLuk(0);
        setLast(last + luk);
      }
    }
  }

  useEffect(() => {
    setStr(0);
    setLuk(0);
    setSpr(0);
    setMag(0);
    setInd(0);
    setAgl(0);
    setLast(character.tot_frstat - character.used_frstat);
    setTotLast(character.tot_frstat - character.used_frstat);
  }, [character]);

  const handleAdapt = () => {
    adaptFreeStat(str, ind, agl, spr, mag, luk)
        .then(() => {
          getCharacter().then((res) => {
            dispatch({ type: "CHARACTER", payload: res.data });
          });
          freeStatAdapt(addToast);
        })
        .catch((error) => {
          if (error.response.status === 401)
            dispatch({ type: "EXPIRED", payload: true });
          else errorOccur(addToast);
        });
  };

  const handleReset = () => {
    resetFreeStat()
        .then(() => {
          getCharacter().then((res) => {
            dispatch({ type: "CHARACTER", payload: res.data });
          });
          freeStatReset(addToast);
        })
        .catch((error) => {
          if (error.response.status === 401)
            dispatch({ type: "EXPIRED", payload: true });
          else errorOccur(addToast);
        });
  };

  return (
      <div className="section">
        <div className="container">
          <h1 style={center_text}>{character.name} 스텟 분배</h1>
          <br />
          <div className="row">
            <div className="col-md-6" style={auto_center}>
              HP
              <div className="progress">
                <div className="progress-bar bg-danger" role="progressbar" style={{ width: hp }}/>
                <div className="justify-content-center d-flex position-absolute w-100 align-self-center">
                  <font color="white"><b>{character.hp} / {character.max_hp}</b></font>
                </div>
              </div>
              MP
              <div className="progress">
                <div className="progress-bar" role="progressbar" style={{ width: mp }}/>
                <div className="justify-content-center d-flex position-absolute w-100 align-self-center">
                  <font color="white"><b>{character.mp} / {character.max_mp}</b></font>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6" style={auto_center}>
              <table className="table">
                <tbody>
                <tr>
                  <th style={width40}><img src="/icon/attack.png" alt="attack icon" title="공격력"/></th>
                  <td className="text-center">{character.attack}</td>
                  <th style={width40}><img src="/icon/magic.png" alt="attack icon" title="마력"/></th>
                  <td className="text-center">{character.magic}</td>
                </tr>
                <tr>
                  <th style={width40}><img src="/icon/defend.png" alt="attack icon" title="방어력"/></th>
                  <td className="text-center">{character.defend}</td>
                  <th style={width40}><img src="/icon/mag_defend.png" alt="attack icon" title="마법저항력"/></th>
                  <td className="text-center">{character.mag_defend}</td>
                </tr>
                <tr>
                  <th style={width40}><img src="/icon/speed.png" alt="attack icon" title="속도"/></th>
                  <td className="text-center">{character.speed}</td>
                  <th style={width40}><img src="/icon/critical.png" alt="attack icon" title="치명타율"/></th>
                  <td className="text-center">{parseFloat(character.critical).toFixed(1)}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-auto" style={auto_center}>
              <table className="table">
                <tbody>
                <tr>
                  <th width="100" />
                  <td width="100">자유 스텟</td>
                  <td />
                  <td style={center_text}>
                    <div id="stat_text" style={inline_display}>
                      {last}
                    </div>
                  </td>
                  <td />
                </tr>
                {statList.map((statName, id) => (
                    <tr key={id}>
                      <th width="100">{statName}</th>
                      <td width="150">
                        {statName.toString() === "강인" ? character.str + ` (+${character.str_fr})` : null}
                        {statName.toString() === "단련" ? character.ind + ` (+${character.ind_fr})` : null}
                        {statName.toString() === "민첩" ? character.agl + ` (+${character.agl_fr})` : null}
                        {statName.toString() === "정신" ? character.spr + ` (+${character.spr_fr})` : null}
                        {statName.toString() === "마력" ? character.mag + ` (+${character.mag_fr})` : null}
                        {statName.toString() === "행운" ? character.luk + ` (+${character.luk_fr})` : null}
                      </td>
                      <td>
                        <button
                            type="button"
                            onClick={() => {
                              if (statName.toString() === "강인") {
                                str_stat(100, "minus");
                              } else if (statName.toString() === "단련") {
                                ind_stat(100, "minus");
                              } else if (statName.toString() === "민첩") {
                                agl_stat(100, "minus");
                              } else if (statName.toString() === "정신") {
                                spr_stat(100, "minus");
                              } else if (statName.toString() === "마력") {
                                mag_stat(100, "minus");
                              } else if (statName.toString() === "행운") {
                                luk_stat(100, "minus");
                              }
                            }}
                        >
                          -100
                        </button>
                        &nbsp;
                        <button
                            type="button"
                            onClick={() => {
                              if (statName.toString() === "강인") {
                                str_stat(10, "minus");
                              } else if (statName.toString() === "단련") {
                                ind_stat(10, "minus");
                              } else if (statName.toString() === "민첩") {
                                agl_stat(10, "minus");
                              } else if (statName.toString() === "정신") {
                                spr_stat(10, "minus");
                              } else if (statName.toString() === "마력") {
                                mag_stat(10, "minus");
                              } else if (statName.toString() === "행운") {
                                luk_stat(10, "minus");
                              }
                            }}
                        >
                          -10
                        </button>
                        &nbsp;
                        <button
                            type="button"
                            onClick={() => {
                              if (statName.toString() === "강인") {
                                str_stat(1, "minus");
                              } else if (statName.toString() === "단련") {
                                ind_stat(1, "minus");
                              } else if (statName.toString() === "민첩") {
                                agl_stat(1, "minus");
                              } else if (statName.toString() === "정신") {
                                spr_stat(1, "minus");
                              } else if (statName.toString() === "마력") {
                                mag_stat(1, "minus");
                              } else if (statName.toString() === "행운") {
                                luk_stat(1, "minus");
                              }
                            }}
                        >
                          -
                        </button>
                      </td>
                      <td width="50" style={center_text}>
                        <div id="str_text" style={inline_display}>
                          {statName.toString() === "강인" ? str : null}
                          {statName.toString() === "단련" ? ind : null}
                          {statName.toString() === "민첩" ? agl : null}
                          {statName.toString() === "정신" ? spr : null}
                          {statName.toString() === "마력" ? mag : null}
                          {statName.toString() === "행운" ? luk : null}
                        </div>
                      </td>
                      <td>
                        <button
                            type="button"
                            onClick={() => {
                              if (statName.toString() === "강인") {
                                str_stat(1, "plus");
                              } else if (statName.toString() === "단련") {
                                ind_stat(1, "plus");
                              } else if (statName.toString() === "민첩") {
                                agl_stat(1, "plus");
                              } else if (statName.toString() === "정신") {
                                spr_stat(1, "plus");
                              } else if (statName.toString() === "마력") {
                                mag_stat(1, "plus");
                              } else if (statName.toString() === "행운") {
                                luk_stat(1, "plus");
                              }
                            }}
                        >
                          +
                        </button>
                        &nbsp;
                        <button
                            type="button"
                            onClick={() => {
                              if (statName.toString() === "강인") {
                                str_stat(10, "plus");
                              } else if (statName.toString() === "단련") {
                                ind_stat(10, "plus");
                              } else if (statName.toString() === "민첩") {
                                agl_stat(10, "plus");
                              } else if (statName.toString() === "정신") {
                                spr_stat(10, "plus");
                              } else if (statName.toString() === "마력") {
                                mag_stat(10, "plus");
                              } else if (statName.toString() === "행운") {
                                luk_stat(10, "plus");
                              }
                            }}
                        >
                          +10
                        </button>
                        &nbsp;
                        <button
                            type="button"
                            onClick={() => {
                              if (statName.toString() === "강인") {
                                str_stat(100, "plus");
                              } else if (statName.toString() === "단련") {
                                ind_stat(100, "plus");
                              } else if (statName.toString() === "민첩") {
                                agl_stat(100, "plus");
                              } else if (statName.toString() === "정신") {
                                spr_stat(100, "plus");
                              } else if (statName.toString() === "마력") {
                                mag_stat(100, "plus");
                              } else if (statName.toString() === "행운") {
                                luk_stat(100, "plus");
                              }
                            }}
                        >
                          +100
                        </button>
                      </td>
                    </tr>
                ))}
                </tbody>
              </table>
              <div className="inline" align="left" style={{ float: "left" }}>
                <button
                    className="btn btn-success"
                    onClick={() => {
                      handleAdapt();
                    }}
                    id="adapt"
                >
                  적용
                </button>
              </div>
              <div className="inline" align="right" style={{ float: "right" }}>
                <button
                    className="btn btn-danger"
                    onClick={() => {
                      handleReset();
                    }}
                    id="reset"
                >
                  초기화
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
  );
}

export default FreeStat;
