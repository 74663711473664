import axios from "axios";
import {api_url} from "./basic";

function getField() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/field/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function battleField(field) {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/field/", {field: field},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getPracticeMember() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/practice/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function battlePractice(enemy) {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/practice/", {enemy: enemy},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getMyArenaInfo() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/arena/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function battleArena() {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/arena/", {},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getArenaTotalRank() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/arena_list/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getMyGradeRank() {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/arena_list/", {},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
export {getPracticeMember, battlePractice, getMyArenaInfo, battleArena, battleField, getField, getArenaTotalRank, getMyGradeRank}