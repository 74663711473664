import axios from "axios";
import {api_url} from "./basic";

function getGuildList() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/guild_join',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function createGuild(name) {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/guild/", {name: name},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function deleteGuild() {
    return Promise.resolve(
        axios.delete(api_url+'/api/v1/guild/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getGuildInfo() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/guild/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getGuildMemberList() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/guild_member_list/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getMyMemberData() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/guild_member/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getMyApplyList() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/guild_apply',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getAppliedList() {
    return Promise.resolve(
        axios.put(api_url+'/api/v1/guild_apply/', {},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function approveApply(name) {
    return Promise.resolve(
        axios.post(api_url+'/api/v1/guild_member_list/', {name:name},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function rejectApply(name) {
    return Promise.resolve(
        axios.patch(api_url+'/api/v1/guild_member_list/', {name:name},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function joinInGuild(guild) {
    return Promise.resolve(
        axios.post(api_url+'/api/v1/guild_join/',{guild:guild},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function deleteMyApply(guild) {
    return Promise.resolve(
        axios.patch(api_url+'/api/v1/guild_apply/',{guild:guild},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function outGuild() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/my_guild/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function guildOP_0(name) {
    return Promise.resolve(
        axios.put(api_url+'/api/v1/guild_member/', {name:name},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function guildOP_1(name) {
    return Promise.resolve(
        axios.post(api_url+'/api/v1/guild_member/', {name:name},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function guildOP_2(name) {
    return Promise.resolve(
        axios.patch(api_url+'/api/v1/guild_member/', {name:name},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function kickGuildMember(name) {
    return Promise.resolve(
        axios.put(api_url+'/api/v1/guild_member_list/', {name:name},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function changeGuildInfo(type, data) {
    return Promise.resolve(
        axios.put(api_url+'/api/v1/guild/', {manage:type, data:data},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}

function donateGuild(money) {
    return Promise.resolve(
        axios.post(api_url+'/api/v1/my_guild/',{money: money},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function manageGuildTown(hp, mp, atk, mag, def, spr, spd) {
    return Promise.resolve(
        axios.post(api_url+'/api/v1/town_manage/', {type:"guard", hp:hp, mp:mp, atk:atk, mag:mag, def:def, spr:spr, spd:spd},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
export {getGuildList, deleteMyApply, getMyApplyList, joinInGuild, getMyMemberData, getGuildInfo, getGuildMemberList,
    outGuild, createGuild, approveApply, getAppliedList, rejectApply, deleteGuild, guildOP_0, guildOP_1, guildOP_2,
    kickGuildMember, changeGuildInfo, donateGuild, manageGuildTown}