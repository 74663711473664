import axios from 'axios'
import {api_url} from "./basic";

function getCharacter() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/character/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getCharacterList() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/characters/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function chooseCharacterSession(character) {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/characters/", {name: character},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function createCharacter(name, sex) {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/character/", {name: name, sex:sex},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function deleteCharacter(character) {
    return Promise.resolve(
        axios.patch(api_url+"/api/v1/character/", {name: character},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function chooseJob(job) {
    return Promise.resolve(
        axios.post(api_url+'/api/v1/job/',{job: job}
            ,{headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function upgradeJob() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/job/'
            ,{headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function resetFreeStat() {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/reset_stat/", {},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function adaptFreeStat(str, ind, agl, spr, mag, luk) {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/free_stat/",
            {str:str, ind:ind, agl:agl, spr:spr, mag:mag, luk:luk},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getEquip() {
    return Promise.resolve(
        axios.get(api_url+"/api/v1/equip/",
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    )
}
function getInven() {
    return Promise.resolve(
        axios.get(api_url+"/api/v1/inventory/",
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    )
}
function throwItem(invenId) {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/inventory/", { inven_id: invenId },
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    )
}
function putOnItem(invenId) {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/equip/", { inven_id: invenId },
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    )
}

export {createCharacter, deleteCharacter, adaptFreeStat, resetFreeStat, getCharacter, getCharacterList,
    chooseJob, upgradeJob, chooseCharacterSession, getEquip, getInven, throwItem, putOnItem}