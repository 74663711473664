import { combineReducers } from "redux";

const character = (state = {value: 'null'}, action)=>{
    switch (action.type) {
        case "CHARACTER":
          return { ...state, value: action.payload };
        default:
          return state;
    }
};

const town = (state = {value: 'null'}, action)=>{
    switch (action.type) {
        case "TOWN":
          return { ...state, value: action.payload };
        default:
          return state;
    }
};

const expired = (state = {value: false}, action)=>{
    switch (action.type) {
        case "EXPIRED":
            return { ...state, value: action.payload };
        default:
            return state;
    }
};

const guild_info = (state = {value: {guild__name:"없음", op:0}}, action)=>{
    switch (action.type) {
        case "GUILD":
            return { ...state, value: action.payload };
        default:
            return state;
    }
};

const battle_log = (state = {value: null}, action)=>{
    switch (action.type) {
        case "BATTLE":
            return { ...state, value: action.payload };
        default:
            return state;
    }
};

const initialState = [
    [null, 1, null, null, 2, 3, null, null, 4],
    [null, 5, 6, 7, null, 8, 9, 10, null],
    [11, null, null, null, 12, null, 13, 14, null],
    [15, 16, null, null, 17, 18, null, 19, null],
    [20, 21, 22, 23, 0, null, 24, 25, 26],
    [null, 27, null, 28, 29, 30, 31, 32, null],
    [null, 33, 34, 35, null, null, 36, null, 37],
    [null, null, 38, 39, null, 40, 41, 42, 43],
    [null, 44, 45, 46, 47, null, 48, null, 49],
]
const town_position = (state = { value: initialState }, action) => {
    switch (action.type) {
        default: return state;
    }
};

const chat_room = (state = {value: "world"}, action)=>{
    switch (action.type) {
        case "CHANNEL":
            return { ...state, value: action.payload };
        default:
            return state;
    }
};

const rootReducer = combineReducers({
    character,
    town,
    expired,
    guild_info,
    battle_log,
    town_position,
    chat_room
});

export default rootReducer;