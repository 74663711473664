import React from "react";

function Intro(props) {
    return (
        <React.Fragment>
            <div className="cover" style={{display: 'flex', alignItems: 'center', overflow: 'hidden', minHeight: '100%', position: 'relative', backgroundColor:"rgba(135, 249, 255, 0.3)"}}>
                <div className="cover-image">{/*<img src="/background.jpg" alt="cover" style={{position:'absolute', top:'0px', Width: '100%', height:'auto'}} />*/}</div>
                <div className="container" style={{maxWidth: '100%'}}>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>에리온</h1><br/>
                            <p style={{fontSize: '21px'}}>남녀노소 언제 어디서나 즐길 수 있는 웹게임, 지금 참여하세요!</p><br/><br/>
                            <button className="btn btn-lg btn-primary" onClick={()=>props.history.push('/login')}>시작하기</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Intro;