import axios from "axios";
import {api_url} from "./basic";

function getPassiveSkillPoints() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/passive_skill/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getActiveSkillPoints() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/active_skill/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getSkillAdaptPoints() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/skill_slot/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}

export {getPassiveSkillPoints, getActiveSkillPoints, getSkillAdaptPoints}