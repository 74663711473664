import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";

function ChatHeader() {
    const cur_channel  = useSelector(state=>state.chat_room).value
    const character = useSelector(state=>state.character).value
    const dispatch = useDispatch()
    const handleChannel = (channel) => {
        if(channel === "world"){
            dispatch({ type: "CHANNEL", payload: "world" })
        }
        else if(channel === "guild"){
            dispatch({ type: "CHANNEL", payload: "guild" })
        }
        else if(channel === "announce"){
            dispatch({ type: "CHANNEL", payload: "announce" })
        }
    }
    useEffect(()=>{
        dispatch({ type: "CHANNEL", payload: "world" })
    },[character.guild, dispatch])
    return (
        <div id="chat" className="col-md-12 text-center"
             style={{height: 60, backgroundColor: "brown", position: "absolute", left: 0, top: 0, zIndex: 10}}>
            {(()=>{
                if(cur_channel === 'world')
                    return(
                        <>
                            <div style={world_active_style} onClick={() => {handleChannel("world")}}>
                                <b>월드</b>
                            </div>
                            {character.guild !== "없음" &&
                            <div style={guild_inactive_style} onClick={() => {handleChannel("guild")}}>
                                <b>길드</b>
                            </div>}
                            <div style={log_inactive_style} onClick={() => {handleChannel("announce")}}>
                                <b>로그</b>
                            </div>
                        </>
                    )
                else if(cur_channel === 'guild')
                    return(
                        <>
                            <div style={world_inactive_style} onClick={() => {handleChannel("world")}}>
                                <b>월드</b>
                            </div>
                            {character.guild !== "없음" &&
                            <div style={guild_active_style} onClick={() => {handleChannel("guild")}}>
                                <b>길드</b>
                            </div>}
                            <div style={log_inactive_style} onClick={() => {handleChannel("announce")}}>
                                <b>로그</b>
                            </div>
                        </>
                    )
                else if(cur_channel === 'announce')
                    return(
                        <>
                            <div style={world_inactive_style} onClick={() => {handleChannel("world")}}>
                                <b>월드</b>
                            </div>
                            {character.guild !== "없음" &&
                            <div style={guild_inactive_style} onClick={() => {handleChannel("guild")}}>
                                <b>길드</b>
                            </div>}
                            <div style={log_active_style} onClick={() => {handleChannel("announce")}}>
                                <b>로그</b>
                            </div>
                        </>
                    )
            })()}

        </div>
    )
}

const world_active_style = {
    border: "1px solid black",
    position: "absolute",
    left: 0,
    bottom: 0,
    borderRadius: "10px 10px 0px 0px",
    paddingTop: "5px",
    width: "35%",
    height: "35px",
    backgroundColor: "white",
    borderBottom: "0px",
    cursor: "Default",
}
const world_inactive_style = {
    border: "1px solid black",
    position: "absolute",
    left: 0,
    bottom: 0,
    borderRadius: "10px 10px 0px 0px",
    paddingTop: "5px",
    width: "35%",
    height: "35px",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderBottom: "1px solid black",
    cursor: "pointer",
}
const guild_active_style = {
    border: "1px solid black",
    position: "absolute",
    left: "35%",
    bottom: 0,
    borderRadius: "10px 10px 0px 0px",
    paddingTop: "5px",
    width: "35%",
    height: "35px",
    backgroundColor: "white",
    borderBottom: "0px",
    cursor: "Default",
}
const guild_inactive_style = {
    border: "1px solid black",
    position: "absolute",
    left: "35%",
    bottom: 0,
    borderRadius: "10px 10px 0px 0px",
    paddingTop: "5px",
    width: "35%",
    height: "35px",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderBottom: "1px solid black",
    cursor: "pointer",
}
const log_active_style = {
    border: "1px solid black",
    position: "absolute",
    left: "70%",
    bottom: 0,
    borderRadius: "10px 10px 0px 0px",
    paddingTop: "5px",
    width: "30%",
    height: "35px",
    backgroundColor: "white",
    borderBottom: "0px",
    cursor: "Default",
}
const log_inactive_style = {
    border: "1px solid black",
    position: "absolute",
    left: "70%",
    bottom: 0,
    borderRadius: "10px 10px 0px 0px",
    paddingTop: "5px",
    width: "30%",
    height: "35px",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderBottom: "1px solid black",
    cursor: "pointer",
}
export default ChatHeader