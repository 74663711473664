import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {errorOccur, joinGuild, applyGuild, cancelGuildApply} from "../../utils/toast";
import { useToasts } from "react-toast-notifications";
import { deleteMyApply, getGuildList, getMyApplyList, getMyMemberData, joinInGuild } from "../../utils/api/guild";
import { getCharacter } from "../../utils/api/character";
import {guildJoinTimeLeft} from "../../utils/toast/guild";

function GuildJoin(props) {
    const character = useSelector(state => state.character)
    const [guilds, setGuilds] = useState([])
    const [applied_list, setAppliedList] = useState([])
    const [tempAction, setTempAction] = useState(null)
    const dispatch = useDispatch()
    const { addToast } = useToasts()

    useLayoutEffect(() => {
        window.jQuery('[data-modal="apply"]').click(function () {
            window.jQuery('#guildApply').modal('show');
        });
        window.jQuery('[data-modal="cancel"]').click(function () {
            window.jQuery('#guildCancel').modal('show');
        });
    })
    useEffect(() => {
        getGuildList()
            .then(res => {
                if (res.data.length !== 0) setGuilds(res.data)
                else setGuilds([])
            })
            .catch(error => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else errorOccur(addToast)
                setGuilds([])
            })
        getMyApplyList()
            .then(res => {
                if (res.data.length !== 0) setAppliedList(res.data)
                else setAppliedList([])
            })
            .catch(error => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else errorOccur(addToast)
                setAppliedList([])
            })
    }, [dispatch, addToast, character])

    const handleSubmit = (guild) => {
        joinInGuild(guild)
            .then((res) => {
                if (res.status === 202) {
                    getCharacter().then(res => { dispatch({ type: 'CHARACTER', payload: res.data }) })
                    getMyMemberData().then(res => dispatch({ type: "GUILD", payload: res.data[0] }))
                    props.history.push('/')
                    joinGuild(addToast, guild)
                }
                else if (res.status === 201) {
                    getCharacter().then(res => { dispatch({ type: 'CHARACTER', payload: res.data }) })
                    applyGuild(addToast, guild)
                }
            })
            .catch(error => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else if (error.response.status === 408) guildJoinTimeLeft(addToast)
                else errorOccur(addToast)
            })
    }
    const handleCancel = (guild) => {
        deleteMyApply(guild)
            .then(() => {
                getCharacter().then(res => { dispatch({ type: 'CHARACTER', payload: res.data }) })
                cancelGuildApply(addToast, guild)
            })
            .catch(error => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else errorOccur(addToast)
                setTempAction(null)
            })
    }

    if (guilds === undefined || applied_list === undefined) {
        return (<>loading</>)
    }
    else {
        return (
            <div className="section">
                <div className="container">
                    <h1 style={{ marginTop: "5%", marginBottom: "30px" }}>길드 가입</h1>
                    <div className="row">
                        <div className="col">
                            <table className="table table-bordered" style={{ verticalAlign: "center" }}>
                                <thead style={{ textAlign: "center" }}>
                                    <tr>
                                        <th style={{ width: "5%" }} />
                                        <th style={{ width: "25%" }}>길드 이름</th>
                                        <th style={{ width: "40%" }}>길드 설명</th>
                                        <th style={{ width: "10%" }}>인원 현황</th>
                                        <th style={{ width: "10%" }}>가입 방식</th>
                                        <th style={{ width: "10%" }}>선택 항목</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {guilds.map((guild, index) => (<>
                                        {(() => {
                                            if (applied_list.includes(guild.name)) {
                                                if (guild.join_type) {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                                                            <td style={{ textAlign: "center" }}>{guild.name}</td>
                                                            <td>{guild.intro}</td>
                                                            <td style={{ textAlign: "center" }}>{ guild.mem } / { guild.maxmem }</td>
                                                            <td style={{ textAlign: "center" }}>승인 가입</td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <button className="btn btn-sm btn-outline-danger" style={{ width: "100%" }}
                                                                    data-modal="cancel" onClick={() => setTempAction(guild.name)}>
                                                                    신청 취소
                                                            </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                } else {
                                                    return (
                                                        <tr>
                                                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                                                            <td style={{ textAlign: "center" }}>{guild.name}</td>
                                                            <td>{guild.intro}</td>
                                                            <td style={{ textAlign: "center" }}>{ guild.mem } / { guild.maxmem }</td>
                                                            <td style={{ textAlign: "center" }}>자유 가입</td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <button className="btn btn-sm btn-outline-primary"
                                                                    style={{ width: "100%" }}
                                                                    data-modal="apply"
                                                                    onClick={() => setTempAction(guild.name)}>
                                                                    가입
                                                            </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            } else if (!(applied_list.includes(guild.name))) {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                                                        <td style={{ textAlign: "center" }}>{guild.name}</td>
                                                        <td>{guild.intro}</td>
                                                        <td style={{ textAlign: "center" }}>{ guild.mem } / { guild.maxmem }</td>
                                                        {(() => {
                                                            if (guild.join_type) {
                                                                return (
                                                                    <>
                                                                        <td style={{ textAlign: "center" }}>승인 가입</td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            <button className="btn btn-sm btn-outline-warning"
                                                                                style={{ width: "100%" }}
                                                                                onClick={() => handleSubmit(guild.name)}>
                                                                                신청
                                                                        </button>
                                                                        </td>
                                                                    </>
                                                                )
                                                            } else {
                                                                return (
                                                                    <>
                                                                        <td style={{ textAlign: "center" }}>자유 가입</td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            <button className="btn btn-sm btn-outline-primary"
                                                                                style={{ width: "100%" }}
                                                                                data-modal="apply"
                                                                                onClick={() => setTempAction(guild.name)}>
                                                                                가입
                                                                        </button>
                                                                        </td>
                                                                    </>
                                                                )
                                                            }
                                                        })()}
                                                    </tr>
                                                )
                                            }
                                        })()}
                                    </>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="modal" id="guildApply" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="guildApply">길드 가입</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>정말 {tempAction} 길드에 가입하시겠습니까?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary" data-dismiss="modal" onClick={() => handleSubmit(tempAction)}>확인</button>
                                <button type="button" className="btn btn-outline-danger" data-dismiss="modal" onClick={() => setTempAction()}>취소</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="guildCancel" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="guildCancel">길드 가입신청 취소</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>정말 {tempAction} 길드의 가입신청을 취소하시겠습니까?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary" data-dismiss="modal" onClick={() => handleCancel(tempAction)}>확인</button>
                                <button type="button" className="btn btn-outline-danger" data-dismiss="modal" onClick={() => setTempAction()}>취소</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default GuildJoin
