import React, {useEffect, useState} from "react";
import {getPercentData, jobCounter} from "../../utils/character";
import {useSelector} from "react-redux";
import {getEquip} from "../../utils/api/character";

function ChrInfo(props) {
    const character = useSelector(state=>state.character).value
    const [equip, setEquip] = useState(['','','','','',''])
    useEffect(() => {
        getEquip().then(res => {setEquip(res.data)})
    }, []);
    const marginTop3 = {
        marginTop: "3%"
    }
    const floatRight={
        float:"right"
    }
    const {max_exp, hp, mp, exp} = getPercentData(character)
    return(
        <div className="section">
            <div className="container" style={marginTop3}>
                <div align="right" className="inline">
                    <button className="btn btn-warning" type="button" onClick={()=>{props.history.push('/chr/stat')}}
                            style={floatRight}>자유 스탯
                    </button>
                </div>
                <h1>{character.name}</h1>
                <div className="row">
                    <div className="col-md-8" style={{marginTop: "1%", marginLeft:"auto", marginRight:"auto"}}>HP
                        <div className="progress">
                            <div className="progress-bar bg-danger" role="progressbar" style={{width: hp}}/>
                            <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                                color="white"><b>{character.hp} / {character.max_hp}</b></font></div>
                        </div>
                        MP
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: mp}} />
                            <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                                color="white"><b>{character.mp} / {character.max_mp}</b></font></div>
                        </div>
                        경험치
                        <div className="progress">
                            <div className="progress-bar bg-warning" role="progressbar" style={{width: exp}}/>
                            <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                                color="white"><b>{character.exp} / {max_exp}</b></font></div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <table className="table" style={{textAlign:"center"}}>
                            <tbody>
                            <tr>
                                <th width="100"><img src="/icon/attack.png" alt="attack icon" title="공격력"/></th>
                                <th width="100"><img src="/icon/magic.png" alt="magic icon" title="마력"/></th>
                                <th width="100"><img src="/icon/defend.png" alt="defend icon" title="방어력"/></th>
                                <th width="100"><img src="/icon/mag_defend.png" alt="mag_defend icon" title="마법저항력"/></th>
                                <th width="100"><img src="/icon/speed.png" alt="speed icon" title="속도"/></th>
                                <th width="100"><img src="/icon/critical.png" alt="critical icon" title="치명타율"/></th>
                            </tr>
                            <tr>
                                <td>{character.attack}</td>
                                <td>{character.magic}</td>
                                <td>{character.defend}</td>
                                <td>{character.mag_defend}</td>
                                <td>{character.speed}</td>
                                <td>{parseFloat(character.critical).toFixed(1)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <h3>스테이터스</h3>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <table className="table">
                            <tbody>
                            <tr>
                                <th width="100">직업</th>
                                <td width="150">{jobCounter(character.job, character.job_class)}</td>
                                <th width="100">레벨</th>
                                <td width="150">{character.lev}</td>
                                <th width="100">경험치</th>
                                <td width="150">{character.exp}</td>
                            </tr>
                            <tr>
                                <th width="100">강인</th>
                                <td width="150">{character.str}</td>
                                <th width="100">단련</th>
                                <td width="150">{character.ind}</td>
                                <th width="100">민첩</th>
                                <td width="150">{character.agl}</td>
                            </tr>
                            <tr>
                                <th width="100">정신</th>
                                <td width="150">{character.spr}</td>
                                <th width="100">마력</th>
                                <td width="150">{character.mag}</td>
                                <th width="100">행운</th>
                                <td width="150">{character.luk}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <h3>장비</h3>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <table className="table">
                            <tbody>
                            <tr>
                                <th width="100">무기</th>
                                <td width="150">{equip[0].name}</td>
                                <th width="100">보조 무기</th>
                                <td width="150">{equip[1].name}</td>
                                <th width="100">방어구</th>
                                <td width="150">{equip[2].name}</td>
                            </tr>
                            <tr>
                                <th width="100">목걸이</th>
                                <td width="150">{equip[3].name}</td>
                                <th width="100">팔찌</th>
                                <td width="150">{equip[4].name}</td>
                                <th width="100">반지</th>
                                <td width="150">{equip[5].name}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export  default ChrInfo