import axios from "axios";
import {api_url} from "./basic";

function takeRest() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/rest',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getTown() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/town/',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function moveTown(move_to) {
    return Promise.resolve(
        axios.post(api_url+"/api/v1/town/", {target_town:move_to},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function checkBankMoney() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/bank',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function moveBankMoney(type, money) {
    return Promise.resolve(
        axios.post(api_url+'/api/v1/bank/',{type:type, money:money},
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getConquerInfo() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/guild_town',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}})
    );
}
function getItemList() {
    return Promise.resolve(
        axios.get(api_url+'/api/v1/shop',
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}}
        )
    )
}
function takeItem(itemId) {
    return Promise.resolve(
        axios.post(api_url+'/api/v1/shop/', { item_id: itemId },
            {headers: {Authorization: `jwt ${localStorage.getItem('token')}`}}
        )
    )
}

export {takeRest, getTown, moveTown, checkBankMoney, moveBankMoney, getConquerInfo, getItemList, takeItem}