import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Page from "react-page-loading";
import {actionTimeLeft, errorOccur, townMoved} from "../../utils/toast";
import {useToasts} from "react-toast-notifications";
import {getCharacter} from "../../utils/api/character";
import {moveTown} from "../../utils/api/town";

function TownMove(props) {
    const towns = useSelector(state=>state.town).value
    const { addToast } = useToasts()
    const [value, setValue] = useState(towns[1]['id']);
    const dispatch = useDispatch()
    const handleAdapt = () => {
        moveTown(value)
            .then(res=>{
                dispatch({type:'TOWN', payload:res.data})
                getCharacter().then(res=>{dispatch({type:'CHARACTER', payload:res.data})})
                townMoved(addToast, res.data[0]["nameKorean"])
                props.history.push('/')
            })
            .catch(error=>{
                if (error.response.status === 401) dispatch({type: 'EXPIRED', payload: true})
                else if(error.response.status===417) actionTimeLeft(addToast, error.response.data)
                else errorOccur(addToast)
            })
    }
    const townPosition = useSelector((state) => state.town_position).value;
    const [edge, setEdge] = useState(null);

    useEffect(() => {
        let edge = {};
        for (let j = 0; j < towns.length; j++) {
          edge[towns[j].id] = towns[j].nameKorean;
        }
        setEdge(edge);
    }, [towns]);

    if (towns === "null")
        return(
            <Page loader={"bar"} color={"#A9A9A9"} size={4}>
                <div> Loading </div>
            </Page>)
    else
    return(
        <div className="section">
            <div className="container">
                <br/><br/><br/>
                <h1>지역 이동</h1>
                <br/>
                <h4 style={{textAlign: 'center'}}>현재 위치{" "}
                    <img src="/navigation.png" alt="navigation" style={{width: '1.5rem'}}></img>
                </h4>
                <div className="row">
                    <div className="col-md-8" style={{textAlign: 'center', marginLeft:'auto', marginRight:'auto', padding: "0"}}>
                        <img src="/map.png" className="img-responsive" style={{width: '100%', height: '100%', left: '0%', position: 'absolute'}} alt="map"/>
                        {townPosition.map((floor, id) => (
                            <div key={id}>
                                {floor.map((room, id) => (
                                    <span
                                        key={id}
                                        data-modal="townRoom"
                                        style={{
                                            position: "relative",
                                            border: "1px solid transparent",
                                            width: "11.11%",
                                            paddingBottom: "10.7%",
                                            float: "left",
                                        }}
                                        onClick={() => {
                                            setValue(room);
                                            if (towns[0].id !== room && edge[room]) {
                                                return window.jQuery("#moveTown").modal("show");
                                            }
                                        }}                                        
                                    >
                                        {edge && edge[room] && towns[0].id !== room ? (
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    border: "0.85vmin solid black",
                                                    top: "9%",
                                                    left: "9%",
                                                    width: "82%",
                                                    height: "82%",
                                                    cursor: 'pointer'
                                                }}
                                            ></span>
                                        ) : null}
                                        {towns[0].id === room ? (
                                            <img
                                                src="/navigation.png"
                                                alt=""
                                                style={{
                                                    position: "absolute",
                                                    width: "1.5rem",
                                                    transform: "translate(-50%)",
                                                }}
                                            ></img>
                                        ) : null}
                                    </span>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="modal" id="moveTown" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">마을 이동</h5>
                            </div>
                            <div className="modal-body">
                               <p>{edge ? edge[value] : null}(으)로 이동하시겠습니까?</p>
                            </div>
                           <div className="modal-footer">
                                <button
                                    className="btn btn-outline-primary"
                                    data-dismiss="modal"
                                    onClick={() => {
                                        handleAdapt();
                                    }}
                                >
                                    확인
                                </button>
                                <button className="btn btn-outline-danger" data-dismiss="modal">
                                    취소
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TownMove