import React, {useState, useLayoutEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { jobCounter } from "../../utils/character";
import { errorOccur, jobChanged, jobLevelLow, jobUpgraded } from "../../utils/toast";
import { useToasts } from "react-toast-notifications";
import { getCharacter, chooseJob, upgradeJob } from "../../utils/api/character";

function JobClass(props) {
    const character = useSelector(state => state.character).value
    const [tempAction, setTempAction] = useState()
    const dispatch = useDispatch()
    const { addToast } = useToasts()

    useLayoutEffect(() => {
        window.jQuery('[data-modal="firstSubmit"]').click(function () {
            window.jQuery('#firstSubmit').modal('show');
        });
        window.jQuery('[data-modal="jobSubmit"]').click(function () {
            window.jQuery('#jobSubmit').modal('show');
        });
    })

    const firstSubmit = (job) => {
        chooseJob(job)
            .then(() => {
                jobChanged(addToast, jobCounter(job, 1))
                getCharacter().then(res => { dispatch({ type: "CHARACTER", payload: res.data }) })
                props.history.push('/')
            })
            .catch(error => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else if (error.response.status === 428) jobLevelLow(addToast)
                else errorOccur(addToast)
            })
    }
    const jobSubmit = () => {
        upgradeJob()
            .then(() => {
                jobUpgraded(addToast, jobCounter(character.job, character.job_class + 1), character.job_class + 1)
                getCharacter().then(res => { dispatch({ type: "CHARACTER", payload: res.data }) })
                props.history.push('/')
            })
            .catch(error => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else if (error.response.status === 428) jobLevelLow(addToast)
                else errorOccur(addToast)
            })
    }
    if (character.job !== "없음")
        return (
            <div className="section">
                <div className='container' style={{ textAlign: "center" }}>
                    <h1 style={{ marginTop: '30px' }}>전직</h1>
                    <div className="row" style={{ marginTop: "50px", marginBottom: "5%" }}>
                        <div className="col-md-12" style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <h3 style={{ marginBottom: "20px" }}>{character.job_class + 1}차 전직</h3>
                            <button style={{ padding: "0" }} data-modal="jobSubmit" onClick={() => setTempAction(jobCounter(character.job, character.job_class + 1))}>
                                <img src="/placeholder.png" width="150" height="200" alt="char_image" />
                            </button>
                            <h2>{jobCounter(character.job, character.job_class + 1)}</h2>
                        </div>
                    </div>
                </div>
                <div className="modal" id="jobSubmit" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="jobSubmit">전직</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>정말 {tempAction}(으)로 전직하시겠습니까?</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-outline-primary" data-dismiss="modal" onClick={() => jobSubmit()}>확인</button>
                                <button className="btn btn-outline-danger" data-dismiss="modal" onClick={() => setTempAction()}>취소</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    else
        return (
            <div className="section">
                <div className='container' style={{ textAlign: "center" }}>
                    <h1 style={{ marginTop: '30px' }}>전직</h1>
                    <div className="row" style={{ marginTop: "50px", marginBottom: "5%" }}>
                        <div className="col-md-auto" style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <h3 style={{ marginBottom: "20px" }}>투사</h3>
                            <button style={{ padding: "0" }} data-modal="firstSubmit" onClick={() => setTempAction("투술")}>
                                <img src="/placeholder.png" width="150" height="200" alt="char_image" />
                            </button>
                            <h5 style={{ marginTop: "10px" }}>특징</h5>
                            <p>높은 공격력</p>
                            <p>강한 물리피해 위주의 전투</p>
                        </div>
                        <div className="col-md-auto" style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <h3 style={{ marginBottom: "20px" }}>기사</h3>
                            <button style={{ padding: "0" }} data-modal="firstSubmit" onClick={() => setTempAction("검술")}>
                                <img src="/placeholder.png" width="150" height="200" alt="char_image" />
                            </button>
                            <h5 style={{ marginTop: "10px" }}>특징</h5>
                            <p>높은 내구력</p>
                            <p>내구력을 기반한 안정적 전투</p>
                        </div>
                        <div className="col-md-auto" style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <h3 style={{ marginBottom: "20px" }}>궁수</h3>
                            <button style={{ padding: "0" }} data-modal="firstSubmit" onClick={() => setTempAction("궁술")}>
                                <img src="/placeholder.png" width="150" height="200" alt="char_image" />
                            </button>
                            <h5 style={{ marginTop: "10px" }}>특징</h5>
                            <p>높은 치명타확률과 데미지</p>
                            <p>강한 치명타를 활용한 전투</p>
                        </div>
                        <div className="col-md-auto" style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <h3 style={{ marginBottom: "20px" }}>자객</h3>
                            <button style={{ padding: "0" }} data-modal="firstSubmit" onClick={() => setTempAction("암술")}>
                                <img src="/placeholder.png" width="150" height="200" alt="char_image" />
                            </button>
                            <h5 style={{ marginTop: "10px" }}>특징</h5>
                            <p>높은 속도</p>
                            <p>높은 속도와 CC기를 이용한 전투</p>
                        </div>
                        <div className="col-md-auto" style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <h3 style={{ marginBottom: "20px" }}>법사</h3>
                            <button style={{ padding: "0" }} data-modal="firstSubmit" onClick={() => setTempAction("마술")}>
                                <img src="/placeholder.png" width="150" height="200" alt="char_image" />
                            </button>
                            <h5 style={{ marginTop: "10px" }}>특징</h5>
                            <p>높은 마력</p>
                            <p>강력한 마법스킬을 이용한 전투</p>
                        </div>
                        <div className="col-md-auto" style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <h3 style={{ marginBottom: "20px" }}>술사</h3>
                            <button style={{ padding: "0" }} data-modal="firstSubmit" onClick={() => setTempAction("주술")}>
                                <img src="/placeholder.png" width="150" height="200" alt="char_image" />
                            </button>
                            <h5 style={{ marginTop: "10px" }}>특징</h5>
                            <p>높은 마법저항력</p>
                            <p>보조스킬을 이용한 안정적인 전투</p>
                        </div>
                    </div>
                </div>
                <div className="modal" id="firstSubmit" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="firstSubmit">전직 선택</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>정말 {tempAction}(으)로 전직하시겠습니까?</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-outline-primary" data-dismiss="modal" onClick={() => firstSubmit(tempAction)}>확인</button>
                                <button className="btn btn-outline-danger" data-dismiss="modal" onClick={() => setTempAction()}>취소</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default JobClass