function skillCheck(skill_list, skill_name, initial, character) {
    for (let i = 0; i < skill_list.length; i++)
        for (let j = 0; j < skill_list[i].length; j++)
            if (skill_list[i][j].title === skill_name)
                return [
                    skill_list[i][j].id,
                    skill_list[i][j].img_path,
                    skill_list[i][j].structure +
                    getSkillInfo(skill_list[i][j].name, initial[i][j], character),
                ];
}

function isUndefined(array, skill_list, initial, character) {
    for (let i = 0; i < array.length; i++) {
        if (array[i].title === undefined) array[i].title = null;
        else if (array[i].title === "") array[i].title = null;
        else if (array[i].title !== undefined && array[i].title !== null) {
            let array_result = skillCheck(
                skill_list,
                array[i].title,
                initial,
                character
            );
            array[i].id = array_result[0];
            array[i].img_path = array_result[1];
            array[i].description = array_result[2];
        }
        if (array[i].per === undefined) array[i].per = 0;
        else if (array[i].per === null) array[i].per = 0;
        else if (array[i].per === "") array[i].per = 0;
    }
    return array;
}

export function initialSlotList(slots, skill_list, initial, adapt, character) {
    return isUndefined(
        [
            {
                id: "",
                per: null,
                img_path: "/emptySkill.png",
                title: slots[0].slot_first,
                description: "",
            },
            {
                id: "",
                per: slots[0].percent_slot_1,
                img_path: "/emptySkill.png",
                title: slots[0].slot_1,
                description: "",
            },
            {
                id: "",
                per: slots[0].percent_slot_2,
                img_path: "/emptySkill.png",
                title: slots[0].slot_2,
                description: "",
            },
            {
                id: "",
                per: slots[0].percent_slot_3,
                img_path: "/emptySkill.png",
                title: slots[0].slot_3,
                description: "",
            },
            {
                id: "",
                per: slots[0].percent_slot_4,
                img_path: "/emptySkill.png",
                title: slots[0].slot_4,
                description: "",
            },
            {
                id: "",
                per: slots[0].percent_slot_5,
                img_path: "/emptySkill.png",
                title: slots[0].slot_5,
                description: "",
            },
            {
                id: "",
                per: slots[0].percent_slot_6,
                img_path: "/emptySkill.png",
                title: slots[0].slot_6,
                description: "",
            },
        ],
        skill_list,
        initial,
        adapt,
        character
    );
}

function getSkillDamage(name, lev, character) {
    if (lev === 0) return 0
    if (name === "강타") return parseInt(100 + 40 * lev + 0.5 * character.attack)

    else if (name==="후려치기") return parseInt(330 + 100 * lev + 0.6 * character.attack)
    else if (name==="내려찍기") return parseInt(280 + 100 * lev + 0.5 * character.attack)
    else if (name==="포효") return 0
    else if (name==="발구르기") return parseInt(175 + 175 * lev + 0.6 * character.attack)
    else if (name==="숄더태클") return parseInt(300 + 200 * lev + 0.7 * character.attack)
    else if (name==="공포의일격") return parseInt(250 + 250 * lev + 0.8 * character.attack)
    else if (name==="쪼개기") return parseInt(250 + 200 * lev + 0.7 * character.attack)
    else if (name==="분노의외침") return 0
    else if (name==="난타") return parseInt(400 + 400 * lev + 0.9 * character.attack)
    else if (name==="견뎌내기") return 0

    else if (name==="슬래쉬") return parseInt(350 + 80 * lev + 0.6 * character.attack)
    else if (name==="찌르기") return parseInt(230 + 100 * lev + 0.6 * character.attack)
    else if (name==="방패올리기") return 0
    else if (name==="방패가격") return parseInt(175 + 175 * lev + 0.6 * character.attack)
    else if (name==="기간트슬래쉬") return parseInt(550 + 250 * lev + 0.8 * character.attack)
    else if (name==="파이어슬래쉬") return parseInt(170 + 230 * lev + 0.7 * character.attack)
    else if (name==="왕국검법1식") return parseInt(100 + 300 * lev + 0.8 * character.attack)
    else if (name==="마나브레이크") return parseInt(425 + 175 * lev + 0.7 * character.attack)
    else if (name==="수호의방패") return 0
    else if (name==="페이탈슬래쉬") return parseInt(666 + 333 * lev + 0.9 * character.attack)

    else if (name==="속사") return parseInt(350 + 80 * lev + 0.6 * character.attack)
    else if (name==="파이어애로우") return parseInt(230 + 100 * lev + 0.6 * character.attack)
    else if (name==="스나이핑") return parseInt(720 + 280 * lev + 0.8 * character.attack)
    else if (name==="아이스애로우") return parseInt(175 + 175 * lev + 0.6 * character.attack)
    else if (name==="윈드애로우") return parseInt(120 + 280 * lev + 0.7 * character.attack)
    else if (name==="사격태세") return 0
    else if (name==="발리스타") return parseInt(300 + 500 * lev + 0.9 * character.attack)
    else if (name==="스파크애로우") return parseInt(228 + 222 * lev + 0.7 * character.attack)
    else if (name==="신경독") return 0
    else if (name==="피어싱애로우") return parseInt(244 + 222 * lev + 0.7 * character.attack)
    else if (name==="엄폐물설치") return 0

    else if (name==="올가미투척") return parseInt(180 + 100 * lev + 0.5 * character.attack)
    else if (name==="표창난사") return parseInt(130 + 100 * lev + 0.6 * character.attack)
    else if (name==="연막탄") return 0
    else if (name==="신경독침") return parseInt(300 + 150 * lev + 0.6 * character.attack)
    else if (name==="기류차단") return parseInt(355 + 145 * lev + 0.6 * character.attack)
    else if (name==="전광석화") return 0
    else if (name==="그림자침식") return parseInt(175 + 175 * lev + 0.6 * character.attack)
    else if (name==="습격") return parseInt(150 + 250 * lev + 0.7 * character.attack)
    else if (name==="그림자수리검") return parseInt(100 + 300 * lev + 0.8 * character.attack)
    else if (name==="패닉어택") return parseInt(200 + 250 * lev + 0.7 * character.attack)
    else if (name==="그림자숨기") return 0
    else if (name==="백스탭") return parseInt(400 + 200 * lev + 0.7 * character.attack)
    else if (name==="안개장막") return 0

    else if (name==="파이어볼") return parseInt(350 + 80 * lev + 0.6 * character.magic)
    else if (name==="아이스볼트") return parseInt(180 + 100 * lev + 0.5 * character.magic)
    else if (name==="라이트닝볼트") return parseInt(230 + 100 * lev + 0.6 * character.magic)
    else if (name==="스톤스피어") return parseInt(170 + 230 * lev + 0.7 * character.magic)
    else if (name==="강풍") return parseInt(170 + 230 * lev + 0.7 * character.magic)
    else if (name==="혹한") return parseInt(750 + 250 * lev + 0.8 * character.magic)
    else if (name==="이그니션") return parseInt(120 + 180 * lev + 0.6 * character.magic)
    else if (name==="썬더볼트") return parseInt(375 + 325 * lev + 0.8 * character.magic)
    else if (name==="샐러맨더") return parseInt(250 + 250 * lev + 0.8 * character.magic)
    else if (name==="섬광") return parseInt(250 + 200 * lev + 0.7 * character.magic)
    else if (name==="순간동결") return 0
    else if (name==="크리스탈스피어") return parseInt(720 + 280 * lev + 0.9 * character.magic)
    else if (name==="마나배리어") return 0

    else if (name==="가르기") return parseInt(230 + 100 * lev + 0.6 * character.magic)
    else if (name==="룬스트라이크") return parseInt(350 + 80 * lev + 0.6 * character.magic)
    else if (name==="자연의은혜") return 0
    else if (name==="뿌리묶기") return 0
    else if (name==="암석화") return 0
    else if (name==="재생") return parseInt(200 * lev + 1 * character.magic)
    else if (name==="에너지볼") return parseInt(270 + 230 * lev + 0.7 * character.magic)
    else if (name==="가시덤불") return parseInt(400 + 200 * lev + 0.8 * character.magic)
    else if (name==="조여오는덩굴") return 0
    else if (name==="치유의안개") return parseInt(250 + 50 * lev + 1.2 * character.magic)
    else if (name==="스피릿서지") return parseInt(700 + 300 * lev + 0.9 * character.magic)
}
function getSkillCC(name, lev) {
    if (name==="내려찍기") return "15% 확률로 적 공격력 10% 감소"
    else if (name==="포효") return "100% 확률로 자신 공격력 "+String(2*lev)+"% 증가"
    else if (name==="발구르기") return "20% 확률로 적 속도 10% 감소"
    else if (name==="쪼개기") return "25% 확률로 적 방어력 10% 감소"
    else if (name==="분노의외침") return "100% 확률로 자신 속도 "+String(3*lev)+"% 증가"
    else if (name==="견뎌내기") return "100% 확률로 받는 피해 "+String(3*lev)+"% 감소"

    else if (name==="방패올리기") return "100% 확률로 자신 방어력 "+String(2*lev)+"% 증가"
    else if (name==="방패가격") return "20% 확률로 적 속도 10% 감소"
    else if (name==="마나브레이크") return "25% 확률로 적 마력 10% 감소"
    else if (name==="수호의방패") return "100% 확률로 마법저항력 "+String(3*lev)+"% 증가"

    else if (name==="올가미투척") return "15% 확률로 적 속도 10% 감소"
    else if (name==="연막탄") return "100% 확률로 자신 회피율 "+String(2*lev)+"% 증가"
    else if (name==="신경독침") return "20% 확률로 적 공격력 10%  감소"
    else if (name==="기류차단") return "20% 확률로 적 마법 10% 감소"
    else if (name==="전광석화") return "100% 확률로 자신 속도 "+String(2*lev)+"% 증가"
    else if (name==="그림자침식") return "20% 확률로 적 마법저항력 10% 감소"
    else if (name==="패닉어택") return "25% 확률로 적 마나 소모량 20% 증가"
    else if (name==="그림자숨기") return "100% 확률로 마법저항력 "+String(3*lev)+"% 증가"
    else if (name==="백스탭") return "25% 확률로 적 방어력 10% 감소"
    else if (name==="안개장막") return "100% 확률로 받는피해 "+String(3*lev)+"% 감소"

    else if (name==="아이스애로우") return "20% 확률로 적 속도 10% 감소"
    else if (name==="사격태세") return "100% 확률로 적 회피율 "+String(4*lev)+"% 감소"
    else if (name==="스파크애로우") return "25% 확률로 적 속도 10% 감소"
    else if (name==="신경독") return "100% 확률로 적 공격력 "+String(3*lev)+"% 감소"
    else if (name==="피어싱애로우") return "25% 확률로 적 방어력 10% 감소"
    else if (name==="엄폐물설치") return "100% 확률로 받는 피해 "+String(3*lev)+"% 감소"

    else if (name==="아이스볼트") return "15% 확률로 적 속도 10% 감소"
    else if (name==="이그니션") return "20% 확률로 적 공격력 10% 감소"
    else if (name==="섬광") return "25% 확률로 적 회피율 20% 감소"
    else if (name==="순간동결") return "100% 확률로 적 속도 "+String(3*lev)+"% 감소"
    else if (name==="마나배리어") return "100% 확률로 받는 피해 "+String(3*lev)+"% 감소"

    else if (name==="자연의은혜") return "100% 확률로 자신과 파티원 마력 "+String(2*lev)+"% 증가"
    else if (name==="뿌리묶기") return "100% 확률로 상대 속도 "+String(2*lev)+"% 감소"
    else if (name==="암석화") return "100% 확률로 자신과 파티원 방어력 "+String(2*lev)+"% 증가"
    else if (name==="재생") return "자신과 파티원 HP 회복"
    else if (name==="조여오는덩굴") return "100% 확률로 적 회피율 "+String(4*lev)+"% 감소"
    else return "없음"
}
function getSkillMP(name) {
    if (name === "강타") return 100

    else if (name === "후려치기") return 160
    else if (name === "내려찍기") return 200
    else if (name === "포효") return 480
    else if (name === "발구르기") return 300
    else if (name === "숄더태클") return 240
    else if (name === "공포의일격") return 320
    else if (name === "쪼개기") return 400
    else if (name === "분노의외침") return 640
    else if (name === "난타") return 480
    else if (name === "견뎌내기") return 800

    else if (name === "슬래쉬") return 200
    else if (name === "찌르기") return 200
    else if (name === "방패올리기") return 600
    else if (name === "방패가격") return 375
    else if (name === "기간트슬래쉬") return 450
    else if (name === "파이어슬래쉬") return 300
    else if (name === "왕국검법1식") return 400
    else if (name === "마나브레이크") return 500
    else if (name === "수호의방패") return 800
    else if (name === "페이탈슬래쉬") return 600

    else if (name === "속사") return 200
    else if (name === "파이어애로우") return 200
    else if (name === "스나이핑") return 450
    else if (name === "아이스애로우") return 375
    else if (name === "윈드애로우") return 300
    else if (name === "사격태세") return 600
    else if (name === "발리스타") return 600
    else if (name === "스파크애로우") return 500
    else if (name === "신경독") return 800
    else if (name === "피어싱애로우") return 500
    else if (name === "엄폐물설치") return 1000

    else if (name === "올가미투척") return 200
    else if (name === "표창난사") return 160
    else if (name === "연막탄") return 320
    else if (name === "신경독침") return 300
    else if (name === "기류차단") return 300
    else if (name === "전광석화") return 480
    else if (name === "그림자침식") return 300
    else if (name === "습격") return 240
    else if (name === "그림자수리검") return 320
    else if (name === "패닉어택") return 400
    else if (name === "그림자숨기") return 640
    else if (name === "백스탭") return 400
    else if (name === "안개장막") return 800

    else if (name === "파이어볼") return 300
    else if (name === "아이스볼트") return 375
    else if (name === "라이트닝볼트") return 300
    else if (name === "스톤스피어") return 450
    else if (name === "강풍") return 450
    else if (name === "혹한") return 675
    else if (name === "이그니션") return 563
    else if (name === "썬더볼트") return 675
    else if (name === "샐러맨더") return 600
    else if (name === "섬광") return 750
    else if (name === "순간동결") return 1200
    else if (name === "크리스탈스피어") return 900
    else if (name === "마나배리어") return 1500

    else if (name === "가르기") return 200
    else if (name === "룬스트라이크") return 200
    else if (name === "자연의은혜") return 600
    else if (name === "뿌리묶기") return 600
    else if (name === "암석화") return 600
    else if (name === "재생") return 600
    else if (name === "에너지볼") return 300
    else if (name === "가시덤불") return 400
    else if (name === "조여오는덩굴") return 800
    else if (name === "치유의안개") return 800
    else if (name === "스피릿서지") return 600
}
function getSkillCool(name){
    if (name === "강타") return 3

    else if (name === "후려치기") return 3
    else if (name === "내려찍기") return 3
    else if (name === "포효") return 4
    else if (name === "발구르기") return 10
    else if (name === "숄더태클") return 4
    else if (name === "공포의일격") return 3
    else if (name === "쪼개기") return 4
    else if (name === "분노의외침") return 10
    else if (name === "난타") return 5
    else if (name === "견뎌내기") return 99

    else if (name === "슬래쉬") return 3
    else if (name === "찌르기") return 3
    else if (name === "방패올리기") return 10
    else if (name === "방패가격") return 4
    else if (name === "기간트슬래쉬") return 5
    else if (name === "파이어슬래쉬") return 3
    else if (name === "왕국검법1식") return 3
    else if (name === "마나브레이크") return 4
    else if (name === "수호의방패") return 10
    else if (name === "페이탈슬래쉬") return 5

    else if (name === "속사") return 3
    else if (name === "파이어애로우") return 3
    else if (name === "스나이핑") return 5
    else if (name === "아이스애로우") return 4
    else if (name === "윈드애로우") return 3
    else if (name === "사격태세") return 10
    else if (name === "발리스타") return 5
    else if (name === "스파크애로우") return 4
    else if (name === "신경독") return 10
    else if (name === "피어싱애로우") return 4
    else if (name === "엄폐물설치") return 99

    else if (name === "올가미투척") return 4
    else if (name === "표창난사") return 3
    else if (name === "연막탄") return 10
    else if (name === "신경독침") return 4
    else if (name === "기류차단") return 4
    else if (name === "전광석화") return 10
    else if (name === "그림자침식") return 4
    else if (name === "습격") return 3
    else if (name === "그림자수리검") return 3
    else if (name === "패닉어택") return 4
    else if (name === "그림자숨기") return 10
    else if (name === "백스탭") return 4
    else if (name === "안개장막") return 99

    else if (name === "파이어볼") return 3
    else if (name === "아이스볼트") return 4
    else if (name === "라이트닝볼트") return 3
    else if (name === "스톤스피어") return 3
    else if (name === "강풍") return 3
    else if (name === "혹한") return 5
    else if (name === "이그니션") return 4
    else if (name === "썬더볼트") return 5
    else if (name === "샐러맨더") return 3
    else if (name === "섬광") return 4
    else if (name === "순간동결") return 10
    else if (name === "크리스탈스피어") return 5
    else if (name === "마나배리어") return 99

    else if (name === "가르기") return 3
    else if (name === "룬스트라이크") return 3
    else if (name === "자연의은혜") return 6
    else if (name === "뿌리묶기") return 6
    else if (name === "암석화") return 4
    else if (name === "재생") return 10
    else if (name === "에너지볼") return 3
    else if (name === "가시덤불") return 3
    else if (name === "조여오는덩굴") return 6
    else if (name === "치유의안개") return 10
    else if (name === "스피릿서지") return 5
}
function getSkillInfo(name, lev, character){
    return ('<br/>위력: '+getSkillDamage(name,lev, character)+"<br/>효과: "+getSkillCC(name, lev)+"<br/> 소모MP: "+getSkillMP(name)+"<br/> 쿨타임: "+getSkillCool(name))
}

function getSum(skilltree) {
    skilltree.id = 0;
    let sum = 0;
    for (let key in skilltree) {
        sum += skilltree[key]
    }
    return sum
}

const passive_skill_list = {
    공용: {
        class0: [
            {
                name: '사냥꾼',
                value: '몹에게 주는 데미지 #4% 증가',
                imgAlt: "common_class0_1",
                imgSrc: "/skills/common/passive/1-1.png"
            },
            {
                name: '적응',
                value: '몹에게 받는 데미지 #4% 감소',
                imgAlt: "common_class0_2",
                imgSrc: "/skills/common/passive/1-2.png"
            },
            {
                name: '재기',
                value: '몹에게 주는 데미지의 #4% 만큼 HP 회복',
                imgAlt: "common_class0_3",
                imgSrc: "/skills/common/passive/1-3.png"
            },
            {
                name: '통찰',
                value: '몹에게 주는 데미지의 #10% 만큼 MP 회복',
                imgAlt: "warrior_class0_3",
                imgSrc: "/skills/common/passive/1-4.png"
            },
        ]
    },
    투술: {
        class1: [
            {
                name: '천부적인 힘',
                value: '공격력 #1% 증가',
                imgAlt: "warrior_class1_1",
                imgSrc: "/skills/warrior/passive/1-1.png"
            },
            {
                name: '싸움꾼',
                value: '가한 데미지 #2% 증가',
                imgAlt: "warrior_class1_2",
                imgSrc: "/skills/warrior/passive/1-2.png"
            },
        ],
        class2: [
            {
                name: '적개심',
                value: '공격력 #2% 증가',
                imgAlt: "warrior_class2_1",
                imgSrc: "/skills/warrior/passive/2-1.png"
            },
            {
                name: '위협',
                value: '상대 공격력 #2% 감소',
                imgAlt: "warrior_class2_2",
                imgSrc: "/skills/warrior/passive/2-2.png"
            },
        ],
        class3: [
            {
                name: '분노',
                value: '공격력 #3% 증가,\n받는 데미지 ##1% 증가',
                imgAlt: "warrior_class3_1",
                imgSrc: "/skills/warrior/passive/3-1.png"
            },
            {
                name: '돌격태세',
                value: '속도 #2% 증가',
                imgAlt: "warrior_class3_2",
                imgSrc: "/skills/warrior/passive/3-2.png"
            },
            {
                name: '결투가',
                value: '회피율 #4% 증가',
                imgAlt: "warrior_class3_3",
                imgSrc: "/skills/warrior/passive/3-3.png"
            },
        ]
    },
    검술: {
        class1: [
            {
                name: '끈기',
                value: '받는 물리데미지 #2% 감소',
                imgAlt: "knight_class1_1",
                imgSrc: "/skills/knight/passive/1-1.png"
            },
            {
                name: '강인함',
                value: '방어력 #2% 증가',
                imgAlt: "knight_class1_2",
                imgSrc: "/skills/knight/passive/1-2.png"
            },
        ],
        class2: [
            {
                name: '굳건한 의지',
                value: '마법저항력 #2% 증가',
                imgAlt: "knight_class2_1",
                imgSrc: "/skills/knight/passive/2-1.png"
            },
            {
                name: '방패 막기',
                value: '#2%의 확률로 적의 공격을 막음',
                imgAlt: "knight_class2_2",
                imgSrc: "/skills/knight/passive/2-2.png"
            },
        ],
        class3: [
            {
                name: '왕국검법 초식',
                value: '공격력 #3%만큼 증가,\n받는 데미지 ##1%만큼 증가',
                imgAlt: "knight_class3_1",
                imgSrc: "/skills/knight/passive/3-1.png"
            },
            {
                name: '막아서기',
                value: '상대 회피율 #4%만큼 감소',
                imgAlt: "knight_class3_2",
                imgSrc: "/skills/knight/passive/3-2.png"
            },
            {
                name: '경량화',
                value: '착용중인 장비의 무게 #5%만큼 감소',
                imgAlt: "knight_class3_3",
                imgSrc: "/skills/knight/passive/3-3.png"
            },
        ]
    },
    암술: {
        class1: [
            {
                name: '암살 개시',
                value: '가한 데미지 #2% 증가',
                imgAlt: "theif_class1_1",
                imgSrc: "/skills/thief/passive/1-1.png"
            },
            {
                name: '신속',
                value: '속도 #2% 증가',
                imgAlt: "theif_class1_2",
                imgSrc: "/skills/thief/passive/1-2.png"
            },
        ],
        class2: [
            {
                name: '약점 간파',
                value: '치명타 확률 #3% 증가',
                imgAlt: "theif_class2_1",
                imgSrc: "/skills/thief/passive/2-1.png"
            },
            {
                name: '민첩함',
                value: '회피율 #3% 증가',
                imgAlt: "theif_class2_2",
                imgSrc: "/skills/thief/passive/2-2.png"
            },
        ],
        class3: [
            {
                name: '계략',
                value: '디버프 효과 발동률 #1% 증가',
                imgAlt: "theif_class3_1",
                imgSrc: "/skills/thief/passive/3-1.png"
            },
            {
                name: '비껴맞기',
                value: '받는 물리데미지 #3% 감소,\n속도 ##1% 감소',
                imgAlt: "theif_class3_2",
                imgSrc: "/skills/thief/passive/3-2.png"
            },
            {
                name: '살기',
                value: '상대 마력 #2% 감소',
                imgAlt: "theif_class3_3",
                imgSrc: "/skills/thief/passive/3-3.png"
            },
        ]
    },
    궁술: {
        class1: [
            {
                name: '예리한 화살촉',
                value: '치명타 데미지 #8% 증가',
                imgAlt: "archer_class1_1",
                imgSrc: "/skills/archer/passive/1-1.png"
            },
            {
                name: '집중',
                value: '치명타 확률 #4% 증가',
                imgAlt: "archer_class1_2",
                imgSrc: "/skills/archer/passive/1-2.png"
            },
        ],
        class2: [
            {
                name: '매의 눈',
                value: '상대 회피율 #3% 감소',
                imgAlt: "archer_class2_1",
                imgSrc: "/skills/archer/passive/2-1.png"
            },
            {
                name: '방어 관통',
                value: '상대 방어력 #2% 감소',
                imgAlt: "archer_class2_2",
                imgSrc: "/skills/archer/passive/2-2.png"
            },
        ],
        class3: [
            {
                name: '심호흡',
                value: '가한 데미지 #3% 증가,\n속도 ##1% 감소',
                imgAlt: "archer_class3_1",
                imgSrc: "/skills/archer/passive/3-1.png"
            },
            {
                name: '사주경계',
                value: '회피율 #4% 증가',
                imgAlt: "archer_class3_2",
                imgSrc: "/skills/archer/passive/3-2.png"
            },
            {
                name: '약점 공략',
                value: '치명타 데미지 #8% 증가',
                imgAlt: "archer_class3_3",
                imgSrc: "/skills/archer/passive/3-3.png"
            },
        ]
    },
    마술: {
        class1: [
            {
                name: '마법의 기초',
                value: '스킬 피해량 #1% 증가',
                imgAlt: "magician_class1_1",
                imgSrc: "/skills/magician/passive/1-1.png"
            },
            {
                name: '학문 수양',
                value: '마력 #2% 증가',
                imgAlt: "magician_class1_2",
                imgSrc: "/skills/magician/passive/1-2.png"
            },
        ],
        class2: [
            {
                name: '프로텍션',
                value: '방어력 #2% 증가',
                imgAlt: "magician_class2_1",
                imgSrc: "/skills/magician/passive/2-1.png"
            },
            {
                name: '익스팬딩',
                value: '스킬 피해량을 #2% 증가시킨다',
                imgAlt: "magician_class2_2",
                imgSrc: "/skills/magician/passive/2-2.png"
            },
        ],
        class3: [
            {
                name: '정신 붕괴',
                value: '상대 마법저항력 #3% 감소,\n받는 데미지 ##1% 증가',
                imgAlt: "magician_class3_1",
                imgSrc: "/skills/magician/passive/3-1.png"
            },
            {
                name: '래피드 캐스팅',
                value: '속도 #2% 증가',
                imgAlt: "magician_class3_2",
                imgSrc: "/skills/magician/passive/3-2.png"
            },
            {
                name: '마음의 눈',
                value: '치명타 확률 #3% 증가',
                imgAlt: "magician_class3_3",
                imgSrc: "/skills/magician/passive/3-3.png"
            },
        ]
    },
    주술: {
        class1: [
            {
                name: '정령 계약',
                value: '버프스킬 효과량 #4% 증가',
                imgAlt: "druid_class1_1",
                imgSrc: "/skills/druid/passive/1-1.png"
            },
            {
                name: '마력 융화',
                value: '마력 #2% 증가',
                imgAlt: "druid_class1_2",
                imgSrc: "/skills/druid/passive/1-2.png"
            },
        ],
        class2: [
            {
                name: '파고드는 마력',
                value: '상대 마법저항력 #2% 감소',
                imgAlt: "druid_class2_1",
                imgSrc: "/skills/druid/passive/2-1.png"
            },
            {
                name: '심록의 아우라',
                value: '상대 마력 #2% 감소',
                imgAlt: "druid_class2_2",
                imgSrc: "/skills/druid/passive/2-2.png"
            },
        ],
        class3: [
            {
                name: '나무껍질 갑옷',
                value: '방어력 #2% 증가',
                imgAlt: "druid_class3_1",
                imgSrc: "/skills/druid/passive/3-1.png"
            },
            {
                name: '자연의 활력',
                value: '속도 #2% 증가',
                imgAlt: "druid_class3_2",
                imgSrc: "/skills/druid/passive/3-2.png"
            },
            {
                name: '정령의 분노',
                value: '마력 #3% 증가, 받는 데미지 ##1% 증가',
                imgAlt: "druid_class3_3",
                imgSrc: "/skills/druid/passive/3-3.png"
            },
        ]
    },
}

export const active_skill_list = {
    없음: [
        [
            {
                id: "a0_1",
                per: 0,
                img_path: "/skills/common/active/1-1.png",
                title: "강타",
                structure: "물리 피해",
            },
        ],
    ],
    투술: [
        [
            {
                id: "a0_1",
                per: 0,
                img_path: "/skills/common/active/1-1.png",
                title: "강타",
                structure: "물리 피해",
            },
        ],

        [
            {
                id: "a1_1",
                per: 0,
                img_path: "/skills/warrior/active/1-1.png",
                title: "후려치기",
                structure: "물리 피해",
            },
            {
                id: "a1_2",
                per: 0,
                img_path: "/skills/warrior/active/1-2.png",
                title: "내려찍기",
                structure: "물리 피해",
            },
        ],

        [
            {
                id: "a2_1",
                per: 0,
                img_path: "/skills/warrior/active/2-1.png",
                title: "포효",
                structure: "버프",
            },
            {
                id: "a2_2",
                per: 0,
                img_path: "/skills/warrior/active/2-2.png",
                title: "발구르기",
                structure: "물리 피해",
            },
            {
                id: "a2_3",
                per: 0,
                img_path: "/skills/warrior/active/2-3.png",
                title: "숄더태클",
                structure: "물리 피해",
            },
        ],

        [
            {
                id: "a3_1",
                per: 0,
                img_path: "/skills/warrior/active/3-1.png",
                title: "공포의일격",
                structure: "마법 피해",
            },
            {
                id: "a3_2",
                per: 0,
                img_path: "/skills/warrior/active/3-2.png",
                title: "쪼개기",
                structure: "물리 피해",
            },
            {
                id: "a3_3",
                per: 0,
                img_path: "/skills/warrior/active/3-3.png",
                title: "분노의외침",
                structure: "버프",
            },
            {
                id: "a3_4",
                per: 0,
                img_path: "/skills/warrior/active/3-4.png",
                title: "난타",
                structure: "물리 피해",
            },
            {
                id: "a3_5",
                per: 0,
                img_path: "/skills/warrior/active/3-5.png",
                title: "견뎌내기",
                structure: "버프",
            },
        ],
    ],
    검술: [
        [
            {
                id: "a0_1",
                per: 0,
                img_path: "/skills/common/active/1-1.png",
                title: "강타",
                structure: "물리 피해",
            },
        ],

        [
            {
                id: "a1_1",
                per: 0,
                img_path: "/skills/knight/active/1-1.png",
                title: "슬래쉬",
                structure: "물리 피해",
            },
            {
                id: "a1_2",
                per: 0,
                img_path: "/skills/knight/active/1-2.png",
                title: "찌르기",
                structure: "물리 피해",
            },
        ],

        [
            {
                id: "a2_1",
                per: 0,
                img_path: "/skills/knight/active/2-1.png",
                title: "방패올리기",
                structure: "버프",
            },
            {
                id: "a2_2",
                per: 0,
                img_path: "/skills/knight/active/2-2.png",
                title: "방패가격",
                structure: "물리 피해",
            },
            {
                id: "a2_3",
                per: 0,
                img_path: "/skills/knight/active/2-3.png",
                title: "기간트슬래쉬",
                structure: "물리 피해",
            },
            {
                id: "a2_4",
                per: 0,
                img_path: "/skills/knight/active/2-4.png",
                title: "파이어슬래쉬",
                structure: "마법 피해",
            },
        ],

        [
            {
                id: "a3_1",
                per: 0,
                img_path: "/skills/knight/active/3-1.png",
                title: "왕국검법1식",
                structure: "물리 피해",
            },
            {
                id: "a3_2",
                per: 0,
                img_path: "/skills/knight/active/3-2.png",
                title: "마나브레이크",
                structure: "마법 피해",
            },
            {
                id: "a3_3",
                per: 0,
                img_path: "/skills/knight/active/3-3.png",
                title: "수호의방패",
                structure: "버프",
            },
            {
                id: "a3_4",
                per: 0,
                img_path: "/skills/knight/active/3-4.png",
                title: "페이탈슬래쉬",
                structure: "물리 피해",
            },
        ],
    ],
    마술: [
        [
            {
                id: "a0_1",
                per: 0,
                img_path: "/skills/common/active/1-1.png",
                title: "강타",
                structure: "물리 피해",
            },
        ],

        [
            {
                id: "a1_1",
                per: 0,
                img_path: "/skills/magician/active/1-1.png",
                title: "파이어볼",
                structure: "마법 피해",
            },
            {
                id: "a1_2",
                per: 0,
                img_path: "/skills/magician/active/1-2.png",
                title: "아이스볼트",
                structure: "마법 피해",
            },
            {
                id: "a1_3",
                per: 0,
                img_path: "/skills/magician/active/1-3.png",
                title: "라이트닝볼트",
                structure: "마법 피해",
            },
        ],

        [
            {
                id: "a2_1",
                per: 0,
                img_path: "/skills/magician/active/2-1.png",
                title: "스톤스피어",
                structure: "물리 피해",
            },
            {
                id: "a2_2",
                per: 0,
                img_path: "/skills/magician/active/2-2.png",
                title: "강풍",
                structure: "마법 피해",
            },
            {
                id: "a2_3",
                per: 0,
                img_path: "/skills/magician/active/2-3.png",
                title: "혹한",
                structure: "마법 피해",
            },
            {
                id: "a2_4",
                per: 0,
                img_path: "/skills/magician/active/2-4.png",
                title: "이그니션",
                structure: "마법 피해",
            },
            {
                id: "a2_5",
                per: 0,
                img_path: "/skills/magician/active/2-5.png",
                title: "썬더볼트",
                structure: "마법 피해",
            },
        ],

        [
            {
                id: "a3_1",
                per: 0,
                img_path: "/skills/magician/active/3-1.png",
                title: "샐러맨더",
                structure: "마법 피해",
            },
            {
                id: "a3_2",
                per: 0,
                img_path: "/skills/magician/active/3-2.png",
                title: "섬광",
                structure: "마법 피해",
            },
            {
                id: "a3_3",
                per: 0,
                img_path: "/skills/magician/active/3-3.png",
                title: "순간동결",
                structure: "디버프",
            },
            {
                id: "a3_4",
                per: 0,
                img_path: "/skills/magician/active/3-4.png",
                title: "크리스탈스피어",
                structure: "물리 피해",
            },
            {
                id: "a3_5",
                per: 0,
                img_path: "/skills/magician/active/3-5.png",
                title: "마나배리어",
                structure: "버프",
            },
        ],
    ],
    궁술: [
        [
            {
                id: "a0_1",
                per: 0,
                img_path: "/skills/common/active/1-1.png",
                title: "강타",
                structure: "물리 피해",
            },
        ],

        [
            {
                id: "a1_1",
                per: 0,
                img_path: "/skills/archer/active/1-1.png",
                title: "속사",
                structure: "물리 피해",
            },
            {
                id: "a1_2",
                per: 0,
                img_path: "/skills/archer/active/1-2.png",
                title: "파이어애로우",
                structure: "마법 피해",
            },
        ],

        [
            {
                id: "a2_1",
                per: 0,
                img_path: "/skills/archer/active/2-1.png",
                title: "스나이핑",
                structure: "물리 피해",
            },
            {
                id: "a2_2",
                per: 0,
                img_path: "/skills/archer/active/2-2.png",
                title: "아이스애로우",
                structure: "마법 피해",
            },
            {
                id: "a2_3",
                per: 0,
                img_path: "/skills/archer/active/2-3.png",
                title: "윈드애로우",
                structure: "물리 피해",
            },
            {
                id: "a2_4",
                per: 0,
                img_path: "/skills/archer/active/2-4.png",
                title: "사격태세",
                structure: "디버프",
            },
        ],

        [
            {
                id: "a3_1",
                per: 0,
                img_path: "/skills/archer/active/3-1.png",
                title: "발리스타",
                structure: "물리 피해",
            },
            {
                id: "a3_2",
                per: 0,
                img_path: "/skills/archer/active/3-2.png",
                title: "스파크애로우",
                structure: "마법 피해",
            },
            {
                id: "a3_3",
                per: 0,
                img_path: "/skills/archer/active/3-3.png",
                title: "신경독",
                structure: "디버프",
            },
            {
                id: "a3_4",
                per: 0,
                img_path: "/skills/archer/active/3-4.png",
                title: "피어싱애로우",
                structure: "물리 피해",
            },
            {
                id: "a3_5",
                per: 0,
                img_path: "/skills/archer/active/3-5.png",
                title: "엄폐물설치",
                structure: "버프",
            },
        ],
    ],
    암술: [
        [
            {
                id: "a0_1",
                per: 0,
                img_path: "/skills/common/active/1-1.png",
                title: "강타",
                structure: "물리 피해",
            },
        ],

        [
            {
                id: "a1_1",
                per: 0,
                img_path: "/skills/thief/active/1-1.png",
                title: "올가미투척",
                structure: "물리 피해",
            },
            {
                id: "a1_2",
                per: 0,
                img_path: "/skills/thief/active/1-2.png",
                title: "표창난사",
                structure: "물리 피해",
            },
            {
                id: "a1_3",
                per: 0,
                img_path: "/skills/thief/active/1-3.png",
                title: "연막탄",
                structure: "버프",
            },
        ],

        [
            {
                id: "a2_1",
                per: 0,
                img_path: "/skills/thief/active/2-1.png",
                title: "신경독침",
                structure: "물리 피해",
            },
            {
                id: "a2_2",
                per: 0,
                img_path: "/skills/thief/active/2-2.png",
                title: "기류차단",
                structure: "마법 피해",
            },
            {
                id: "a2_3",
                per: 0,
                img_path: "/skills/thief/active/2-3.png",
                title: "전광석화",
                structure: "버프",
            },
            {
                id: "a2_4",
                per: 0,
                img_path: "/skills/thief/active/2-4.png",
                title: "그림자침식",
                structure: "마법 피해",
            },
            {
                id: "a2_5",
                per: 0,
                img_path: "/skills/thief/active/2-5.png",
                title: "습격",
                structure: "물리 피해",
            },
        ],

        [
            {
                id: "a3_1",
                per: 0,
                img_path: "/skills/thief/active/3-1.png",
                title: "그림자수리검",
                structure: "마법 피해",
            },
            {
                id: "a3_2",
                per: 0,
                img_path: "/skills/thief/active/3-2.png",
                title: "패닉어택",
                structure: "물리 피해",
            },
            {
                id: "a3_3",
                per: 0,
                img_path: "/skills/thief/active/3-3.png",
                title: "그림자숨기",
                structure: "버프",
            },
            {
                id: "a3_4",
                per: 0,
                img_path: "/skills/thief/active/3-4.png",
                title: "백스탭",
                structure: "물리 피해",
            },
            {
                id: "a3_5",
                per: 0,
                img_path: "/skills/thief/active/3-5.png",
                title: "안개장막",
                structure: "버프",
            },
        ],
    ],
    주술: [
        [
            {
                id: "a0_1",
                per: 0,
                img_path: "/skills/common/active/1-1.png",
                title: "강타",
                structure: "물리 피해",
            },
        ],

        [
            {
                id: "a1_1",
                per: 0,
                img_path: "/skills/druid/active/1-1.png",
                title: "가르기",
                structure: "물리 피해",
            },
            {
                id: "a1_2",
                per: 0,
                img_path: "/skills/druid/active/1-2.png",
                title: "룬스트라이크",
                structure: "마법 피해",
            },
        ],

        [
            {
                id: "a2_1",
                per: 0,
                img_path: "/skills/druid/active/2-1.png",
                title: "자연의은혜",
                structure: "버프",
            },
            {
                id: "a2_2",
                per: 0,
                img_path: "/skills/druid/active/2-2.png",
                title: "뿌리묶기",
                structure: "디버프",
            },
            {
                id: "a2_3",
                per: 0,
                img_path: "/skills/druid/active/2-3.png",
                title: "암석화",
                structure: "버프",
            },
            {
                id: "a2_4",
                per: 0,
                img_path: "/skills/druid/active/2-4.png",
                title: "재생",
                structure: "회복",
            },
            {
                id: "a2_5",
                per: 0,
                img_path: "/skills/druid/active/2-5.png",
                title: "에너지볼",
                structure: "마법 피해",
            },
        ],

        [
            {
                id: "a3_1",
                per: 0,
                img_path: "/skills/druid/active/3-1.png",
                title: "가시덤불",
                structure: "물리 피해",
            },
            {
                id: "a3_2",
                per: 0,
                img_path: "/skills/druid/active/3-2.png",
                title: "조여오는덩굴",
                structure: "디버프",
            },
            {
                id: "a3_3",
                per: 0,
                img_path: "/skills/druid/active/3-3.png",
                title: "치유의안개",
                structure: "회복",
            },
            {
                id: "a3_4",
                per: 0,
                img_path: "/skills/druid/active/3-4.png",
                title: "스피릿서지",
                structure: "마법 피해",
            },
        ],
    ],
};

export const empty_skill_slot_list = [
    {
        id: null,
        per: 0,
        img_path: "/emptySkill.png",
        title: null,
        description: "",
    },
    {
        id: null,
        per: 0,
        img_path: "/emptySkill.png",
        title: null,
        description: "",
    },
    {
        id: null,
        per: 0,
        img_path: "/emptySkill.png",
        title: null,
        description: "",
    },
    {
        id: null,
        per: 0,
        img_path: "/emptySkill.png",
        title: null,
        description: "",
    },
    {
        id: null,
        per: 0,
        img_path: "/emptySkill.png",
        title: null,
        description: "",
    },
    {
        id: null,
        per: 0,
        img_path: "/emptySkill.png",
        title: null,
        description: "",
    },
    {
        id: null,
        per: 0,
        img_path: "/emptySkill.png",
        title: null,
        description: "",
    },
];


const auto_center = {
    marginLeft:"auto",
    marginRight:"auto",
    width:"300px",
}
const skill_icon ={
    width:"75px",
    height:"75px",
    marginLeft:"auto",
    marginRight:"auto",
}
const button={
    width:"25px",
    height:"25px",
    padding:"0px",
    textAlign:"center"
}
const column_style={
    float:"left",
    textAlign: "center",
    padding:'20px'
}

export {getSkillInfo, skillCheck, isUndefined, getSum, auto_center, button, column_style, skill_icon, passive_skill_list}