function getPercentData(character) {
    const hp = (character.hp/character.max_hp*100)+"%"
    const mp = (character.mp/character.max_mp*100)+"%"
    const max_exp = Math.pow(character.lev + 2, 3)
    const exp = (character.exp/max_exp*100)+"%"
    return {hp,mp,max_exp,exp}
}

function jobCounter(job, job_class) {
    if(job ==='없음')
        return '초심자'
    else if(job ==='근위대')
        return '직속'
    else if(job ==='무패전사')
        return '무패전사'
    else if(job ==='투술'){
        if (job_class===1)
            return '투사'
        else if (job_class===2)
            return '브루저'
        else if (job_class===3)
            return '스트라이커'
        else if (job_class===4)
            return '크림슨'
        else if (job_class===5)
            return '버서커'
    }
    else if(job ==='검술'){
        if (job_class===1)
            return '기사'
        else if (job_class===2)
            return '세이버'
        else if (job_class===3)
            return '포트리스'
        else if (job_class===4)
            return '팔라딘'
        else if (job_class===5)
            return '로얄가드'
    }
    else if(job ==='궁술'){
        if (job_class===1)
            return '궁수'
        else if (job_class===2)
            return '레인저'
        else if (job_class===3)
            return '샤프슈터'
        else if (job_class===4)
            return '발키리'
        else if (job_class===5)
            return '셀레스테'
    }
    else if(job ==='암술'){
        if (job_class===1)
            return '도적'
        else if (job_class===2)
            return '어쌔신'
        else if (job_class===3)
            return '팬텀'
        else if (job_class===4)
            return '다크로드'
        else if (job_class===5)
            return '데스브링어'
    }
    else if(job ==='마술'){
        if (job_class===1)
            return '법사'
        else if (job_class===2)
            return '소서러'
        else if (job_class===3)
            return '워록'
        else if (job_class===4)
            return '아크메이지'
        else if (job_class===5)
            return '엘리멘탈마스터'
    }
    else if(job ==='주술'){
        if (job_class===1)
            return '술사'
        else if (job_class===2)
            return '드루이드'
        else if (job_class===3)
            return '우드가드'
        else if (job_class===4)
            return '센티넬'
        else if (job_class===5)
            return '네이쳐가디언'
    }
    return '버그이용자(문의)'
}

export {jobCounter, getPercentData}