import React, {useContext, useEffect, useRef, useState} from 'react'
import { WebSocketContext } from './websocketProvider';
import {useSelector} from "react-redux";
import {smallLogType} from "../../utils/log";

function Chatting() {
    const ws = useContext(WebSocketContext);
    const scroll = useRef();
    const cur_channel  = useSelector(state=>state.chat_room).value
    const [items, setItems] = useState([]);
    const [guildChat, setGuildChat] = useState([]);
    const [announce, setAnnounce] = useState([]);
    const character = useSelector(state=>state.character).value

    const addItem = (item) => {
        setItems([
            ...items,
            item
        ]);
    };
    const addGuildChat = (item) => {
        setGuildChat([
            ...guildChat,
            item
        ]);
    };
    const addAnnounce = (item) => {
        setAnnounce([
            ...announce,
            item
        ]);
    };
    const scrollToBottom = () => {
        scroll.current.scrollIntoView({
            block: "start",
        });
    };
    if (character.guild !== "없음") {
        ws['guild'].current.onmessage = (evt) => {
            const data = JSON.parse(evt.data);
            addGuildChat(data);
            scrollToBottom()
        };
    }
    ws['world'].current.onmessage = (evt) => {
        const data = JSON.parse(evt.data);
        addItem(data);
        scrollToBottom()
    };
    ws['announce'].current.onmessage = (evt) => {
        const data = JSON.parse(evt.data);
        addAnnounce(data);
        scrollToBottom()
    };
    useEffect(()=>{
        setGuildChat([])
    },[character.guild])
    if(cur_channel === "world")
        return (
            <div id="chat" className="col-md-12" style={{paddingBottom:60, paddingTop: 60, display:"inline-block", overflowY:"scroll", maxHeight:"100%"}}>
                <div className="col" style={{position: "absolute", left: 0, top: 60, zIndex: 12}} >
                    <div className="row" style={{padding: 0}}>
                        {announce.length>0 ?
                            <div className="col-12 text-center" style={{backgroundColor: "#C7C7C7"}}>
                                {smallLogType(announce[announce.length-1]['log_type'])} &nbsp;
                                {announce[announce.length-1]['log']}
                            </div>
                            :<></>}
                    </div>
                </div>
                {
                    items.map((message, index) => {
                        if(message['user'] === character.name)
                            return (
                                <div key={index}  className="row" style={{padding: 5}}>
                                    <div className="col-12 text-right" style={{paddingRight:20}}>
                                        {message['user']}
                                    </div>
                                    <div className="text-right"
                                         style={{borderRadius: 10, marginLeft: "auto", marginRight: 20, padding: 5, paddingRight:10, paddingLeft:10,
                                             maxWidth:"90%", wordWrap:"break-word", backgroundColor: "orange", boxShadow: "3px 3px 3px gray"}}
                                    >
                                        {message['message']}
                                    </div>
                                </div>
                            )
                        else
                            return (
                                <div key={index}  className="row" style={{padding: 5}}>
                                    <div className="col-12" style={{paddingLeft:20}}>
                                        {message['user']}
                                    </div>
                                    <div
                                        style={{borderRadius: 10, marginLeft: 20, marginRight: "auto", padding: 5, paddingRight:10, paddingLeft:10,
                                            maxWidth:"90%", wordWrap:"break-word",backgroundColor: "#dee2e6", boxShadow: "3px 3px 3px gray"}}
                                    >
                                        {message['message']}
                                    </div>
                                </div>
                            )
                    })
                }
                <div ref={scroll} className="list-bottom"/>
            </div>
        )
    else if(cur_channel === "guild")
        return (
            <div id="chat" className="col-md-12" style={{paddingBottom:60, paddingTop: 60, display:"inline-block", overflowY:"scroll", maxHeight:"100%"}}>
                <div className="col" style={{position: "absolute", left: 0, top: 60, zIndex: 12}} >
                    <div className="row" style={{padding: 0}}>
                        {announce.length>0 ?
                            <div className="col-12 text-center" style={{backgroundColor: "#C7C7C7"}}>
                                {smallLogType(announce[announce.length-1]['log_type'])} &nbsp;
                                {announce[announce.length-1]['log']}
                            </div>
                            :<></>}
                    </div>
                </div>
                {
                    guildChat.map((message, index) => {
                        if(message['user'] === character.name)
                            return (
                                <div key={index} className="row" style={{padding: 5}}>
                                    <div className="col-12 text-right" style={{paddingRight:20}}>
                                        {message['user']}
                                    </div>
                                    <div className="text-right"
                                         style={{borderRadius: 10, marginLeft: "auto", marginRight: 20, padding: 5, paddingRight:10, paddingLeft:10,
                                             maxWidth:"90%", wordWrap:"break-word", backgroundColor: "orange", boxShadow: "3px 3px 3px gray"}}
                                    >
                                        {message['message']}
                                    </div>
                                </div>
                            )
                        else
                            return (
                                <div key={index} className="row" style={{padding: 5}}>
                                    <div className="col-12" style={{paddingLeft:20}}>
                                        {message['user']}
                                    </div>
                                    <div
                                        style={{borderRadius: 10, marginLeft: 20, marginRight: "auto", padding: 5, paddingRight:10, paddingLeft:10,
                                            maxWidth:"90%", wordWrap:"break-word",backgroundColor: "#dee2e6", boxShadow: "3px 3px 3px gray"}}
                                    >
                                        {message['message']}
                                    </div>
                                </div>
                            )
                    })
                }
                <div ref={scroll} className="list-bottom"/>
            </div>
        )
    else if(cur_channel === "announce")
        return (
            <div id="chat" className="col-md-12" style={{paddingBottom:60, paddingTop: 60, display:"inline-block", overflowY:"scroll", maxHeight:"100%"}}>
                {
                    announce.map((message, index) => {
                        return (
                            <div key={index} className="row" style={{padding: 2}}>
                                <div className="col-12 text-center" style={{paddingRight:20, backgroundColor: "#C7C7C7",marginTop:5, height:"40"}}>
                                    {smallLogType(message['log_type'])} &nbsp;
                                    {message['log']}
                                </div>
                            </div>
                        )
                    })
                }
                <div ref={scroll} className="list-bottom"/>
            </div>
        )
}

export default Chatting