import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEquip, getInven, putOnItem, throwItem, getCharacter } from "../../utils/api/character";
import { typeFilter, tablePaging, pageIndexing, instrType, itemGrade } from '../../utils/item'
import { useToasts } from "react-toast-notifications";
import { jobCounter } from "../../utils/character";
import {
    noChoose,
    equipSuccess,
    notFoundItem,
    differntJob,
    shortOfLevel,
    dumpSuccess,
    notMine,
    wearItem,
} from "../../utils/toast";

export default function ChrInvt(props) {
    const character = useSelector(state=>state.character).value
    const [isEquipLoading, setIsEquipLoading] = useState(true)
    const [equip, setEquip] = useState()
    const [isInvenLoading, setIsInvenLoading] = useState(true)
    const [inven, setInven] = useState()
    const [itemList, setItemList] = useState()
    const [choosedItem, setChoosedItem] = useState()
    const dispatch = useDispatch()
    const { addToast } = useToasts()
    const tableSize = useState(10)[0]
    const [listNum, setListNum] = useState(0);
    const [pageIndex, setPageIndex] = useState()
    const [pageNum, setPageNum] = useState(0);
    const [hoverTable, setHoverTable] = useState(false)
    function equipItem(item) {
        setIsInvenLoading(true)
        setIsEquipLoading(true)
        putOnItem(item.inven_id)
            .then(res => {
                equipSuccess(addToast, item.name)
                // dispatch({ type: null, payload: null })
                getCharacter().then(res => dispatch({ type: "CHARACTER", payload: res.data }))
                getEquip()
                    .then((res) => {
                        setEquip(res.data)
                        setIsEquipLoading(false)
                    })
                getInven()
                    .then((res) => {
                        setInven(res.data.inven)
                        const pages = tablePaging(res.data.inven, tableSize)
                        setItemList(pages)
                        setPageIndex(pageIndexing(pages.length))
                        setIsInvenLoading(false)
                    })
            })
            .catch(err => {
                setIsInvenLoading(false)
                setIsEquipLoading(false)
                if (err.response.status === 500) notFoundItem(addToast)
                if (err.response.status === 406) notFoundItem(addToast)
                if (err.response.status === 405) differntJob(addToast)
                if (err.response.status === 412) shortOfLevel(addToast)

            })
    }
    function dumpItem() {
        setIsInvenLoading(true)
        throwItem(choosedItem.inven_id)
            .then(res => {
                setChoosedItem()
                dumpSuccess(addToast, choosedItem.name)
                // useDispatch({ type: null, payload: null })
                getInven()
                    .then((res) => {
                        setInven(res.data.inven)
                        const pages = tablePaging(res.data.inven, tableSize)
                        setItemList(pages)
                        setPageIndex(pageIndexing(pages.length))
                        setIsInvenLoading(false)
                    })
                setChoosedItem()
            })
            .catch(err => {
                setIsInvenLoading(false)
                if (err.response.status === 500) notMine(addToast)
                if (err.response.status === 412) wearItem(addToast)
            })
    }

    useEffect(() => {
        getEquip()
            .then((res) => {
                setEquip(res.data)
                setIsEquipLoading(false)
            })
        getInven()
            .then((res) => {
                setInven(res.data.inven)
                const pages = tablePaging(res.data.inven, tableSize)
                setItemList(pages)
                setPageIndex(pageIndexing(pages.length))
                setIsInvenLoading(false)
            })
    }, [tableSize]);

    useLayoutEffect(()=> {
        window.jQuery('[data-toggle="popover"]').popover({
            animation: true,
            container: "body",
            html: true,
            placement: "right",
            trigger: "hover"
        })
    })

    const hp = (character.hp / character.max_hp) * 100 + "%";
    const mp = (character.mp / character.max_mp) * 100 + "%";
    return (
        <div className="container">
            <br />
            <h3>인벤토리</h3>
            <p className="h5">장비 및 아이템을 관리할 수 있습니다.</p>
            <br />
            <div className="row" style={window.innerWidth < 800 ? { paddingBottom: '40px' } : null}>
                <div className="col-md-6 align-self-start" style={styles.statBar}>
                    <h4 className="text-right">{jobCounter(character.job, character.job_class)}</h4>
                    <h4 className="text-right" style={{marginBottom:"10px"}}>Lv. {character.lev}</h4>
                    <b>HP</b>
                    <div className="progress">
                        <div className="progress-bar bg-danger" role="progressbar" style={{width: hp}}/>
                        <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                            color="white"><b>{character.hp} / {character.max_hp}</b></font></div>
                    </div>

                    <b>MP</b>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar"  style={{width: mp}}/>
                        <div className="justify-content-center d-flex position-absolute w-100 align-self-center">
                            <font color="white"><b>{character.mp} / {character.max_mp}</b></font></div>
                    </div>
                    <div className="row">
                        <span className="col-sm-8" style={{ paddingTop: 5 }}>
                            <table className="table">
                                <tbody>
                                <tr>
                                    <th style={{padding:5}}><img src="/icon/attack.png" alt="attack icon" title="공격력"/></th>
                                    <td style={{padding:5}} className="text-center">{character.attack}</td>
                                    <th style={{padding:5}}><img src="/icon/magic.png" alt="attack icon" title="마력"/></th>
                                    <td style={{padding:5}} className="text-center">{character.magic}</td>
                                </tr>
                                <tr>
                                    <th style={{padding:5}}><img src="/icon/defend.png" alt="attack icon" title="방어력"/></th>
                                    <td style={{padding:5}} className="text-center">{character.defend}</td>
                                    <th style={{padding:5}}><img src="/icon/mag_defend.png" alt="attack icon" title="마법저항력"/></th>
                                    <td style={{padding:5}} className="text-center">{character.mag_defend}</td>
                                </tr>
                                <tr>
                                    <th style={{padding:5}}><img src="/icon/speed.png" alt="attack icon" title="속도"/></th>
                                    <td style={{padding:5}} className="text-center">{character.speed}</td>
                                    <th style={{padding:5}}/>
                                    <td style={{padding:5}}/>
                                </tr>
                                </tbody>
                        </table>
                            </span>
                        <span className="col-sm-4" style={{ paddingTop: 5, paddingBottom: 5 }}>
                            <button
                                type="button"
                                className="btn btn-info col"
                                onClick={() => props.history.push('/chr/info')}
                                style={styles.statBtn}
                            >
                                자유스탯
                            </button>
                        </span>
                    </div>
                </div>


                <div className="col-md-6 border" style={{ padding: 0 }}>
                    <table className="table table-bordered" style={{ textAlign: 'center', margin: 0 }}>
                        <thead>
                        <tr>
                            <th scope="col" width="30%">장비 분류</th>
                            <th scope="col">장비명</th>
                        </tr>
                        </thead>
                        <tbody>
                        {isEquipLoading ? (
                            <tr>
                                <th>...loading</th>
                            </tr>
                        ) : (
                            equip.map((item, index) => (
                                <tr key={index}>
                                    <th>{instrType(item.instr_type, item.job)}</th>
                                    <th
                                        style={{ textAlign: 'left' }}
                                        data-toggle="popover"
                                        data-content={`
                        직업: ${jobCounter(item.job, 1)}<br />
                        등급: ${itemGrade(item.grade)}<br />
                        부위: ${instrType(item.instr_type, item.job)}<br />
                        ${item.attack !== 0 ? `공격력: ${item.attack}<br />` : ''}
                        ${item.magic !== 0 ? `마력: ${item.magic}<br />` : ''}
                        ${item.defend !== 0 ? `방어력: ${item.defend}<br />` : ''}
                        ${item.spirit !== 0 ? `정신력: ${item.spirit}<br />` : ''}
                        ${item.weight !== 0 ? `무게: ${item.weight}<br />` : ''}
                      `}
                                    >
                                        {item.name}
                                    </th>
                                </tr>
                            ))
                        )}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="row" style={{ marginTop: 2, position: 'relative' }}>
                <div className="list-group list-group-horizontal" style={styles.listGroup}>
                    <button
                        type="button"
                        className="list-group-item list-group-item-action"
                        style={styles.listGroupBtn}
                        onClick={() => {
                            setPageNum(0)
                            setListNum(0)
                            const pages = tablePaging(typeFilter(inven, 'equipment'), tableSize)
                            setItemList(pages)
                            setPageIndex(pageIndexing(pages.length))
                        }}
                    >
                        장비
                    </button>
                    <button
                        type="button"
                        className="list-group-item list-group-item-action"
                        style={styles.listGroupBtn}
                        onClick={() => {
                            setPageNum(0)
                            setListNum(0)
                            const pages = tablePaging(typeFilter(inven, 'consumable'), tableSize)
                            setItemList(pages)
                            setPageIndex(pageIndexing(pages.length))
                        }}
                    >
                        소비
                    </button>
                    <button
                        type="button"
                        className="list-group-item list-group-item-action"
                        style={styles.listGroupBtn}
                        onClick={() => {
                            setPageNum(0)
                            setListNum(0)
                            const pages = tablePaging(typeFilter(inven, 'etc'), tableSize)
                            setItemList(pages)
                            setPageIndex(pageIndexing(pages.length))
                        }}
                    >
                        기타
                    </button>
                </div>
                <table className="table" style={styles.table}>
                    {isInvenLoading ? (
                        <tbody>
                        <tr>
                            <th>...loading</th>
                        </tr>
                        </tbody>
                    ) : (
                        <tbody>
                        {itemList[listNum] ? itemList[listNum].map((item, index) => (
                            <tr
                                key={index}
                                onMouseEnter={() => setHoverTable(index)}
                                onMouseLeave={() => setHoverTable(null)}
                                style={hoverTable === index
                                    ? { backgroundColor: 'rgb(245, 245, 245)' }
                                    : null}
                            >
                                <td width="5%">
                                    <input
                                        type="radio"
                                        name="radio_item"
                                        value={index}
                                        onClick={()=> {
                                            const itemIndex = document.querySelectorAll('input[name="radio_item"]:checked')[0].value
                                            setChoosedItem(itemList[listNum][itemIndex])
                                        }}
                                    ></input>
                                </td>
                                <td
                                    style={{ textAlign: 'left' }}
                                    data-toggle="popover"
                                    data-content={`
                        직업: ${jobCounter(item.job, 1)}<br />
                        등급: ${itemGrade(item.grade)}<br />
                        부위: ${instrType(item.instr_type, item.job)}<br />
                        ${item.attack !== 0 ? `공격력: ${item.attack}<br />` : ''}
                        ${item.magic !== 0 ? `마력: ${item.magic}<br />` : ''}
                        ${item.defend !== 0 ? `방어력: ${item.defend}<br />` : ''}
                        ${item.spirit !== 0 ? `정신력: ${item.spirit}<br />` : ''}
                        ${item.weight !== 0 ? `무게: ${item.weight}<br />` : ''}
                      `}
                                >
                                    {item.name}
                                </td>
                                <td width="40%" />
                                {typeFilter([item.instr_type], 'equipment')
                                    ? (
                                        <td width="17%" style={styles.tableBtnRap}>
                                            <button
                                                className="btn btn-info col-10"
                                                onClick={() => {
                                                    equipItem(item)
                                                }}
                                            >
                                                장착
                                            </button>
                                        </td>
                                    ) : (
                                        <td></td>
                                    )
                                }
                            </tr>
                        )) : null}
                        {itemList[listNum] ? [...Array(tableSize - itemList[listNum].length)].map((i, index) => (
                                <tr
                                    key={index}
                                    onMouseEnter={() => setHoverTable(index + itemList[listNum].length)}
                                    onMouseLeave={() => setHoverTable(null)}
                                    style={hoverTable === index + itemList[listNum].length
                                        ? { backgroundColor: 'rgb(245, 245, 245)' }
                                        : null}
                                >
                                    <td style={{ height: '49px' }}></td>
                                    <td></td>
                                    <td width="40%"></td>
                                    <td></td>
                                </tr>
                            )) :
                            [...Array(tableSize)].map((i, index) => (
                                <tr
                                    key={index}
                                    onMouseEnter={() => setHoverTable(index)}
                                    onMouseLeave={() => setHoverTable(null)}
                                    style={hoverTable === index
                                        ? { backgroundColor: 'rgb(245, 245, 245)' }
                                        : null}
                                >
                                    <td style={{ height: '49px' }}></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                </table>
                <table className="table" style={styles.btnTable}>
                    <tbody>
                    <tr>
                        <td></td>
                        <td width="17%" style={styles.dumpBtnRap}>
                            <button
                                className="btn btn-info col-10"
                                onClick={() => {
                                    if(choosedItem) return window.jQuery("#buyItem").modal("show");
                                    noChoose(addToast)
                                }}
                            >
                                버리기
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                {isInvenLoading ? (
                    <span></span>
                ) : (
                    <nav aria-label="..." style={styles.pagination}>
                        <ul className="pagination pagination-sm">
                            {pageIndex[pageNum - 1] ? (
                                <li className="page-item">
                                    <a
                                        className="page-link"
                                        href="##"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setPageNum(pageNum - 1)
                                        }}
                                    >
                                        Previous
                                    </a>
                                </li>
                            ) : null}
                            {pageIndex[pageNum] ? pageIndex[pageNum].map((i, index) => (
                                <li className="page-item" key={index}>
                                    <a
                                        className="page-link"
                                        href="##"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setListNum(index)
                                        }}
                                    >
                                        {index + 1}
                                    </a>
                                </li>
                            )) : (
                                <li className="page-item">
                                    <a className="page-link" href="##">1</a>
                                </li>
                            )}
                            {pageIndex[pageNum + 1] ? (
                                <li className="page-item">
                                    <a
                                        className="page-link"
                                        href="##"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setPageNum(pageNum + 1)
                                        }}
                                    >
                                        Next
                                    </a>
                                </li>
                            ) : null}
                        </ul>
                    </nav>
                )}
            </div>

            <div className="modal" id="buyItem" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">구매하기</h5>
                        </div>
                        <div className="modal-body">
                            <p>{choosedItem ? choosedItem.name : null}(을)를 버리겠습니까?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn btn-outline-primary"
                                data-dismiss="modal"
                                onClick={() => {
                                    dumpItem()
                                }}
                            >
                                확인
                            </button>
                            <button className="btn btn-outline-danger" data-dismiss="modal">
                                취소
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = {
    statBar: {
        padding: 15,
        paddingBottom: 0,
        border: '2px solid rgb(222, 226, 230)'
    },
    stat: {
        display: 'inline',
        fontSize: 20
    },
    statBtn: {
        left: '50%',
        transform: 'translateX(-50%)',
        width: '80%'
    },
    table: {
        margin: 0,
        border: '2px solid rgb(222, 226, 230)',
    },
    tableBtnRap: {
        borderLeft: '1px solid rgb(222, 226, 230)',
        padding: 5,
        textAlign: 'center',
    },
    btnTable: {
        border: '2px tranparent',
    },
    dumpBtnRap: {
        borderLeft: '1px tranparent',
        padding: 5,
        textAlign: 'center',
    },
    pagination: {
        margin: '0 auto'
    },
    listGroup: {
        position: 'absolute',
        transform: 'translateY(-100%)',
        paddingLeft: 5,
    },
    listGroupBtn: {
        width: '9vw',
        minWidth: '100px',
        height: '35px',
        textAlign: 'center',
        padding: 0,
        fontWeight: 'bold'
    },
}