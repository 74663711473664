import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { errorOccur } from "../../../utils/toast"
import { jobCounter } from "../../../utils/character";
import { useLayoutEffect } from "react";
import { approveApply, getAppliedList, rejectApply } from "../../../utils/api/guild";

function ManageApply(props) {
    const [apply_list, setApplyList] = useState()
    const [tempAction, setTempAction] = useState()
    const dispatch = useDispatch()
    const { addToast } = useToasts()

    const go_member = () => {
        props.history.push('/guild/manage/member')
    }

    useLayoutEffect(() => {
        window.jQuery('[data-modal="approve"]').click(function () {
            window.jQuery('#applyApprove').modal('show');
        });
        window.jQuery('[data-modal="reject"]').click(function () {
            window.jQuery('#applyReject').modal('show');
        });
    })
    useEffect(() => {
        getAppliedList()
            .then(res => {
                if (res.data.length !== 0) setApplyList(res.data)
                else setApplyList([])
            })
            .catch(error => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else errorOccur(addToast)
                setApplyList([])
            })
    }, [dispatch, addToast, apply_list])

    const handleApprove = () => {
        approveApply(tempAction)
            .then(() => {
                setTempAction();
                setApplyList();
            })
            .catch((error) => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else errorOccur(addToast)
                setTempAction()
            })
    }
    const handleReject = () => {
        rejectApply(tempAction)
            .then(() => {
                setTempAction();
                setApplyList();
            })
            .catch((error) => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else errorOccur(addToast)
                setTempAction()
            })
    }

    if (apply_list === undefined) {
        return (<>loading</>)
    }
    else {
        return (
            <div className="section">
                <div className="container">
                    <br /><br /><br />
                    <h1>
                        길드 신청 관리
                        <button className="btn btn-outline-primary" style={{ float: "right" }} onClick={() => go_member()}>길드원 관리</button>
                    </h1>
                    <div className="row" style={{ marginTop: "3%" }}>
                        <div className="col-md-18">
                            <table className="table table-bordered" style={{ tableLayout: 'fixed' }}>
                                <thead>
                                    <tr>
                                        <th width="60px" />
                                        <th>이름</th>
                                        <th>직업</th>
                                        <th>레벨</th>
                                        <th>관리 항목</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {apply_list.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ textAlign: "center" }}>1{index}</td>
                                            <td>{item.character__name}</td>
                                            <td>{jobCounter(item.character__job, item.character__job_class)}</td>
                                            <td>{item.character__lev}</td>
                                            <td>
                                                <button data-modal="approve" className="btn btn-outline-primary" style={{ float: "left", width: "40%" }}
                                                    data-target="#applyApprove" onClick={() => setTempAction(item.character__name)}>
                                                    수락
                                            </button>
                                                <button data-modal="reject" className="btn btn-outline-danger" style={{ float: "right", width: "40%" }}
                                                    data-target="#applyReject" onClick={() => setTempAction(item.character__name)}>
                                                    거절
                                            </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="modal" id="applyApprove" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="applyApprove">길드 신청 수락</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>정말 {tempAction}을 영입하시겠습니까?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary" data-dismiss="modal" onClick={() => handleApprove()}>확인</button>
                                <button type="button" className="btn btn-outline-danger" data-dismiss="modal" onClick={() => setTempAction()}>취소</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="applyReject" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="applyReject">길드 신청 거절</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>정말 {tempAction}의 신청을 거절하시겠습니까?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary" data-dismiss="modal" onClick={() => handleReject()}>확인</button>
                                <button type="button" className="btn btn-outline-danger" data-dismiss="modal" onClick={() => setTempAction()}>취소</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ManageApply