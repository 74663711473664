import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {jobCounter} from "../../utils/character";
import {chooseCharacterSession, getCharacterList} from "../../utils/api/character";
import {getTown} from "../../utils/api/town";
import {getMyMemberData} from "../../utils/api/guild";

function ChrSelect(props) {
    const character = useSelector(state=>state.character).value
    if (character === 'null'&&!localStorage.getItem('token')) props.history.push('/login')
    const dispatch = useDispatch()
    const [list, setList] = useState([]);
    useEffect(() => {
        if(!!localStorage.getItem('token')){
            getCharacterList()
                .then(res=>{setList(res.data);})
                .catch((error)=> {
                    if (error.response.status === 401) dispatch({type: 'EXPIRED', payload: true})
                })
        }
    },[dispatch]);

    const handleSubmit = (character) => {
        chooseCharacterSession(character)
            .then(res=>{
                dispatch({type: 'CHARACTER', payload: res.data})
                getTown().then(res=>{dispatch({type:"TOWN", payload:res.data})})
                if (res.data.guild !== '없음') getMyMemberData().then(res=>{dispatch({type:"GUILD", payload:res.data[0]})})
                props.history.push('/')})
            .catch((error)=> {
                if (error.response.status === 401) dispatch({type: 'EXPIRED', payload: true})
            })
    };

    return (
        <div className='section'>
            <div className="container" style={{textAlign:"center"}}>
                <br/><br/><br/>
                <h1>캐릭터 선택</h1><br/>
                <div className="row">
                    {list.map(charac => (
                        <div className="col-md-auto" style={{padding:"15px"}} key = {charac.name}>
                            <button className="btn btn-light" style={{padding:"0"}} type="submit" onClick={() => handleSubmit(charac.name)}>
                                <img src="/placeholder.png" width="150" height="200" alt="char_image"/>
                                <div className="carousel-caption" style={{paddingBottom:"0"}}>
                                    <h6 className="text-dark" style={{marginBottom:"0"}}><b>{charac.name}</b></h6>
                                    <p className="text-dark" style={{marginBottom:"0"}}>{jobCounter(charac.job, charac.job_class)}
                                        <br/>{charac.sex} Lv.{charac.lev}</p>
                                </div>
                            </button>
                        </div>
                    ))}
                    {list.length >= 6? <></>:
                    <div className="col-md-auto" style={{padding:"15px"}}>
                        <img src="/placeholder.png" width="150" height="200" alt="new_image"/>
                        <div className="carousel-caption">
                            <h2 className="text-dark">빈 슬롯</h2><br/>
                            <input type="button" value="캐릭터 생성" onClick={()=>props.history.push('/chr/create')}
                                   className="text-dark"/>
                        </div>
                    </div>
                    }
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-md-18" style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <input type="button" className="btn btn-danger" value="캐릭터 삭제" onClick={()=>{props.history.push('/chr/delete')}}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChrSelect;