import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPercentData, jobCounter} from "../utils/character";
import {logType, setBattleLog} from "../utils/log";
import moment from "moment";
import {client, getLog} from "../utils/api/basic";

function Main(props) {
    const [list, setList] = useState([])

    const character = useSelector(state=>state.character).value
    const town = useSelector(state=>state.town).value
    const dispatch = useDispatch()
    const stat_box = {border:'2px solid', padding:'10px'};
    const town_data = {marginTop: '10px'};
    const town_img={marginLeft: '10px'};

    const {max_exp, hp, mp, exp} = getPercentData(character)

    useEffect(() => {
        getLog(1).then(res=> setList(res.data))
            .catch(error=>{
                if (error.response.status === 401) dispatch({type:"EXPIRED", payload:true})
            })
    },[dispatch])

    return(
        <div className="section">
            <div className="container">
                <div className="row" style={{marginTop:"5%"}}>
                    <div className="col-md-3" style={stat_box}><br/>
                        <h4 className="text-right">{jobCounter(character.job, character.job_class)}</h4>
                        <h3 className="text-right">{character.name}</h3>
                        <h4 className="text-right" style={{marginBottom:"30px"}}>Lv. {character.lev}</h4>

                        <b>HP</b>
                        <div className="progress">
                            <div className="progress-bar bg-danger" role="progressbar" style={{width: hp}}/>
                            <div className="justify-content-center d-flex position-absolute w-100 align-self-center"><font
                                color="white"><b>{character.hp} / {character.max_hp}</b></font></div>
                        </div>

                        <b>MP</b>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar"  style={{width: mp}}/>
                            <div className="justify-content-center d-flex position-absolute w-100 align-self-center">
                                <font color="white"><b>{character.mp} / {character.max_mp}</b></font></div>
                        </div>

                        <b>EXP</b>
                        <div className="progress">
                            <div className="progress-bar bg-warning" role="progressbar" style={{width: exp}}/>
                            <div className="justify-content-center d-flex position-absolute w-100 align-self-center">
                                <font color="white"><b>{character.exp} / {max_exp}</b></font></div>
                        </div>
                        <br/>
                        <table className="table">
                            <tbody>
                            <tr>
                                <th style={{padding:5}}><img src="/icon/attack.png" alt="attack icon" title="공격력"/></th>
                                <td style={{padding:5}} className="text-center">{character.attack}</td>
                                <th style={{padding:5}}><img src="/icon/magic.png" alt="attack icon" title="마력"/></th>
                                <td style={{padding:5}} className="text-center">{character.magic}</td>
                            </tr>
                            <tr>
                                <th style={{padding:5}}><img src="/icon/defend.png" alt="attack icon" title="방어력"/></th>
                                <td style={{padding:5}} className="text-center">{character.defend}</td>
                                <th style={{padding:5}}><img src="/icon/mag_defend.png" alt="attack icon" title="마법저항력"/></th>
                                <td style={{padding:5}} className="text-center">{character.mag_defend}</td>
                            </tr>
                            <tr>
                                <th style={{padding:5}}><img src="/icon/speed.png" alt="attack icon" title="속도"/></th>
                                <td style={{padding:5}} className="text-center">{character.speed}</td>
                                <th style={{padding:5}}><img src="/icon/critical.png" alt="attack icon" title="치명타율"/></th>
                                <td style={{padding:5}} className="text-center">{parseFloat(character.critical).toFixed(1)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-9">
                        <li className="media" style={town_data}>
                            <div className="pull-left"><img src="/placeholder.png" className="media-object"
                                                            height="256" width="256" style={town_img} alt="town_image"/></div>
                            <div className="media-body" style={town_img}>
                                <h4 className="media-heading">
                                    {town[0]["size"]===3 && <>수도</>}
                                    {town[0]["size"]===2 && <>도시</>}
                                    {town[0]["size"]===1 && <>마을</>} {town[0]["nameKorean"]} </h4>
                                <p>에리온에 오신 것을 환영합니다.</p>
                                <a href="https://open.kakao.com/o/gx2SOjI">카카오톡 공식 오픈 채팅방</a> <br/><br/>
                                <a href="http://pf.kakao.com/_WDxbxfxb">카톡 플러스친구 고객센터</a> <br/><br/>
                                <button className="btn btn-sm btn-outline-primary"
                                        onClick={()=>{window.open(client+"/chat",'채팅','resizable,height=700,width=500')}}>채팅방</button>
                            </div>
                        </li>
                        <br/>
                        <table className="table">
                            <tbody>
                            <tr>
                                <th width="150">소속 국가</th>
                                <td width="150">{town[0]["own"]}</td>
                                <th width="150"> </th>
                                <td width="150"> </td>
                            </tr>
                            <tr>
                                <th width="150"> </th>
                                <td width="150"> </td>
                                <th width="150"> </th>
                                <td width="150"> </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-12" style={{marginTop:"20px"}}>
                        <div className="list-group">
                            <div className="list-group-item" style={{textAlign:"center", cursor: 'pointer'}} onClick={()=>{props.history.push("/log")}}>
                                <h4>로그</h4>
                            </div>
                            {list.map((item, index)=>(
                                <div className="list-group-item" key={index}
                                     onClick={item.battle !==null ? ()=>{setBattleLog(item, dispatch, props.history)}: null}>
                                    {logType(item.type)}
                                    &nbsp;
                                    <b style={item.battle ? {cursor: 'pointer'} : null}>[{moment(item.time).format("LT")}]</b>
                                    &nbsp;
                                    <font style={item.battle ? {cursor: 'pointer'} : null}>{item.log}</font>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Main;