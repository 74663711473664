import React, { useEffect, useLayoutEffect, useState } from "react";
import { jobCounter } from "../../utils/character";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { errorOccur, masterCanNotDeleteCharacter } from "../../utils/toast";
import { deleteCharacter, getCharacterList } from "../../utils/api/character";

function ChrDelete(props) {
    const character = useSelector(state => state.character).value
    if (character === 'null') props.history.push('/login')
    const dispatch = useDispatch()
    const { addToast } = useToasts()

    const [tempAction, setTempAction] = useState(0)
    const [list, setList] = useState([]);

    useLayoutEffect(() => {
        window.jQuery('[data-modal="char_remove"]').click(function () {
            window.jQuery('#charRemove').modal('show');
        });
    })
    useEffect(() => {
        if (!!localStorage.getItem('token')) {
            getCharacterList()
                .then(res => { setList(res.data); })
                .catch((error) => {
                    if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                    else errorOccur(addToast)
                })
        }
    }, [dispatch, addToast]);

    const handleSubmit = (character) => {
        deleteCharacter(character)
            .then(() => {
                dispatch({ type: 'CHARACTER', payload: 'none' })
                dispatch({ type: "TOWN", payload: 'null' })
                dispatch({ type: "GUILD", payload: { guild__name: "없음", op: 0 } })
                window.location.reload()
            })
            .catch((error) => {
                if (error.response.status === 401) dispatch({ type: 'EXPIRED', payload: true })
                else if (error.response.status === 423) masterCanNotDeleteCharacter(addToast)
            })
    };

    return (
        <div className='section'>
            <div className="container" style={{ textAlign: "center" }}>
                <br /><br /><br />
                <h1>캐릭터 삭제</h1><br />
                <div className="row">
                    {list.map(charac => (
                        <div className="col-md-auto" style={{ padding: "15px" }} key={charac.name}>
                            <button className="btn btn-light" style={{ padding: "0" }} data-modal="char_remove" onClick={() => setTempAction(charac.name)}>
                                <img src="/placeholder.png" width="150" height="200" alt="char_image" />
                                <div className="carousel-caption" style={{ paddingBottom: "0" }}>
                                    <h6 className="text-dark" style={{ marginBottom: "0" }}><b>{charac.name}</b></h6>
                                    <p className="text-dark" style={{ marginBottom: "0" }}>{jobCounter(charac.job, charac.job_class)}
                                        <br />{charac.sex} Lv.{charac.lev}</p>
                                </div>
                            </button>
                        </div>
                    ))}
                    {list.length < 1 ? <h5 style={{ marginLeft: "auto", marginRight: "auto" }}>캐릭터가 없습니다. 생성하셔야 합니다.</h5> : <> </>}
                </div>
                <br /><br />
                <div className="row">
                    <div className="col-md-18" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <input className="btn btn-success" type="button" value="캐릭터 선택" onClick={() => { props.history.push('/chr/select') }} />
                    </div>
                </div>
            </div>
            <div className="modal" id="charRemove" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="charRemove">캐릭터 삭제 재확인</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>정말 캐릭터 {tempAction}을 삭제하시겠습니까?</p>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-primary" data-dismiss="modal" onClick={() => handleSubmit(tempAction)}>확인</button>
                            <button className="btn btn-outline-danger" data-dismiss="modal" onClick={() => setTempAction()}>취소</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChrDelete