import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useToasts} from "react-toast-notifications";
import {alreadyNameExist, characterCreated, errorOccur, nameTooLong} from "../../utils/toast";
import {createCharacter} from "../../utils/api/character";


function ChrMake(props) {
    const dispatch = useDispatch()
    const { addToast } = useToasts()

    const char_picture= {
        marginLeft:"auto",
        display:"block",
        marginRight:"auto"
    }

    const center={
        marginRight:"auto",
        marginLeft:"auto"
    }

    const nameChange = event => {
        SetUsername(event.target.value);
    };

    const handleChange=(sex)=>{
        setSex(sex)
        if (sex==="남")
            setCheck(true)
        else
            setCheck(false)
    }

    const handleSubmit =(name,sex)=>{
        createCharacter(name,sex)
            .then(()=>{
                characterCreated(addToast)
                props.history.push('/chr/select')
            })
            .catch((error)=> {
                if (error.response.status === 401) {dispatch({type: 'EXPIRED', payload: true})}
                else if (error.response.status === 411) {nameTooLong(addToast)}
                else if (error.response.status === 406) {alreadyNameExist(addToast)}
                else errorOccur(addToast)
            })

    }

    const [username,SetUsername] = useState('');
    const [check, setCheck] = useState(true)
    const [sex, setSex] = useState("남")
    return(
        <div className="section">
            <div className="container" style={{marginTop: "4%"}}>
                <h1 className="text-center">캐릭터 만들기</h1>
                <div className="row">
                    <div className="col-md-6" style={center}>
                        <div className="col-md-5" style={{float:"left", marginTop:"8%"}}>
                            <img src="/placeholder.png" width="150"
                                 height="200" style={char_picture} alt="create_image"/>
                        </div>
                        <div className="col-md-7 text-right" style={{float:"right", marginTop:"8%"}}>
                            <table className="table">
                                <tbody>
                                <tr>
                                    <td style={{textAlign: 'left', paddingLeft:"0", paddingRight:"0", verticalAlign:"middle"}} className="text-center"><label>캐릭터명</label></td>
                                    <td style={{textAlign: 'left'}}><label><input className="input-group-sm" onChange={nameChange}/></label></td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'left', verticalAlign:"middle"}} className="text-center"><label>성별</label></td>
                                    <td style={{textAlign: 'left'}}><label><form>
                                        <input type="radio" checked={check} onChange={()=>handleChange("남")}/> 남&nbsp; &nbsp; &nbsp;
                                        <input type="radio" checked={!check} onChange={()=>handleChange("여")}/> 여&nbsp; &nbsp; &nbsp;
                                    </form>
                                    </label>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <div className="dropdown-divider"/>
                            <button className="btn btn-light" style={{width:"100px"}} onClick={()=>handleSubmit(username, sex)}>생성</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ChrMake