import React, { useState } from "react";
import {
    alreadyEmailExist,
    alreadyIDExist,
    invalidID,
    invalidPassword,
    passNotConfirm,
    signUpSuccess
} from "../../utils/toast";
import {useToasts} from "react-toast-notifications";
import {signUp} from "../../utils/api/basic";

function SignUp(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [email, setEmail] = useState('');
    const { addToast } = useToasts()

    const nameChange = event => setUsername(event.target.value);
    const passChange = event => setPassword(event.target.value);
    const confirmChange = event => setConfirm(event.target.value);
    const emailChange = event => setEmail(event.target.value);
    const handleSubmit = event => {
        event.preventDefault();

        signUp(username, password, confirm, email)
            .then(() => {
                props.history.push('/login');
                signUpSuccess(addToast)
            })
            .catch(error=>{
                if (error.response.status===499) invalidID(addToast)
                else if (error.response.status===498) alreadyIDExist(addToast)
                else if (error.response.status===497) invalidPassword(addToast)
                else if (error.response.status===496) passNotConfirm(addToast)
                else if (error.response.status===495) alreadyEmailExist(addToast)
                else console.log("오류발생")
            })
    };
    const login_style={
        marginLeft:'auto',
        marginRight:'auto',
        marginTop:'10%',
        marginBottom: '20%',
    };

    return (
        <div className="section">
            <div className="container">
                <div className="row">
                    <div className="text-center"
                         style={login_style}>
                        <h2>회원 가입</h2><br/><br/>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input className="form-control" placeholder="아이디" type="text" name="username"
                                        autoComplete="off" onChange={nameChange}/>
                            </div>
                            <div className="form-group">
                                <input className="form-control" placeholder="비밀번호" type="password" name="password1"
                                        autoComplete="off" onChange={passChange}/>
                            </div>
                            <div className="form-group">
                                <input className="form-control" placeholder="비밀번호 확인" type="password" name="password2"
                                       autoComplete="off" onChange={confirmChange}/>
                            </div>
                            <font style={{position:'relative',bottom:'10px',margin:'0px',float:'left',color:'rgb(0,123,255)',fontSize:'11px'}}>
                                * 8자 이상, 숫자로만 이루어질 수 없음 
                            </font>                        
                            <div className="form-group">
                                <input className="form-control" placeholder="이메일" type="email" name="email"
                                       autoComplete="off" onChange={emailChange}/>
                            </div>
                            <div className="text-right">계정이 있으신가요?&nbsp;
                                <a href="/login/">로그인</a>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                                <button type="submit" className="btn btn-light">회원가입</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
        </div>
    );
}
export default SignUp;